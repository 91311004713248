import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

import { UsersService } from '../../../core/services/users.service';
import { Customer, User } from '../../../core/models';
import {listAnimation, listAnimationParams} from '../../../core/animations/list-animation';
import { query } from '@angular/animations';

@Component({
  selector: 'app-customer-user-list',
  templateUrl: './customer-user-list.component.html',
  styleUrls: ['./customer-user-list.component.scss'],
  animations: [listAnimationParams('500ms')]
})
export class CustomerUserListComponent implements OnInit {

  loading: boolean = false;
  users: User[] = [];
  filteredUserList: User[] = [];
  customer!: Customer;
  searchString: string = '';

  constructor(private usersService: UsersService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    // get customer from router
    this.route.data.subscribe(data => {
      this.customer = data['customer'];
      this.loadUsers();
    })
  }

  loadUsers(): void {
    this.loading = true;
    this.usersService.getUsers(this.customer.subdomain)
      .subscribe(users => {
        this.users = users.sort((a,b) => a.name.localeCompare(b.name));
        this.filteredUserList = [...this.users];
        this.loading = false;
      });
  }

  search(): void {
    if (this.searchString === '') {
      this.filteredUserList = [...this.users]
      return;
    }
    this.filteredUserList = this.users.filter(user => {
      return user.name.toLocaleLowerCase().includes(this.searchString.toLowerCase());
    });
  }

  protected readonly query = query;
}
