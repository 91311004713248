<div class="customer-list-container">
  <form class="customer-list--search px-3">
    <mat-form-field class="customer-list--search-field" color="accent" appearance="outline">
      <input
        [formControl]="searchString"
        matInput
        name="search"
        placeholder="Søg i fonde"
        autocomplete="off"
      >
      <button matSuffix mat-button><mat-icon>search</mat-icon></button>
    </mat-form-field>
  </form>
  <mat-progress-bar mode="indeterminate" *ngIf="loading" color="accent"></mat-progress-bar>
  <mat-divider></mat-divider>
  <ng-container *ngIf="filteredCustomerList$ | async as filteredCustomerList">
    <mat-list class="customer-list pt-0">
      <mat-list-item *ngFor="let customer of filteredCustomerList" @fadeIn
                     class="customer-list-item"
                     routerLinkActive="active"
                     routerLink="/customers/{{customer.id}}/"
      >
        <mat-icon matListIcon [ngClass]="'status-' + customer.status">circle</mat-icon>
        <h4 matLine>{{customer.name}}</h4>
        <small matLine><em>{{customer.subdomain}}</em></small>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item *ngIf="filteredCustomerList.length === 0">
        <mat-icon matListIcon>search_off</mat-icon>
        <h4 matLine>Ingen matches.</h4>
        <p matLine>Ingen fonde matcher <em>{{searchString.value}}</em>. Prøv noget andet.</p>
      </mat-list-item>
    </mat-list>
  </ng-container>
</div>
