<div mat-dialog-title class="mb-3">
  <div fxLayoutAlign="space-between">
    <h1>Journalnummer formatering</h1>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>
  <mat-divider></mat-divider>
</div>
<div mat-dialog-content>
  <div fxLayout="column">
    <div fxLayout="column">
      <h4 class="mb-0">Guide</h4>
      <p>– Du kan vælge faste værdier i form af kategori initialer, årstal og antalet af tal for løbenummer.</p>
      <p>– Du kan indsætte vilkårlig tekst og tegn i indtastningsfeltet herunder.</p>
      <p>– Du kan trække i "chips" for at ændre rækkefølgen af formateringen</p>
    </div>
    <div fxLayout="row">
      <div fxLayoutGap="2em" fxFlex="65">
        <mat-form-field>
          <mat-label>Angiv selv værdi</mat-label>
          <input
            matInput
            [formControl]="userValue"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="true"
            [matChipInputFor]="chipList"
            (matChipInputTokenEnd)="addUserValue(userValue.value)"
          >
        </mat-form-field>
        <button mat-raised-button color="accent" (click)="addUserValue(userValue.value)" [disabled]="!userValue.value">Tilføj</button>
        <mat-chip-list #chipList multiple
                       cdkDropList
                       cdkDropListOrientation="horizontal"
                       (cdkDropListDropped)="drop($event)"
        >
          <mat-chip
            *ngFor="let format of currentFormat"
            cdkDrag
            (removed)="removeSubFormatType(format)">
            {{getDisplayValue(format)}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div fxFlex="34" fxLayoutGap="1em" fxLayout="column" class="mt-3">
        <button mat-raised-button color="primary" (click)="addFormatType('category')">Tilføj kategori initialer</button>
        <button mat-raised-button color="primary" (click)="addFormatType('year')">Tilføj årstal</button>
        <button mat-raised-button color="primary" [matMenuTriggerFor]="addNoMenu">
          Tilføj løbenumre
          <mat-menu #addNoMenu="matMenu" class="mat-grant-menu">
            <span mat-menu-item>
              <mat-checkbox (click)="toggleShowLeadingZeroes(); $event.stopPropagation()">Foranstillet nuller</mat-checkbox>
            </span>
            <button mat-menu-item *ngFor="let _ of [].constructor(9); let i = index"
                    (click)="addNumberFormatType(i + 1)">
              {{i + 1}}
            </button>
          </mat-menu>
        </button>
        <button mat-raised-button color="primary" (click)="addUserValue('-')">Tilføj bindestreg (-)</button>
      </div>
    </div>
    <p class="mt-3">Eksempel på journal nr med angivet formatering: <b>{{getExampleJournalNo().toString()}}</b></p>
  </div>

</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-raised-button (click)="saveJournalNoPattern()" color="accent">Gem</button>
</div>
