import {Component, Inject, OnInit} from '@angular/core';
import {ScoringSettings, Settings} from "../../../../../core/models";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-scoring-settings-dialog',
  templateUrl: './scoring-settings-dialog.component.html',
  styleUrls: ['./scoring-settings-dialog.component.scss']
})
export class ScoringSettingsDialogComponent implements OnInit {

  settings: ScoringSettings;

  form: FormGroup = new FormGroup({
    range: new FormArray([])
  });

  constructor(public dialogRef: MatDialogRef<ScoringSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data['scoring'] || { range: [] };
    this.settings.range?.forEach(() => this.doAppendScore());
    this.form.patchValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  doAppendScore(): void {
    const bufferGroup = new FormGroup({
      label: new FormControl('', Validators.required),
      value: new FormControl(null)
    })
    this.range.push(bufferGroup);
  }

  doRemoveScore(index: number): void {
    this.range.removeAt(index);
  }

  get range(): FormArray {
    return this.form.get('range') as FormArray;
  }
}
