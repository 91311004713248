<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Dialog</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Fælles "læst" markering</dt>
      <dd>{{value.shared_reader ? 'Ja' : 'Nej'}}</dd>
      <dt>{{'grant-admin.dialog-settings.thread_type.label' | translate}}</dt>
      <dd>{{('grant-admin.dialog-settings.thread_type.' + value.thread_type) | translate}}</dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="45" class="settings-list">
      <dt>Intern afsendere</dt>
      <dd *ngIf="!value.internal_senders.length">Kun oprettede brugere.</dd>
      <dd *ngFor="let m of value.internal_senders">{{m}}</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45" fxLayout="column">
        <p>Hvis aktiveret markeres beskeder som læst, hvis en bruger har læst beskeden.</p>
        <mat-checkbox formControlName="shared_reader">Brug fælles "læst" markering</mat-checkbox>
        <div class="my-3"></div>
        <label>{{'grant-admin.dialog-settings.thread_type.label' | translate}}</label>
        <mat-radio-group formControlName="thread_type" class="thread-type-radio-group">
          <mat-radio-button *ngFor="let type of dialogThreadTypes" [value]="type" class="thread-type-radio-button">
            {{('grant-admin.dialog-settings.thread_type.' + type) | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxFlex="45" fxLayout="column" formArrayName="internal_senders">
        <p>Indkommende beskeder til sagen send fra en bruger i fonden opfattes automatisk som "interne", dvs. de ikke bliver vist for ansøger. Det er muligt at tilføje yderligere interne afsendere.</p>
        <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let _ of internalSenders.controls; let i = index">
          <mat-form-field >
            <mat-label>Afsender e-mail</mat-label>
            <input
              type="email"
              matInput
              [formControl]="internalSenderAt(i)"
            />
          </mat-form-field>
          <button
            mat-icon-button
            matTooltip="Slet intern afsender"
            (click)="doRemoveInternalSender(i)"
          ><mat-icon>delete</mat-icon></button>
        </div>
        <div>
          <button
            mat-raised-button
            color="primary"
            matTooltip="Tilføj intern afsender"
            (click)="doAddInternalSender()"
          >Tilføj intern afsender</button>
        </div>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="accent" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="primary" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
