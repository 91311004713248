import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { defaultTaxSettings, Settings, TaxSettings } from '../../../../../core/models';

@Component({
  selector: 'app-tax-settings-dialog',
  templateUrl: './tax-settings-dialog.component.html',
  styleUrls: ['./tax-settings-dialog.component.scss']
})
export class TaxSettingsDialogComponent implements OnInit {

  settings: TaxSettings;
  form: FormGroup = new FormGroup({
    report_on_past_year: new FormControl( ),
  });

  constructor(public dialogRef: MatDialogRef<TaxSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }


  ngOnInit(): void {
    this.settings = {
      ...defaultTaxSettings,
      ...this.data['tax-report']
    };
    this.form.patchValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

}
