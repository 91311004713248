import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Palette, SubPalette, Theme} from "../../models/theme";
import {catchError, delay, map, Observable, of, Subject, switchMap} from "rxjs";
import {ApiConfig} from "../api-config";
import {Customer, StylingSettings} from "../../models";
import Angular11Theming from '!raw-loader!/src/assets/data/angular-11-styles/angular_11_theming.scss';
import {tapLog} from '../../operators';
declare var Sass: any;

const formatScss = (scss: string): string => {
  return scss
    .replace(/@use ['"]sass:map['"];/g, '')
    .replace(/mat\./g, 'mat-')
    .replace(/map\.get/g, 'map-get')
    .replace(/rgb\((\d+)\s(\d+)\s(\d+)\s\/\s(\d+)%\)/g,
      (_, r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${a * 0.01})`
    );
}

// Grant base scss files
const grantBaseImport = (import.meta as any).webpackContext(
  '!raw-loader!grant-base/projects', { recursive: true, regExp: /\.scss$/ }
);
const grantBaseFilePaths: string[] = grantBaseImport.keys()
  .filter((k: string) => k.includes('grant-base/projects'));
const grantBaseScssFiles: { content: string, path: string }[] = grantBaseFilePaths.map(path => {
 return {
   content: formatScss(grantBaseImport(path).default),
   path: path.replace('node_modules/grant-base/', '')
 }
});

// Bootstrap scss files
const bootstrapImport = (import.meta as any).webpackContext(
  '!raw-loader!bootstrap', { recursive: true, regExp: /\.scss$/ }
);
const bootstrapFilePaths: string[] = bootstrapImport.keys()
  .filter((k: string) => k.includes('bootstrap/'));
const bootstrapScssFiles: { content: string, path: string }[] = bootstrapFilePaths.map(path => {
  return {
    content: formatScss(bootstrapImport(path).default),
    path: path.replace('node_modules/', '')
  }
});

@Injectable({
  providedIn: 'root'
})
export class BuildThemeService {
  private currentTheme: Subject<string> = new Subject<string>();

  getCurrentTheme(): Observable<string> {
    return this.currentTheme.asObservable();
  }

  constructor(private http: HttpClient) {
    grantBaseScssFiles.forEach(file => Sass.writeFile(file.path, file.content));
    bootstrapScssFiles.forEach(file => Sass.writeFile(file.path, file.content));
    this.loadThemingScss();
  }

  static FONT_FAMILY_MAPPING = {
    Rounded: 'Material Icons Round',
    TwoTone: 'Material Icons Two Tone',
    Filled: 'Material Icons',
    Outlined: 'Material Icons Outlined',
    Sharp: 'Material Icons Sharp'
  };

  private compileScssTheme(src: string): Observable<string> {
    const indexPath = 'projects/grant-lib/src/styles/brands/custom/index.scss';
    Sass.writeFile(indexPath, src);
    return of({}).pipe(
      delay(100), // Workaround for resource intensive compile, that does not allow DOM to update.
      switchMap(() => this.compileFile(indexPath))
    );
  }

  private compileFile(path: string): Observable<string> {
    return new Observable<string>(subscriber => {
      Sass.compileFile(path, (v: { status: number; text: string }) => {
        if (v.status === 0) subscriber.next(v.text);
        else subscriber.error(v);
        subscriber.complete();
      });
    });
  }

  updateTheme(theme: Theme, customer: Customer, styling: StylingSettings): Observable<void> {
    return this.compileScssTheme(this.getTemplate(theme)).pipe(
      map(css => new File([css], `${customer.subdomain}-stylesheet.css`, {type: "text/css"})),
      switchMap(file => this.setNewStylesheet(customer, file, styling)),
      catchError(err => of(err).pipe(tapLog('error'))),
      map(() => {}),
    );
  }

  private getTemplate(theme: Theme) {
    const primary = `@import '~@angular/material/theming';`;
    const themeImport = `angular-material-theme`;
    const primaryTheme = `mat-light-theme($primary-palette, $accent-palette, $warning-palette)`;
    return `/**
      * Generated theme by Material Theme Generator
      * https://materialtheme.arcsine.dev
      */

      ${primary}
      ${this.getDefaults(theme)}
      ${this.getScssFonts(theme)}

      // Include the common styles for Angular Material. We include this here so that you only
      // have to load a single css file for Angular Material in your app.


      // Theme Config
      ${new Array<keyof Palette>('primary', 'accent', 'warn').map(x => {
        return this.getScssPalette(x, theme.palette[x])
      }).join('\n')}
      .primary {
        background-color: mat-color($primary-palette);
      }

      $theme: ${primaryTheme};

      // Theme Init
      @include ${themeImport}($theme);

      $mat-red: $mat-warning-colors;
      @import "../../styles"; // Everything else
      `;
  }

  private getScssPalette(name: string, p: SubPalette) {
    if (name == 'warn') {
      name = 'warning';
    }
    return `
    $mat-${name}-colors: (
      50: ${p['50']},
      100: ${p['100']},
      200: ${p['200']},
      300: ${p['300']},
      400: ${p['400']},
      500: ${p['500']},
      600: ${p['600']},
      700: ${p['700']},
      800: ${p['800']},
      900: ${p['900']},
      A100: ${p['A100']},
      A200: ${p['A200']},
      A400: ${p['A400']},
      A700: ${p['A700']},
      contrast: (
        50: ${p['contrast']['50']},
        100: ${p['contrast']['100']},
        200: ${p['contrast']['200']},
        300: ${p['contrast']['300']},
        400: ${p['contrast']['400']},
        500: ${p['contrast']['500']},
        600: ${p['contrast']['600']},
        700: ${p['contrast']['700']},
        800: ${p['contrast']['800']},
        900: ${p['contrast']['900']},
        A100: ${p['contrast']['A100']},
        A200: ${p['contrast']['A200']},
        A400: ${p['contrast']['A400']},
        A700: ${p['contrast']['A700']},
      ),
    );
    $${name}-palette: mat-palette($mat-${name}-colors, 500);`
  }

  private getDefaults(theme: Theme): string {
    return `
      $background-color: ${theme.backgroundColor};
      $text-color: ${theme.textColor};
      $header-text-color: ${theme.headerTextColor};
      $card-background-color: ${theme.matCardColor};
      $error-color: #e70000;
      $ruler-color: #32322d;
      $form-field-background: #f5f5f3;
      $use-brand-colors-for-form-field: true;
    `;
  }

  private getScssFonts(theme: Theme): string {
    const headerWeight = theme.headerWeight ? theme.headerWeight : 600;
    const bodyWeight = theme.bodyWeight ? theme.bodyWeight : 400;
    const coreImport = `mat-core`;
    return `
     // Fonts
      @import 'https://fonts.googleapis.com/icon?family=${BuildThemeService.FONT_FAMILY_MAPPING[theme.icons].replace(/ /g, '+')}';
      @import url('https://fonts.googleapis.com/css2?family=${theme.headerFont.family.replace(/ /g, '+')}:wght@300;400;500&family=${theme.bodyFont.family.replace(/ /g, '+')}:wght@300;400;500&display=swap');

      // Default font settings from base theme
      $font-family-header: ${theme.headerFont.family}, ${theme.headerFont.category};
      $font-family-body: ${theme.bodyFont.family}, ${theme.bodyFont.category};
      $font-family-import-url: 'https://fonts.googleapis.com/css2?family=${theme.headerFont.family.replace(/ /g, '+')}:wght@300;400;500&family=${theme.bodyFont.family.replace(/ /g, '+')}:wght@300;400;500&display=swap';

      $mat-headline: mat-typography-level(24px, 32px, 400);

      $mat-title: mat-typography-level(
        $font-family: $font-family-header,
        $font-size: 40px,
        $font-weight: ${headerWeight},
        $line-height: 40px,
        $letter-spacing: 0,
      );

      $mat-subheading-1: mat-typography-level(
        $font-family: $font-family-header,
        $font-size: 20px,
        $font-weight: ${headerWeight},
        $line-height: 32px,
        $letter-spacing: 0,
      );

      $mat-subheading-2: mat-typography-level(
        $font-family: $font-family-header,
        $font-size: 18px,
        $font-weight: ${headerWeight},
        $line-height: 32px,
        $letter-spacing: 0,
      );

      $mat-body-1: mat-typography-level(16px, 22px, ${bodyWeight});
      $mat-body-2: mat-typography-level(16px, 22px, 600);
      $mat-button: mat-typography-level(16px, 22px, 600);

      $fontConfig: mat-typography-config(
        $font-family: $font-family-body,
        $headline: $mat-headline,
        $title: $mat-title,
        $subheading-1: $mat-subheading-1,
        $subheading-2: $mat-subheading-2,
        $body-1: $mat-body-1,
        $body-2: $mat-body-2,
        $button: $mat-button,
      );

      // Compute font config
      @include ${coreImport}($fontConfig);`
  }

  setNewStylesheet(customer: Customer, stylesheet: File, styling: StylingSettings): Observable<Customer> {
    const url = ApiConfig.getApiUrl(`/customers/${customer.id}.json`, customer.subdomain);
    const stylesheetPayload = new FormData()
    stylesheetPayload.append('customer[stylesheet]', stylesheet, stylesheet.name);
    const settingsPayload: { customer: Pick<Customer, 'settings'> } = {
      customer: {
        settings: Object.assign({...customer.settings}, { styling: styling })
      }
    };
    return this.http.patch<Customer>(url, stylesheetPayload).pipe(
      switchMap(() => this.http.patch<Customer>(url, settingsPayload))
    );
  }

  private loadThemingScss(): void {
    const theming = Angular11Theming
      .replace(/\n/gm, '??')
      .replace(/\$mat-([^:?]+)\s*:\s*\([? ]*50:[^()]*contrast\s*:\s*\([^)]+\)[ ?]*\);\s*?/g,
        (all, name) => name === 'grey' ? all : '')
      .replace(/\/\*.*?\*\//g, '')
      .split(/[?][?]/g)
      .map(l => l
        .replace(/^\s*(\/\/.*)?$/g, '')
        .replace(/^\$mat-blue-gray\s*:\s*\$mat-blue-grey\s*;\s*/g, '')
        .replace(/^\s*|\s*$/g, '')
        .replace(/:\s\s+/g, ': ')
      )
      .filter(l => !!l)
      .join('\n');
    Sass.writeFile('~@angular/material/theming', theming);
  }
}
