import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArchiveSettings, Settings } from '../../../../../core/models';

const EmailListValidator = (control: AbstractControl): { [key: string]: any } | null => {
  const emails = control.value.split(',').map((email: string) => email.trim());
  const forbidden = emails.some((email: string) => Validators.email(new FormControl(email)));
  return forbidden ? { 'email': { value: control.value } } : null;
};
@Component({
  selector: 'app-archive-settings-dialog',
  templateUrl: './archive-settings-dialog.component.html',
  styleUrls: ['./archive-settings-dialog.component.scss']
})
export class ArchiveSettingsDialogComponent implements OnInit {

  settings: ArchiveSettings;

  form: FormGroup = new FormGroup({
    receipts: new FormControl<boolean>(false),
    rejections: new FormControl<boolean>(false),
    approvals: new FormControl<boolean>(false),
    email: new FormControl<string>('', [EmailListValidator, Validators.maxLength(100)])
  });

  constructor(public dialogRef: MatDialogRef<ArchiveSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    console.log(this.data.archive);

    this.settings = this.data.archive || { receipts: false, rejections: false, approvals: false, email: '' };
    this.form.setValue(this.settings);
    this.form.updateValueAndValidity();
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  setValidations(): void {
    const value = this.form.value;
    const validators = [Validators.maxLength(100)];
    if (value.receipts || value.rejections || value.approvals) {
      validators.push(Validators.required);
    }
    this.email.setValidators(validators);
    this.email.updateValueAndValidity();
  }

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

}
