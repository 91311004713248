<h2 mat-dialog-title>
  <b>{{data.title}}</b>
</h2>
<mat-dialog-content class="yes-no-dialog-content" [innerHtml]="data.content"></mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="warn" (click)="dialogRef.close(false)">
    {{data.no}}
  </button>
  <button mat-flat-button color="accent" (click)="dialogRef.close(true)">
    {{data.yes}}
  </button>
</mat-dialog-actions>
