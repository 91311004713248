<ng-template let-data #ipDialog>
  <div mat-dialog-title class="mb-3">
    <h3>IP: {{ data.ip }}</h3>
    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-content>
    <b>{{ data.org }}</b>
    <div>
      {{ data.postal }} {{ data.city }}<br/>
      {{ data.country }}
    </div>
    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end">
    <button mat-raised-button mat-dialog-close color="accent">Luk</button>
  </div>
</ng-template>
<mat-card class="user-card">
  <div fxLayout="row" fxLayoutAlign="start start">
    <div fxFlex="40" fxLayout="column">
      <div>{{user.name}}</div>
      <a href="mailto:{{user.email}}" [matTooltip]="'Skriv til ' + user.email">{{user.email}}</a>
    </div>
    <div fxFlex="15" fxLayout="column">
      <div [ngSwitch]="user.provider">
        <span *ngSwitchCase="'nemid'">NemID</span>
        <span *ngSwitchCase="'mitid'">MitID</span>
        <span *ngSwitchCase="'saml'">Single Sign On</span>
        <span *ngSwitchCase="'otp'">To Faktor</span>
      </div>
      <div *ngIf="user.last_sign_in_at" class="mat-caption">({{user.last_sign_in_at | date:'yyyy.MM.dd HH:mm'}})</div>
      <div *ngIf="!user.last_sign_in_at" class="mat-caption">(Aldrig logget ind)</div>
    </div>
    <div fxFlex="10">{{user.role.name}}</div>
    <div fxFlex="35" fxLayout="row" fxLayoutAlign="end center">
      <button
        *ngIf="user.last_sign_in_ip"
        mat-icon-button
        matTooltip="Adresse for seneste login."
        aria-label="Adresse for seneste login."
        (click)="showIpInfo()"
        ><mat-icon>language</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Vis rettigheder"
        aria-label="Vis rettigheder"
        (click)="showPermissions()">
        <mat-icon>admin_panel_settings</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Log ind som bruger"
        aria-label="Log ind som bruger"
        (click)="goToBoard()">
        <mat-icon>switch_account</mat-icon>
      </button>
    </div>
  </div>
</mat-card>
