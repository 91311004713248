import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { RefusalSettings, Settings } from '../../../../../core/models';

@Component({
  selector: 'app-refusal-settings-dialog',
  templateUrl: './refusal-settings-dialog.component.html',
  styleUrls: ['./refusal-settings-dialog.component.scss']
})
export class RefusalSettingsDialogComponent implements OnInit {

  settings: RefusalSettings;

  form: FormGroup = new FormGroup({
    template: new FormControl<string>('', [Validators.maxLength(100), Validators.required])
  });

  constructor(public dialogRef: MatDialogRef<RefusalSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data['paper-refusal'] || { template: null };
    this.form.setValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  get template(): FormControl {
    return this.form.get('template') as FormControl;
  }

}
