import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AutomationSettings, Settings } from '../../../../../core/models';

@Component({
  selector: 'app-automation-settings-dialog',
  templateUrl: './automation-settings-dialog.component.html',
  styleUrls: ['./automation-settings-dialog.component.scss']
})
export class AutomationSettingsDialogComponent implements OnInit {

  settings: AutomationSettings;

  form: FormGroup = new FormGroup({
    triggers: new FormControl<string[]>([], [Validators.required]),
    actions: new FormControl<string[]>([], [Validators.required])
  });

  constructor(public dialogRef: MatDialogRef<AutomationSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = Object.assign({triggers: [], actions: []}, this.data.automation);
    this.form.setValue(this.settings);
    this.form.updateValueAndValidity();
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

}
