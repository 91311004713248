import {Injectable} from '@angular/core';
import {SamlConfig} from '../models/saml-config';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Data} from '@angular/router';
import {Observable, switchMap} from 'rxjs';
import {AdminRecordService} from '../helpers/records/admin-record-service';

@Injectable({
  providedIn: 'root'
})
export class SamlConfigsService extends AdminRecordService<SamlConfig>{

  constructor(
    http: HttpClient
  ) {
    super('administration/saml_configs', http, { cache: true, filterNull: true });
  }

  fromRoute(route: ActivatedRoute): Observable<SamlConfig> {
    return route.data.pipe(switchMap<Data, Observable<SamlConfig>>(data => data['samlConfig']));
  }

}
