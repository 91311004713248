<div fxLayout="column" fxFlex="100">
  <mat-card class="mb-3">
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
      <span>Integrations brugere</span>
    </mat-card-title>
  </mat-card>

  <div>
    <div fxLayout="column" fxFlex="35">
      <mat-card class="customer-integration-users-header mb-3">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxFlex="40">Navn</div>
          <div fxFlex="60" fxLayoutAlign="end center">
            <button mat-icon-button aria-label="Opret integrations bruger" (click)="newUser()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mb-2" *ngFor="let user of users$ | async">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="40">{{user.username}}</div>
          <div fxFlex="60" fxLayoutAlign="end center">
            <button mat-icon-button (click)="editUser(user)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
