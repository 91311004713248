// Theme interfaces from https://github.com/arciisine/MaterialThemeGenerator
export interface Theme {
  palette: Palette;
  headerFont: FontSelection;
  bodyFont: FontSelection;
  icons: IconSelection;
  lightness: boolean;
  version: number;
  backgroundColor: string;
  textColor: string;
  headerTextColor: string;
  matCardColor: string;
  headerWeight: number;
  bodyWeight: number;
}

export interface Color {
  name: string,
  hex: string,
  darkContrast: boolean
}

export interface Palette {
  primary: SubPalette;
  accent: SubPalette;
  warn: SubPalette;
}

export interface SubPalette {
  '50': string,
  '100': string,
  '200': string,
  '300': string,
  '400': string,
  '500': string,
  '600': string,
  '700': string,
  '800': string,
  '900': string,
  'A100': string,
  'A200': string,
  'A400': string,
  'A700': string,
  'contrast': {
    '50': string,
    '100': string,
    '200': string,
    '300': string,
    '400': string,
    '500': string,
    '600': string,
    '700': string,
    '800': string,
    '900': string,
    'A100': string,
    'A200': string,
    'A400': string,
    'A700': string,
  },
}
export interface FontSelection {
  target: string;
  family: string;
  variant: 'light' | 'regular' | 'medium';
  size: number | undefined;
  lineHeight?: number;
  capitalized: boolean;
  spacing: number;
  category?: string;

}

export interface AllFontSelection {
  [key: string]: FontSelection;
}

export type IconSelection = 'Filled' | 'Outlined' | 'Rounded' | 'TwoTone' | 'Sharp';
