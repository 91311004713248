import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';


import { AccountingSettings, Customer, Translation } from '../../../../core/models';
import { TranslationService } from '../../../../core/services';

@Component({
  selector: 'app-accounting-settings',
  templateUrl: './accounting-settings.component.html',
  styleUrls: ['./accounting-settings.component.scss']
})
export class AccountingSettingsComponent implements OnInit, OnChanges {

  @Input() settings: AccountingSettings;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  customer: Customer;
  form: FormGroup = new FormGroup({
    payment_credit_account: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    payment_debit_account: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    provision_account_no: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    account_no: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    intermediate_account: new FormControl('', [Validators.minLength(3), Validators.maxLength(20)]),
  });
  value: AccountingSettings = {
    payment_credit_account: '',
    payment_debit_account: '',
    provision_account_no: '',
    account_no: '',
    intermediate_account: '',
  };
  economic_posting_text: FormControl = new FormControl('', [Validators.required]);

  constructor(private route: ActivatedRoute,
              private translationsServices: TranslationService,
              private translate: TranslateService) {
    this.route.data.subscribe(data => {
      this.customer = data['customer'];
      const lang  = this.customer.settings.board?.language || 'da';
      this.translationsServices.getTranslations(this.customer.subdomain, lang).subscribe(translations => {
        this.translate.use(lang);
        this.translate.setTranslation(lang, translations, true);
        this.translate.get('accounting_connector.economic.text').subscribe(value => {
          this.economic_posting_text.patchValue(value)
        });
      });
    });
  }

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...this.settings };
      }
      this.form.patchValue(this.value);
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...this.settings };
    this.form.reset(this.value);
    this.form.disable()
  }

  doSave(): void {
    this.translationsServices.setTranslation(this.customer.subdomain, 'da', 'accounting_connector.economic.text',
                                             this.economic_posting_text.value).subscribe(() => {});
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

}
