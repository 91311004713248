<h2 mat-dialog-title>
  E-mailarkiv
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <p>Automatisk arkivering af kvitteringer, afslag og/eller bevillignsbreve ved at sætte en eller flere modtagere BCC på beskederne fra GrantOne.</p>
  <form [formGroup]="form" fxLayout="column">
    <div class="mb-2">
      <mat-checkbox formControlName="receipts" (change)="setValidations()">Kvitteringer</mat-checkbox>
    </div>
    <div class="mb-2">
      <mat-checkbox formControlName="rejections" (change)="setValidations()">Afslag</mat-checkbox>
    </div>
    <div class="mb-2">
      <mat-checkbox formControlName="approvals" (change)="setValidations()">Bevillingsbreve</mat-checkbox>
    </div>
    <mat-form-field>
      <mat-label>Emails</mat-label>
      <input
        type="text"
        matInput
        formControlName="email"
      >
      <mat-hint>Indtast en eller flere emailadresser. Adresserne adskilles af komma.</mat-hint>
      <mat-error *ngIf="email.hasError('required')"><strong>Skal</strong> udfyldes.</mat-error>
      <mat-error *ngIf="email.hasError('email')"><strong>Ugyldig</strong> emailadresse(r).</mat-error>
      <mat-error *ngIf="email.hasError('maxlength')"><strong>Højst</strong> 100 tegn.</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
</mat-dialog-actions>
