import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { formatNumber } from '@angular/common';

import { Usage, UsageStatistics } from '../../../../core/models';
import { StatisticsService } from '../../../../core/services';

export const toHuman = (x: number): string => {
  if (x > 1000000) {
    return formatNumber(x / 1000000.0, 'da-DK', '1.0-0') + ' mio.';
  } else if (x  > 1000) {
    return formatNumber(x / 1000.0, 'da-DK', '1.0-0') + ' t.';
  } else {
    return formatNumber(x, 'da-DK', '1.0-0');
  }
};

@Component({
  selector: 'app-customer-stats',
  templateUrl: './customer-stats.component.html',
  styleUrls: ['./customer-stats.component.scss']
})
export class CustomerStatsComponent implements OnInit, OnChanges {

  @Input() type: UsageStatistics;
  @Input() subdomain: string;

  title: string = '';
  cssClass: string = '';
  usage: Usage | null = null;
  total: number;
  yearToDate: number;

  constructor(private statisticsService: StatisticsService) {
  }

  ngOnInit(): void {
    this.title = this.getTitle();
    this.cssClass = this.getCssClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['subdomain']) {
      this.subdomain = changes['subdomain'].currentValue;
      this.fetchUsage();
    }
  }

  fetchUsage(): void {
    this.statisticsService.getUsage(this.subdomain, this.type).subscribe(usage => {
      this.usage = usage;
      this.total = usage.total;
      this.yearToDate = usage.data[4];
    });
  }

  getTitle(): string {
    switch (this.type) {
      case UsageStatistics.Projects:
        return 'Ansøgninger';
      case UsageStatistics.Applied:
        return 'Ansøgt';
      case UsageStatistics.Granted:
        return 'Bevilget';
      case UsageStatistics.Paid:
        return 'Udbetalt';
      case UsageStatistics.Messages:
        return 'Beskeder';
      case UsageStatistics.Profiles:
        return 'Profiler'
      case UsageStatistics.Assets:
        return 'Dokumenter';
      case UsageStatistics.Attachments:
        return 'Sagsakter';
    }
    return '';
  }

  getCssClass(): string {
    switch (this.type) {
      case UsageStatistics.Projects:
        return 'statistics-projects';
      case UsageStatistics.Applied:
        return 'statistics-applied';
      case UsageStatistics.Granted:
        return 'statistics-granted';
      case UsageStatistics.Paid:
        return 'statistics-paid';
      case UsageStatistics.Messages:
        return 'statistics-messages';
      case UsageStatistics.Profiles:
        return 'statistics-profiles'
      case UsageStatistics.Assets:
        return 'statistics-assets';
      case UsageStatistics.Attachments:
        return 'statistics-attachments';
    }
    return '';
  }

  protected readonly toHuman = toHuman;
}
