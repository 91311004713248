import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FontSelection} from "../../models/theme";
import {ApiConfig} from "../api-config";
import {map, Observable, shareReplay} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FontService {

  key = '';

  _fonts: FontSelection[] = [];
  private allFonts$: Observable<FontSelection[]>;

  constructor(private http: HttpClient) {
    this.getAllFonts().subscribe(fonts => {
      this._fonts = fonts;
    });
  }

  public getAllFonts(): Observable<FontSelection[]> {
    if(this.allFonts$) return this.allFonts$;

    this.allFonts$ = this.http.get<{items: FontSelection[]}>(`${ApiConfig.getBaseUrl()}/assets/data/font-data.json`).pipe(
      map(data => data.items),
      shareReplay(1)
    );
    return this.allFonts$;
  }

  searchAllFonts(q: string): Observable<FontSelection[]> {
    q = q.toLowerCase();
    return this.getAllFonts()
      .pipe(
        map(fonts =>
          fonts.filter(v => v.family.toLowerCase().startsWith(q)))
      );
  }
}
