import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { defaultSmsSettings, SmsSettings } from '../../../../core/models';

@Component({
  selector: 'app-sms-settings',
  templateUrl: './sms-settings.component.html',
  styleUrls: ['./sms-settings.component.scss']
})
export class SmsSettingsComponent implements OnInit, OnChanges {

  @Input() settings: SmsSettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  value: SmsSettings = { ...defaultSmsSettings };
  form: FormGroup = new FormGroup({
    all_messages: new FormControl<boolean>(false),
    approval: new FormControl<boolean>(false),
    refusal: new FormControl<boolean>(false),
    sender: new FormControl<string>('', [Validators.required, Validators.minLength(2),
      Validators.maxLength(12), Validators.pattern('^[0-9a-zA-Z ]+$')])
  });

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...defaultSmsSettings, ...this.settings };
      }
      this.form.patchValue(this.value);
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultSmsSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

}
