import {Injector, NgModule, ProviderToken} from '@angular/core';

/**
 * Non-Angular dependency injector
 *
 * Allows non-Angular classes and functions to use Angular's dependency injection.
 *
 * @source https://stackoverflow.com/questions/52665421/angular-inject-service-into-decorator
 */
@NgModule()
export class InjectorModule {
  private static _injector: Injector;

  constructor(injector: Injector) {
    InjectorModule._injector = injector;
  }

  /**
   * Gets injectable dependency.
   *
   * @example
   * InjectorModule.getInjectable(CustomersService) // Returns CustomersService
   *
   * @param token - ProviderToken - The desired injectable dependency.
   * @returns Dependency.
   */
  static getInjectable<T>(token: ProviderToken<T>): T {
    return InjectorModule._injector.get(token);
  }
}
