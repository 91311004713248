export const bulkActions = [
  'project_archive', 'meeting_clear', 'meeting_assign', 'own_note', 'shared_note',
  'case_worker_add', 'case_worker_remove', 'expert_add', 'expert_remove', 'tag_add', 'tag_remove',
  'grant_amount', 'grant_recommended', 'message_approval', 'message_refusal', 'message_applicant',
  'confirm_payment_outgoing', 'forecast_assign', 'forecast_clear', 'clarification_cancel',
  'comments_read', 'comments_read_all', 'mark_clear', 'statistics_set'
] as const;
export type BulkAction = typeof bulkActions[number];

export const actionTranslations: Record<BulkAction, string> = {
  project_archive: 'Arkiver sag',
  meeting_clear: 'Tilføj til møde',
  meeting_assign: 'Fjerne fra møde',
  own_note: 'Opret egen note',
  shared_note: 'Opret delt note',
  case_worker_add: 'Tilføj sagsbehandler',
  case_worker_remove: 'Fjern sagsbehandler',
  expert_add: 'Tilføj ekspert',
  expert_remove: 'Fjern ekspert',
  tag_add: 'Tilføj tag',
  tag_remove: 'Fjern tag',
  grant_amount: 'Bevilg et beløb',
  grant_recommended: 'Bevilg indstillede beløb',
  message_approval: 'Send bevillingsbrev',
  message_refusal: 'Send afslagsbrev',
  message_applicant: 'Send besked til ansøger',
  confirm_payment_outgoing: 'Ekspeder betaling',
  forecast_assign: 'Tildel bevillingsramme',
  forecast_clear: 'Fjern bevillingsramme',
  clarification_cancel: 'Annuller afklaring',
  comments_read: 'Marker kommentarer som læst',
  comments_read_all: 'Marker kommentarer som læst for alle',
  mark_clear: 'Fjern markeringer',
  statistics_set: 'Opdater statistik'
} as const;
