<div fxLayout="column" fxFlex="100">
  <mat-card class="mb-3">
    <mat-card-title>Profiler</mat-card-title>
    <mat-card-content>
      <mat-form-field color="accent" appearance="outline">
        <mat-label>Søg efter profil</mat-label>
        <input
          [formControl]="query"
          matInput
          (keyup.enter)="doSearch()"
          placeholder="Fx. ansoeger@grantone.dk"
          autocomplete="off"
        >
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *ngIf="loading" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>

  <div class="customer-profiles-list" [@listAnimation]="profiles?.length">
    <mat-card class="customer-profiles-header">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="20">Navn</div>
        <div fxFlex="25">Login</div>
        <div fxFlex="10">Type</div>
        <div fxFlex="15">Seneste login</div>
        <div fxFlex="30"></div>
      </div>
    </mat-card>

    <mat-card class="profile-card" *ngFor="let profile of profiles">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="20">{{profile.name}}</div>
        <div fxFlex="25">
          <span *ngIf="profile.provider === 'email'">{{profile.uid}}</span>
        </div>
        <div fxFlex="10" [ngSwitch]="profile.provider">
          <span *ngSwitchCase="'email'">E-mail</span>
          <span *ngSwitchCase="'nemid'">
          {{ isEmployeeNemId(profile) ? 'Medarbejder ' : '' }}
            NemID
        </span>
          <span *ngSwitchCase="'mitid'">MitID</span>
          <span *ngSwitchDefault>{{profile.provider}}</span>
        </div>
        <div fxFlex="15">
          <span *ngIf="profile.last_sign_in_at">{{profile.last_sign_in_at | date: 'dd.MM.yyyy HH:mm'}}</span>
          <span *ngIf="!profile.last_sign_in_at">Aldrig</span>
        </div>
        <div fxFlex="30" fxLayout="row" fxLayoutAlign="end center">
          <button mat-icon-button
                  *ngIf="profile.provider === 'email' && profile.locked_at"
                  matTooltip="Lås op"
                  aria-label="Lås op"
                  (click)="doUnlock(profile)">
            <mat-icon>lock_open</mat-icon>
          </button>
          <button mat-icon-button
                  *ngIf="profile.provider === 'email' && isResetTokenExpired(profile) && !profile.locked_at"
                  matTooltip="Nulstil adgangskode"
                  aria-label="Nulstil adgangskode"
                  (click)="doResetPassword(profile)">
            <mat-icon>lock_reset</mat-icon>
          </button>
          <button  mat-icon-button
                  *ngIf="profile.provider === 'email' && profile.reset_password_expires_at && !isResetTokenExpired(profile) && !profile.locked_at"
                   matTooltip="Skift adgangskode"
                   aria-label="Skift adgangskode"
                  (click)="goToChangePassword(profile)">
            <mat-icon>open_in_new</mat-icon>
          </button>
          <button mat-icon-button
                  matTooltip="Login som profil"
                  aria-label="Login som profil"
                  (click)="goToProfile(profile)">
            <mat-icon>switch_account</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
  </div>

</div>
