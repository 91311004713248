import { Component, Inject, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {Customer, defaultSettings, ForecastDimension, ForecastSettings, Settings} from '../../../../../core/models';
import {ForecastGroupsService} from '../../../../../core/services/forecast-groups.service';
import {ForecastGroup} from '../../../../../core/models/forecast-group';
import {FormGroupRecord} from '../../../../../core/types/form.type';
import {map, Observable, tap} from 'rxjs';

@Component({
  selector: 'app-forecasts-settings-dialog',
  templateUrl: './forecast-settings-dialog.component.html',
  styleUrls: ['./forecast-settings-dialog.component.scss']
})
export class ForecastSettingsDialogComponent implements OnInit {

  settings: ForecastSettings;
  form: FormGroup;
  groupSelector: FormControl<number|null> = new FormControl(null);
  groups$: Observable<ForecastGroup[]>;
  dimensionsChange$: Observable<boolean>;
  currentDimensions: FormArray<FormGroupRecord<ForecastDimension>>;

  constructor(
    public dialogRef: MatDialogRef<ForecastSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Settings & { customer: Customer },
    private forecastGroupsService: ForecastGroupsService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.settings = {
      ...defaultSettings.forecast,
      ...(this.data.forecast || {})
    }
    this.groups$ = this.forecastGroupsService.getAll(this.data.customer.subdomain).pipe(
      tap(groups => this.initForm(groups))
    );
    this.dimensionsChange$ = this.groupSelector.valueChanges.pipe(
      tap(id => this.setCurrentDimensions(id)),
      map(() => true)
    );
  }

  private setCurrentDimensions(id: number | null): void {
    const dimensionsGroup = this.form.get('dimensions') as FormGroup;
    this.currentDimensions = dimensionsGroup.get(`${id}`) as FormArray;
  }

  private initForm(groups: ForecastGroup[]): void {
    const properties = {
      no_exceeding_available: [this.settings.no_exceeding_available],
      multiple_sources: [this.settings.multiple_sources],
      least_one_source: [this.settings.least_one_source],
      allow_change_after_approval: [this.settings.allow_change_after_approval]
    }
    const dimensions: { [K in number]: FormArray } = {}
    groups.map(group => group.id).forEach(id => {
      const list = (this.settings.dimensions[id] || [])
        .map(dimension => this.buildForecastDimensionGroup(dimension))
      dimensions[id] = this.fb.array(list)
    });
    this.form = this.fb.group({
      ...properties,
      dimensions: this.fb.group(dimensions)
    });
  }

  private buildForecastDimensionGroup(dimension: ForecastDimension = { name: '', code: '' }): FormGroupRecord<ForecastDimension> {
    return this.fb.group({
      name: [dimension.name],
      code: [dimension.code]
    });
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  addDimension(): void {
    this.currentDimensions.push(this.buildForecastDimensionGroup());
  }

  removeDimension(index: number): void {
    this.currentDimensions.removeAt(index);
  }
}
