import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountNumber'
})
export class AccountNumberPipe implements PipeTransform {

  transform(value: string | null | undefined): string | null | undefined {
    if (!value) {
      return value;
    }
    return value.replace(/^([0-9]{4})/, '$1 - ');
  }

}
