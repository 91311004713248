import {Portal} from './portal';

export interface User {
  id: number;
  name: string;
  email: string;
  cpr: string;
  pid: string;
  role: Role;
  last_sign_in_at: string;
  last_sign_in_ip: string;
  authorized_signatory: boolean;

  case_worker: boolean;
  expert: boolean;
  initials: string;
  is_current_user?: boolean;
  permissions: Permissions;
  settings: any;
  provider: 'nemid' | 'otp' | 'employee' | 'saml' | 'mitid';
  uid: string;
  portal_id: Portal['id'];
}

interface Role {
  created_at: Date;
  id: number;
  name: string;
  permissions: Permissions;
  updated_at: Date;
}

export interface Permissions {
  administration: {
    read: boolean;
    reply: boolean;
  },
  applications: {
    clarify: boolean;
    comments: boolean;
    confirm: boolean;
    dialog: boolean;
    edit: boolean;
    expert: boolean;
    mark: boolean;
    notes: boolean;
    objective: boolean;
    rate: boolean;
    read: boolean;
    recommend: boolean;
    score: boolean;
    statistics: boolean;
    todo_lists: boolean;
    transfer: boolean;
    vote: boolean;
  },
  case_documents: {
    manage: boolean;
  },
  dialogs: {
    manage: boolean;
    read: boolean;
  },
  experts: {
    manage: boolean;
    read: boolean;
  },
  forecast: {
    manage: boolean;

  },
  master_data: {
    manage: boolean;
    show: boolean;
  }
  meetings: {
    view: boolean;
  }
  payments: {
    confirm: boolean;
    confirm_addendum: boolean;
    confirm_and_lock: boolean;
    confirm_dropped: boolean;
    confirm_incoming: boolean;
    confirm_payment_documentation: boolean;
    confirm_payment_receipts: boolean;
    confirm_purchase: boolean;
    manage: boolean;
    manage_expiration: boolean;
    override_date: boolean;
    read: boolean;
  }
  project_displays: {
    create: boolean;
    share: boolean;
  }
  project_report: {
    manage: boolean;
  }
  reports: {
    manage: boolean;
  }
  tax_codes: {
    manage: boolean;
  }
  tax_report: {
    manage: boolean;
  }
  [key: string]: any;
}

export interface NewUser {
  name: string,
  initials: string,
  email: string,
  cpr: string,
  provider: string
}
