import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IsAuthenticatedGuard, IsNotAuthenticatedGuard } from './core/guards';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {GrantGuidesComponent} from "./grant-guides/grant-guides.component";

const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [IsNotAuthenticatedGuard]
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: 'customers',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'portals',
        loadChildren: () => import('./portals/portals.module').then(m => m.PortalsModule)
      },
      {
        path: 'saml-configs',
        loadChildren: () => import('./saml-configs/saml-configs.module').then(m => m.SamlConfigsModule)
      },
      {
        path: 'grant-guides',
        loadChildren: () => import('./grant-guides/grant-guides.module').then(m => m.GrantGuidesModule)
      }
    ]
  },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: true,
      paramsInheritanceStrategy: 'always'
    })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
