import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { Customer } from '../../core/models';
import { ApiConfig } from '../../core/services/api-config';
import {AdministratorService} from '../../core/services/administrator.service';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss']
})
export class CustomerViewComponent {

  customer!: Customer;

  constructor(private route: ActivatedRoute, private admin: AdministratorService) {
    this.route.data.subscribe((data) => {
      this.customer = data['customer'];
    });
  }

  urlFor(path: string): string {
    return ApiConfig.getUrl(path, this.customer.subdomain);
  }

  goToBoard(): void {
    this.admin.userToken(this.customer.subdomain).subscribe(token =>
      window.open(this.urlFor(`/board/#/sign-in?token=${token}`), "_blank")
    );
  }
}
