<h2 mat-dialog-title>
  AI
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <form [formGroup]="form" fxLayout="column">
    <label>Data uploads</label>
    <mat-radio-group
      class="allow-gdpr-unsafe-files-radio-group"
      formControlName="allow_gdpr_unsafe_files"
    >
      <mat-radio-button class="allow-gdpr-unsafe-files-radio-button" [value]="false">
        Dokumenter med personhenførbare data uploades IKKE til AI
      </mat-radio-button>
      <mat-radio-button class="allow-gdpr-unsafe-files-radio-button" [value]="true"
                        (click)="confirmUploadUnsafeFilesDialog(form.controls.allow_gdpr_unsafe_files.value)">
        Alle sagens dokumenter, inklusiv dokumenter med personhenførbare data, uploades til AI
      </mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="select-prompt-type">
      <mat-label>Prompt typer</mat-label>
      <mat-select formControlName="prompt_types" multiple placeholder="Vælg prompt">
        <mat-option value="chat">Chat</mat-option>
        <mat-option value="recommendation">Indstilling</mat-option>
        <mat-option value="rating">Vurdering</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
</mat-dialog-actions>
