<mat-toolbar color="primary">
  <span>Administration</span>
  <span class="toolbar-spacer"></span>
  <img src="assets/images/GrantOne.svg" alt="GrantOne" class="toolbar-logo"/>
</mat-toolbar>

<mat-drawer-container class="dashboard-container">
  <mat-drawer mode="side" opened class="dashboard-sidebar">
    <div class="dashboard-sidebar__container">
      <button mat-icon-button aria-label="Fonde" routerLinkActive="active" routerLink="/customers" matTooltip="Fonde" matTooltipPosition="right">
        <mat-icon>business</mat-icon>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <button mat-icon-button aria-label="Portaler" routerLinkActive="active" routerLink="/portals" matTooltip="Portaler" matTooltipPosition="right">
        <mat-icon>apps</mat-icon>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <button mat-icon-button aria-label="SSO" routerLinkActive="active" routerLink="/saml-configs" matTooltip="SSO" matTooltipPosition="right">
        <span class="material-symbols-outlined">tenancy</span>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <button mat-icon-button aria-label="GrantOne guides" routerLinkActive="active" routerLink="/grant-guides" matTooltip="GrantOne Guides" matTooltipPosition="right">
        <span class="material-symbols-outlined">help</span>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <div class="sidebar-spacer"></div>
      <mat-divider class="my-2"></mat-divider>
      <button mat-icon-button (click)="goToSupport()" aria-label="Support" matTooltip="Support" matTooltipPosition="right">
        <mat-icon>support</mat-icon>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <button mat-icon-button (click)="goToSidekiq()" aria-label="Sidekiq" matTooltip="Sidekiq" matTooltipPosition="right">
        <mat-icon>sports_martial_arts</mat-icon>
      </button>
      <mat-divider class="my-2"></mat-divider>
      <button mat-icon-button aria-label="Log ud" (click)="doSignOut()" matTooltip="Log ud" matTooltipPosition="right">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </mat-drawer>
  <mat-drawer-content><router-outlet></router-outlet></mat-drawer-content>
</mat-drawer-container>
