import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { Customer, Profile } from '../../../core/models';
import { ProfilesService } from '../../../core/services';
import { ApiConfig } from '../../../core/services/api-config';
import {listAnimation, listAnimationParams} from "../../../core/animations/list-animation";

@Component({
  selector: 'app-customer-profiles',
  templateUrl: './customer-profiles.component.html',
  styleUrls: ['./customer-profiles.component.scss'],
  animations: [listAnimationParams('500ms')]
})
export class CustomerProfilesComponent {

  customer: Customer;
  profiles: Profile[];
  loading: boolean = false;
  query: FormControl = new FormControl('');

  constructor(private route: ActivatedRoute,
              private cookies: CookieService,
              private profilesService: ProfilesService) {
    this.route.data.subscribe(data => {
      this.customer = data['customer'];
    });
  }

  doSearch(): void {
    const needle = this.query.value?.trim();
    if (needle.length === 0) {
      return;
    }
    this.loading = true;
    this.profilesService.search(this.customer.subdomain, needle)
      .subscribe(profiles => {
        this.loading = false;
        this.profiles = profiles.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  doUnlock(profile: Profile): void {
    this.profilesService.unlock(this.customer.subdomain, profile)
      .subscribe(() => this.doSearch());
  }

  doResetPassword(profile: Profile): void {
    this.profilesService.resetPassword(this.customer.subdomain, profile, 24)
      .subscribe(() => this.doSearch());
  }

  goToChangePassword(profile: Profile): void {
    const url = ApiConfig.getUrl(`/profile/#/reset-password/${profile.reset_password_token}`, this.customer.subdomain);
    window.open(url, 'profile');
  }

  goToProfile(profile: Profile): void {
    this.profilesService.signIn(this.customer.subdomain, profile)
      .subscribe((token) => {
        const domain = location.hostname;
        this.cookies.set('grant.profile.token', token, undefined, '/', domain.substring(domain.indexOf('.') + 1));
        window.open(ApiConfig.getUrl('/profile/', this.customer.subdomain), 'profile');
      });
  }

  isResetTokenExpired(profile: Profile): boolean {
    if (profile.reset_password_expires_at) {
      const expireDate = new Date(profile.reset_password_expires_at);
      return expireDate.getTime() < (new Date()).getTime();
    }
    return true;
  }

  isEmployeeNemId(profile: Profile): boolean {
    return profile.provider === 'nemid' && profile.uid.match(/^[0-9]{8}\//) !== null;
  }

}
