import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BankType, Customer} from "../../../../core/models";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {DataService} from "../../../../core/services";
import {validateForm} from "../../../../core/helpers/validate-form.helper";

@Component({
  selector: 'app-edit-customer-dialog',
  templateUrl: './edit-customer-dialog.component.html',
  styleUrls: ['./edit-customer-dialog.component.scss']
})
export class EditCustomerDialogComponent implements OnInit {

  customer: Customer;
  customerForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditCustomerDialogComponent>,
              private dataService: DataService,
              @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    //Todo: load these
    // this.dataService.getCountries().subscribe(countries => this.countries = countries);
    // this.dataService.getCurrencies().subscribe(currencies => this.currencies = currencies);
    // console.log('Countries -> ', this.countries);
    // console.log('Currencies => ', this.currencies);

    this.customer = this.data.customer;
    this.customerForm = new FormGroup({
      id: new FormControl<number>(this.customer.id),
      name: new FormControl<string>(this.customer.name, Validators.required),
      subdomain: new FormControl<string>(this.customer.subdomain, Validators.required),
      reg_no: new FormControl<string>(this.customer.reg_no, Validators.required),
      care_of: new FormControl<string>(this.customer.care_of),
      street: new FormControl<string>(this.customer.street, Validators.required),
      city: new FormControl<string>(this.customer.city, Validators.required),
      zip: new FormControl<string>(this.customer.zip, Validators.required),
      country: new FormControl<string>(this.customer.country, Validators.required),
      contact: new FormControl<string>(this.customer.contact, Validators.required),
      phone: new FormControl<string>(this.customer.phone, Validators.required),
      email: new FormControl<string>(this.customer.email, [Validators.email, Validators.required]),
      currency: new FormControl<string>(this.customer.currency),
      settings: new FormGroup({
        board: new FormGroup({
          language: new FormControl(this.customer.settings?.board?.language, Validators.required)
        }),
        languages: new FormControl(this.customer.settings?.languages, Validators.required),
        tag: new FormControl<string>(this.customer.settings?.tag)
      }),
      wealth_manager_attributes: new FormGroup({
        bank: new FormControl<BankType>(this.customer.wealth_manager.bank, Validators.required),
        custody_no: new FormControl<string>(this.customer.wealth_manager.custody_no, Validators.required),
        nem_konto: new FormControl<boolean>(this.customer.wealth_manager.nem_konto),
        accounting: new FormControl<boolean>(this.customer.wealth_manager.accounting),
        capital: new FormControl<string>(this.customer.wealth_manager.capital),
        exports_enabled: new FormControl<boolean>(this.customer.wealth_manager.exports_enabled),
      })
    })
  }

  saveCustomer(): void {
    validateForm(this.customerForm);
    if (this.customerForm.invalid) {
      return;
    }
    const value = this.customerForm.value;
    value.wealth_manager_attributes['id'] = this.customer.wealth_manager.id;
    value.wealth_manager_attributes['bank_connector_id'] = this.customer.wealth_manager.bank_connector_id;
    this.dialogRef.close(value);
  }

  close(): void {
    this.dialogRef.close();
  }

  getControl(control: string): AbstractControl {
    return this.customerForm.get(control) as AbstractControl;
  }

  getLanguageControl(): FormControl {
    return this.customerForm.get('settings')?.get('languages') as FormControl;
  }
}
