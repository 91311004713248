<h2 mat-dialog-title>
  Skatteindberetning
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <form [formGroup]="form" fxFlex="100" fxLayout="column" fxLayoutGap="10px">
    <div fxFlex="45" fxLayout="column">
      <mat-slide-toggle formControlName="report_on_past_year">Rapporter ændringer i bevillinger/nedskrivninger på tværs af skatteår</mat-slide-toggle>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" [disabled]="form.invalid" (click)="doSave()">Gem</button>
</mat-dialog-actions>
