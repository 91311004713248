<mat-drawer-container class="customer-view-container">
  <mat-drawer mode="side" opened class="customer-view-sidebar">
    <div class="customers-view-sidebar-inner">
      <mat-list>
        <mat-list-item class="customer-view-list-item"
          routerLinkActive="active"
          routerLink="/customers/{{customer.id}}/details">
          <span>Stamdata</span>
          <mat-icon
            *ngIf="customer.status === 'trial'"
            matTooltip="Fonden er i prøvetilstand."
            class="ml-3 trial-alert"
            >warning</mat-icon>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="customer-view-list-item"
                       routerLinkActive="active"
                       routerLink="/customers/{{customer.id}}/wealth-manager">
          Bank
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="customer-view-list-item"
                       routerLinkActive="active"
                       routerLink="/customers/{{customer.id}}/user-list">
          Brugere
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="customer-view-list-item"
                       routerLinkActive="active"
                       routerLink="/customers/{{customer.id}}/profiles">
          Profiler
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="customer-view-list-item"
                       routerLinkActive="active"
                       routerLink="/customers/{{customer.id}}/modules">
          Moduler
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="customer-view-list-item"
                       routerLinkActive="active"
                       routerLink="/customers/{{customer.id}}/settings">
          Indstillinger
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="customer-view-list-item"
                       routerLinkActive="active"
                       routerLink="/customers/{{customer.id}}/styling">
          Styling
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="customer-view-list-item"
                       routerLinkActive="active"
                       routerLink="/customers/{{customer.id}}/questionnaire-clone">
          Skema kopiering
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="customer-view-list-item"
                       routerLinkActive="active"
                       routerLink="/customers/{{customer.id}}/integration-users">
          Integrations brugere
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
      <div class="sidebar-spacer"></div>
      <mat-list class="link-list">
        <mat-divider></mat-divider>
        <h3 matSubheader><span matLine>Links</span>&nbsp;<mat-icon matListIcon>open_in_new</mat-icon></h3>
        <a mat-list-item [href]="urlFor('/board/')" (click)="$event.preventDefault(); goToBoard()">GrantOne Board</a>
        <a mat-list-item target="_blank" [href]="urlFor('/profile/')">GrantOne Profil</a>
        <a mat-list-item target="_blank" [href]="urlFor('/ansoeg/#/?skip_profile=true')">GrantOne Ansøgning</a>
      </mat-list>
    </div>
  </mat-drawer>
  <mat-drawer-content class="customer-view">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
