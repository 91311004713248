import {Component, Inject, OnInit} from '@angular/core';
import {EmployeeNumberSettings, Settings} from "../../../../../core/models";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-employee-number-settings-dialog',
  templateUrl: './employee-number-settings-dialog.component.html',
  styleUrls: ['./employee-number-settings-dialog.component.scss']
})
export class EmployeeNumberSettingsDialogComponent implements OnInit {
  settings: EmployeeNumberSettings;


  form: FormGroup = new FormGroup({
    employeeNoRegex: new FormControl('')
  });
  constructor(public dialogRef: MatDialogRef<EmployeeNumberSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data.employee_no || { employeeNoRegex: ''};
    this.form.patchValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  get employeeNoRegex(): FormControl {
    return this.form.get('employeeNoRegex') as FormControl;
  }
}
