<h2 mat-dialog-title>
  Afslagsbrev
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <p>Afslagsbrev gør det muligt for fonden at printe et papirafslag til ansøger. Selve afslagsskabelonen konfigureres seaprat med fondens brevhoved.</p>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Skabelon</mat-label>
      <input
        type="text"
        matInput
        formControlName="template"
      >
      <mat-hint>Indtast nøglen til afslagsskabelonen.</mat-hint>
      <mat-error *ngIf="template.hasError('required')"><strong>Skal</strong> udfyldes.</mat-error>
      <mat-error *ngIf="template.hasError('maxlength')"><strong>Højst</strong> 100 tegn.</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
</mat-dialog-actions>
