import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {applicantDigests, ApplicantSettings, defaultSettings} from "../../../../core/models";
import {FormGroupRecord} from '../../../../core/types/form.type';

@Component({
  selector: 'app-history-settings',
  templateUrl: './history-settings.component.html',
  styleUrls: ['./history-settings.component.scss']
})
export class HistorySettingsComponent implements OnInit, OnChanges {
  @Input() settings: ApplicantSettings;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  protected form: FormGroupRecord<ApplicantSettings> = new FormGroup({
    digest: new FormControl<ApplicantSettings['digest']|null>(defaultSettings.applicant.digest),
    force_digest: new FormControl<boolean|null>(defaultSettings.applicant.force_digest),
    production_unit_on_top: new FormControl<boolean|null>(defaultSettings.applicant.production_unit_on_top)
  });
  applicantDigests = applicantDigests;

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      this.setSettings();
    }
  }

  setSettings(): void {
    const disabled = this.form.disabled;
    this.form.enable();
    this.form.patchValue(this.settings);
    if (disabled) {
      this.form.disable();
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.setSettings();
    this.form.disable();
  }

  doSave(): void {
    this.form.disable();
    this.saveSettings.next(this.form);
  }
}
