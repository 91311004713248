import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthenticationSettings, defaultAuthenticationSettings } from '../../../../core/models';

@Component({
  selector: 'app-authentication-settings',
  templateUrl: './authentication-settings.component.html',
  styleUrls: ['./authentication-settings.component.scss']
})
export class AuthenticationSettingsComponent implements OnInit, OnChanges {

  @Input() settings: AuthenticationSettings;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  value: AuthenticationSettings = {
    ...defaultAuthenticationSettings
  };
  form: FormGroup = new FormGroup({
    modes: new FormControl<string[]>([], Validators.required)
  });

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...defaultAuthenticationSettings, ...this.settings };
      }
      this.form.patchValue(this.value);
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultAuthenticationSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

}
