import {Component, OnInit} from '@angular/core';
import {map, Observable, switchMap, tap} from 'rxjs';
import {Customer} from '../../../core/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Questionnaire} from '../../../core/models/Questionnaire';
import {CustomersService} from '../../../core/services';
import {QuestionnairesService} from '../../../core/services/questionnaires.service';
import {ActivatedRoute, Data} from '@angular/router';
import {FormGroupRecord} from '../../../core/types/form.type';

@Component({
  selector: 'app-questionnaire-clone',
  templateUrl: './questionnaire-clone.component.html',
  styleUrls: ['./questionnaire-clone.component.scss']
})
export class QuestionnaireCloneComponent implements OnInit {

  customers$: Observable<Customer[]>;
  questionnaires$: Observable<Questionnaire[]>;
  form: FormGroupRecord<{ target: string, id: number }> = new FormGroup({
    target: new FormControl<string|null>(null, [Validators.required]),
    id: new FormControl<number|null>(null, [Validators.required])
  });
  subdomain: string;

  constructor(
    private customersService: CustomersService,
    private questionnairesService: QuestionnairesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.customers$ = this.customersService.getCustomers();
    this.questionnaires$ = this.route.data.pipe(
      map<Data, string>(data => data['customer'].subdomain),
      tap(subdomain => this.subdomain = subdomain),
      switchMap(subdomain =>
        this.questionnairesService.getAll(subdomain)
      )
    );
  }

  clone(): void {
    if (!this.form.valid) return;

    this.form.disable();
    this.questionnairesService.tenantClone(this.subdomain, this.form.value.target as string, this.form.value.id as number)
      .subscribe(() => this.form.enable());
  }
}
