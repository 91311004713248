import {FormControl, FormGroup} from '@angular/forms';

/**
 * Records permission
 * Insert new models and fields here.
 *
 * @example model: ['id', 'created_at']
 */
const recordsPermissions = {
  project: [
    'id', 'applicant_id', 'title', 'description', 'category_id', 'submitted_on', 'start_on', 'end_on', 'applied_amount',
    'total_amount', 'other_funds', 'journal_no', 'status', 'created_at', 'updated_at', 'granted_amount', 'locked_at',
    'notified_at', 'external_reference', 'cleaned_at', 'uuid', 'currency', 'subcategory_id', 'language',
    'applicant_digest', 'category_initials', 'meeting_id', 'archived_at', 'exchange_rate', 'printed_at',
    'manual_cleaning', 'avg_score', 'note', 'own_funding', 'weighted_score', 'expires_on', 'mark_counter',
    'soft_cleaned_at', 'custom_value', 'priority', 'needed_amount'
  ],
  applicant: [
    'id', 'name', 'company', 'company_registration_no', 'social_security_no', 'street', 'city', 'zip', 'country',
    'phone', 'email', 'created_at', 'updated_at', 'independent_scientist', 'birthday', 'production_unit_no',
    'department', 'title', 'production_unit_name', 'industry', 'taxpayer_identification_no'
  ],
  payment_plan: [
    'id', 'project_id', 'plan_type', 'notes', 'created_at', 'updated_at', 'locked_at', 'applicant_submitted_at',
    'applicant', 'purchase', 'reserve', 'hidden_reserve'
  ],
  payment: [
    'id', 'payment_plan_id', 'amount', 'direction', 'date', 'confirmed_at', 'locked_at', 'notes', 'created_at',
    'updated_at', 'note', 'exchange_rate', 'reference', 'requested_at', 'account', 'override', 'receipts_external_note',
    'receipts_internal_note', 'receipts_confirmed_at', 'final'
  ],
  posting: [
    'id', 'project_id', 'date', 'debit_account', 'credit_account', 'amount', 'note', 'exported_at', 'created_at',
    'updated_at', 'exchange_rate', 'currency', 'batch', 'payment_type', 'payment_id'
  ],
  message: [
    'id', 'project_id', 'from', 'to', 'body', 'internal', 'created_at', 'updated_at', 'subject', 'direction',
    'thread_type', 'token', 'status', 'send_on', 'message_type'
  ],
  category: [
    'id', 'name', 'initials', 'position', 'created_at', 'updated_at', 'active', 'color', 'payment_type',
    'invitation_only', 'applicant_types', 'account_no', 'payment_plan_locked', 'journal_no_pattern',
    'payment_plan_read', 'payment_plan_write', 'grant_duration', 'separate_history', 'manual_history',
    'payment_plan_enabled', 'mandatory_subcategory', 'start_on', 'end_on', 'allow_sibling'
  ],
  subcategory: [
    'id', 'category_id', 'name', 'position', 'created_at', 'updated_at', 'account_no'
  ]
} as const;

export type RecordsPermissionKey = keyof typeof recordsPermissions;
export type RecordsPermissionValue<T extends RecordsPermissionKey = RecordsPermissionKey> = typeof recordsPermissions[T][number];
export type RecordsPermission = { [K in RecordsPermissionKey]: RecordsPermissionValue<K>[] };
export type RecordsPermissionMap = { [K in RecordsPermissionKey]: typeof recordsPermissions[RecordsPermissionKey][number][] };

export type RecordsPermissionFormGroup = FormGroup<{
  [K in RecordsPermissionKey]: FormControl<RecordsPermissionValue<K>[] | null>
}>;

export function recordsPermissionMap(): RecordsPermissionMap {
  return recordsPermissions as any;
}
