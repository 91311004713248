import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Customer, defaultSettings, PaymentReceiptsSettings, Settings } from '../../../../../core/models';

@Component({
  selector: 'app-payment-receipts-settings-dialog',
  templateUrl: './payment-receipts-settings-dialog.component.html',
  styleUrls: ['./payment-receipts-settings-dialog.component.scss']
})
export class PaymentReceiptsSettingsDialogComponent implements OnInit {

  settings: PaymentReceiptsSettings;
  form: FormGroup = new FormGroup({
    receipts_amount_check: new FormControl<boolean>(false)
  });

  constructor(
    public dialogRef: MatDialogRef<PaymentReceiptsSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Settings & { customer: Customer }
  ) {}

  ngOnInit(): void {
    this.settings = {
      ...defaultSettings['payment-receipts'],
      ...(this.data['payment-receipts'] || {})
    }
    this.form.patchValue(this.settings);
  }


  doSave(): void {
    this.dialogRef.close(this.form);
  }

}
