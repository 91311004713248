import {FormArray, FormControl, FormGroup} from "@angular/forms";

export const validateForm = (
  formGroup: FormGroup
) => {
  formGroup.markAllAsTouched();
  recursiveValidation(formGroup);
};

const recursiveValidation = (formGroup: FormGroup) => {
  formGroup.markAllAsTouched();
  Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
    if (control instanceof FormControl) {
      control.updateValueAndValidity();
    } else if (control instanceof FormGroup) {
      validateForm(control);
    } else if (control instanceof FormArray) {
      control.controls.forEach((subcontrol) => {
        if (subcontrol instanceof FormGroup) {
          validateForm(subcontrol);
        }
      });
    }
  });
};
