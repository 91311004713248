import { NgModule } from '@angular/core';
import {Routes, RouterModule, ActivatedRouteSnapshot} from '@angular/router';

import { IsAuthenticatedGuard } from "../core/guards";
import { CustomersComponent } from "./customers.component";
import { CustomerViewComponent } from "./customer-view/customer-view.component";
import { NewCustomerComponent } from "./new-customer/new-customer.component";
import { CustomerResolver } from "../core/resolvers/customer.resolver";
import { CustomerDetailsComponent } from "./customer-view/customer-details/customer-details.component";
import { CustomerComponentsComponent } from "./customer-view/customer-components/customer-components.component";
import { WealthManagerComponent } from "./customer-view/wealth-manager/wealth-manager.component";
import { CustomerUserListComponent } from "./customer-view/customer-user-list/customer-user-list.component";
import { CustomerSettingsComponent } from "./customer-view/customer-settings/customer-settings.component";
import { CustomerProfilesComponent } from './customer-view/customer-profiles/customer-profiles.component';
import { CustomerDisplaysComponent } from "./customer-view/customer-displays/customer-displays.component";
import {CustomerStylingComponent} from "./customer-view/customer-styling/customer-styling.component";
import {QuestionnaireCloneComponent} from './customer-view/questionnaire-clone/questionnaire-clone.component';
import {
  CustomerIntegrationUsersComponent
} from './customer-view/customer-integration-users/customer-integration-users.component';

const routes: Routes = [
  { path: '',
    component: CustomersComponent,
    canActivate: [IsAuthenticatedGuard],
    children: [
      { path: 'new', component: NewCustomerComponent },
      {
        path: ':id',
        component: CustomerViewComponent,
        runGuardsAndResolvers: 'always',
        resolve: { customer: CustomerResolver },
        children: [
          { path: '', redirectTo: 'details', pathMatch: 'prefix' },
          { path: 'details', component: CustomerDetailsComponent },
          { path: 'wealth-manager', component: WealthManagerComponent },
          { path: 'user-list', component: CustomerUserListComponent },
          { path: 'modules', component: CustomerComponentsComponent },
          { path: 'settings', component: CustomerSettingsComponent},
          { path: 'displays', component: CustomerDisplaysComponent},
          { path: 'profiles', component: CustomerProfilesComponent },
          { path: 'styling', component: CustomerStylingComponent},
          { path: 'questionnaire-clone', component: QuestionnaireCloneComponent },
          { path: 'integration-users', component: CustomerIntegrationUsersComponent }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CustomerResolver]
})
export class CustomersRoutingModule { }
