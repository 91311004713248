<div fxLayout="column" fxFlex="100">
  <mat-card class="mb-3">
    <mat-card-title>Brugere</mat-card-title>
    <mat-card-content>
      <mat-form-field color="accent" appearance="outline">
        <mat-label>Søg efter bruger</mat-label>
        <input
          [(ngModel)]="searchString"
          matInput
          (keyup)="search()"
          placeholder="Fx. bruger@grantone.dk"
          autocomplete="off"
        >
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *ngIf="loading" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>

  <div class="customer-users-list">
    <mat-card class="customer-users-header">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="40">Navn</div>
        <div fxFlex="15">Login</div>
        <div fxFlex="10">Rolle</div>
        <div fxFlex="35"></div>
      </div>
    </mat-card>

    <span [@listAnimation]="filteredUserList.length">
      <app-user-card
        *ngFor="let user of filteredUserList"
        [user]="user"
        [customer]="customer"
      ></app-user-card>
    </span>
  </div>
</div>
