<h2 mat-dialog-title>
  Fælles kommentarer
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <p>Hvis fonden har <em>delte kommentartråde</em> slået til kan alle brugere med adgang til fælles kommentarer se alle tråde - både dem de deltager I og dem de ikke deltager i.</p>
  <form [formGroup]="form">
    <div class="mb-2">
      <mat-checkbox formControlName="shared">Del kommentartråde</mat-checkbox>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()">Gem</button>
</mat-dialog-actions>
