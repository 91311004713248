<div class="p-3">
  <mat-card class="mb-3">
    <!--  Todo: implement in seperate component -->
    <mat-card-title>Opret fond</mat-card-title>
    <mat-card-subtitle>Stamdata</mat-card-subtitle>
    <mat-card-content>
      <form [formGroup]="form">
        <div fxLayout fxLayoutGap="10px">
          <div fxFlex="25" fxLayout="column" fxLayoutGap="10px">
            <h3>Trin 1: CVR-nummer, domæne & sprog</h3>
            <mat-form-field color="accent">
              <mat-label>CVR-nummer</mat-label>
              <input
                matInput
                placeholder="CVR-nummer"
                formControlName="reg_no"
                (blur)="doLookupCvr()"
                autofocus>
              <mat-hint>Fondens CVR-nummer - data fra CVR-indlæses automatisk.</mat-hint>
              <mat-error *ngIf="form.controls['reg_no'].hasError('required')">Obligatorisk.</mat-error>
              <mat-error *ngIf="form.controls['reg_no'].hasError('pattern')">Ugyldigt CVR-nummer.</mat-error>
              <mat-error *ngIf="form.controls['reg_no'].hasError('minlength')">Mindst 8 cifre.</mat-error>
              <mat-error *ngIf="form.controls['reg_no'].hasError('maxlength')">Højst 8 cifre.</mat-error>
              <mat-error *ngIf="form.controls['reg_no'].hasError('unknown')">Ukendt CVR-nummer.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Domæne</mat-label>
              <input
                matInput
                placeholder="Fondens domæne"
                formControlName="subdomain"
                (blur)="doCheckSubdomain()"
              >
              <span matSuffix>.grant.nu</span>
              <mat-hint>Et unikt underdomæne til fonden.</mat-hint>
              <mat-error *ngIf="form.controls['subdomain'].hasError('required')">Obligatorisk.</mat-error>
              <mat-error *ngIf="form.controls['subdomain'].hasError('minlength')">Mindst 3 tegn.</mat-error>
              <mat-error *ngIf="form.controls['subdomain'].hasError('maxlength')">Højst 60 tegn.</mat-error>
              <mat-error *ngIf="form.controls['subdomain'].hasError('pattern')">Ugyldigt domænenavn.</mat-error>
              <mat-error *ngIf="form.controls['subdomain'].hasError('taken')">Allerede taget.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent" class="mt-3">
              <mat-label>Sprog i board</mat-label>
              <mat-select formControlName="board_language">
                <mat-option value="da">Dansk</mat-option>
                <mat-option value="en">Engelsk</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Mulige sprog i base</mat-label>
              <mat-select formControlName="base_languages" multiple>
                <mat-option value="da">Dansk</mat-option>
                <mat-option value="en">Engelsk</mat-option>
                <mat-option value="fo">Færørsk</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxFlex="25" fxLayout="column" fxLayoutGap="10px">
            <h3>Trin 2: Kontaktperson</h3>
            <mat-form-field color="accent">
              <mat-label>Navn</mat-label>
              <input
                matInput
                placeholder="Kontaktpersonens navn"
                formControlName="contact">
              <mat-hint>Navnet på fondens kontaktperson.</mat-hint>
              <mat-error *ngIf="form.controls['contact'].hasError('required')">Obligatorisk.</mat-error>
              <mat-error *ngIf="form.controls['contact'].hasError('maxlength')">Højst 100 tegn.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>E-mail</mat-label>
              <input
                matInput
                placeholder="E-mail"
                formControlName="email">
              <mat-hint>Kontaktpersonens eller fondens email.</mat-hint>
              <mat-error *ngIf="form.controls['email'].hasError('required')">Obligatorisk.</mat-error>
              <mat-error *ngIf="form.controls['email'].hasError('maxlength')">Højst 100 tegn.</mat-error>
              <mat-error *ngIf="form.controls['email'].hasError('email')">Ugyldig e-mail.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Telefon</mat-label>
              <input
                matInput
                placeholder="Telefonnummer"
                formControlName="phone">
              <mat-hint>Kontaktpersonens eller fondens telefonnummer.</mat-hint>
              <mat-error *ngIf="form.controls['phone'].hasError('required')">Obligatorisk.</mat-error>
              <mat-error *ngIf="form.controls['phone'].hasError('maxlength')">Højst 16 cifre.</mat-error>
              <mat-error *ngIf="form.controls['phone'].hasError('pattern')">Ugyldigt telefonnummer.</mat-error>
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxFlex="25" fxLayout="column" fxLayoutGap="10px">
            <h3>Trin 3: Adresse</h3>
            <mat-form-field color="accent">
              <mat-label>Navn</mat-label>
              <input
                matInput
                placeholder="Fondens navn"
                formControlName="name">
              <mat-hint>Fondens navn, som det vises i GrantOne.</mat-hint>
              <mat-error *ngIf="form.controls['name'].hasError('required')">Obligatorisk.</mat-error>
              <mat-error *ngIf="form.controls['name'].hasError('maxlength')">Højst 100 tegn.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>C/O</mat-label>
              <input
                matInput
                placeholder="Evt. c/o adresse"
                formControlName="care_of">
              <mat-error *ngIf="form.controls['care_of'].hasError('maxlength')">Højst 100 tegn.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Vej/Gade</mat-label>
              <input
                matInput
                placeholder="Vej/gadenavn, fx Hovedgaden 84B"
                formControlName="street">
              <mat-error *ngIf="form.controls['street'].hasError('required')">Obligatorisk.</mat-error>
              <mat-error *ngIf="form.controls['street'].hasError('maxlength')">Højst 100 tegn.</mat-error>
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="10px">
              <mat-form-field color="accent" fxFlex="25">
                <mat-label>Postnr.</mat-label>
                <input
                  matInput
                  placeholder="Postnr."
                  formControlName="zip">
                <mat-error *ngIf="form.controls['zip'].hasError('required')">Obligatorisk.</mat-error>
                <mat-error *ngIf="form.controls['zip'].hasError('maxlength')">Højst 20 tegn.</mat-error>
              </mat-form-field>
              <mat-form-field color="accent" fxFlex="75">
                <mat-label>By</mat-label>
                <input
                  matInput
                  placeholder="By"
                  formControlName="city">
                <mat-error *ngIf="form.controls['city'].hasError('required')">Obligatorisk.</mat-error>
                <mat-error *ngIf="form.controls['city'].hasError('maxlength')">Højst 100 tegn.</mat-error>
              </mat-form-field>
            </div>
            <mat-form-field color="accent">
              <mat-label>Region</mat-label>
              <input
                matInput
                placeholder="Evt. region"
                formControlName="region">
              <mat-error *ngIf="form.controls['region'].hasError('maxlength')">Højst 100 tegn.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Land</mat-label>
              <mat-select formControlName="country">
                <mat-option *ngFor="let country of countries" [value]="country.value">{{country.name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['country'].hasError('required')">Land skal besvares.</mat-error>
            </mat-form-field>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxFlex="25" fxLayout="column" fxLayoutGap="10px">
            <h3>Trin 4: Bankoplysninger</h3>
            <mat-form-field color="accent">
              <mat-label>Bank</mat-label>
              <mat-select formControlName="bank">
                <mat-option value="generic">Ingen/generisk</mat-option>
                <mat-option value="danske_forvaltning">Danske Bank Forvaltning</mat-option>
                <mat-option value="danske_bank">Danske Bank District</mat-option>
                <mat-option value="jyske_bank">Jyske Bank</mat-option>
                <mat-option value="bank_connect">BankConnect</mat-option>
                <mat-option value="bank_integration">BankIntegration</mat-option>
              </mat-select>
              <mat-hint>Vælg hvilken bank-integration, som fonden skal benytte.</mat-hint>
              <mat-error *ngIf="form.controls['bank'].hasError('required')">Obligatorisk.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Depot-/kontonummer</mat-label>
              <input
                matInput
                placeholder="Depot-/kontonummer"
                formControlName="custody_no"
              >
              <mat-hint>Angiv registrerings- og kontonummer for fondens depot.</mat-hint>
              <mat-error *ngIf="form.controls['custody_no'].hasError('required')">Obligatorisk.</mat-error>
              <mat-error *ngIf="form.controls['custody_no'].hasError('maxlength')">Højst 15 cifre.</mat-error>
              <mat-error *ngIf="form.controls['custody_no'].hasError('minlength')">Mindst 8 cifre.</mat-error>
              <mat-error *ngIf="form.controls['custody_no'].hasError('pattern')">Ugyldigt depotnummer.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Valuta</mat-label>
              <mat-select formControlName="currency">
                <mat-option value="dkk">Danske kroner</mat-option>
                <mat-option value="eur">Euro</mat-option>
                <mat-option value="usd">US Dollar</mat-option>
                <mat-option value="sek">Svenske kroner</mat-option>
                <mat-option value="nok">Norske kroner</mat-option>
                <mat-option value="isk">Islandske kroner</mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['currency'].hasError('required')">Obligatorisk.</mat-error>
            </mat-form-field>
            <mat-form-field color="accent"
                            *ngIf="form.controls['bank'].value === 'danske_forvaltning'"
            >
              <mat-label>Kapital</mat-label>
              <mat-select formControlName="capital">
                <mat-option [value]="0">Under 1 million kr.</mat-option>
                <mat-option [value]="1">Over 1 million kr.</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-slide-toggle
              color="accent"
              matTooltip="Angiver om fonden har en NemKonto aftale til udbetalinger."
              formControlName="nem_konto"
            >NemKonto</mat-slide-toggle>
            <mat-slide-toggle
              color="accent"
              matTooltip="Angiver om fonden er regnskabskunde eller ej.."
              formControlName="accounting"
              *ngIf="form.controls['bank'].value === 'danske_forvaltning'"
            >Regnskabskunde hos Danske Bank Forvaltning</mat-slide-toggle>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *ngIf="form.disabled" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
  <mat-card class="mb-3">
    <!--  Todo: implement in seperate component -->
    <div fxFlex="40">
      <mat-card-title>Kategorier</mat-card-title>
      <mat-card-subtitle>Opret en eller flere kategorier.</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="categoriesForm" fxLayout="column" fxLayoutGap="10px">
          <div
            formArrayName="categories"
            class="mb-3"
            *ngFor="let category of categories; let i = index"
          >
            <div [formGroupName]="i" class="category" fxLayout="row" fxLayoutGap="10px">
              <mat-form-field color="accent" fxFlex="60">
                <mat-label>Kategori #{{i + 1}}</mat-label>
                <input
                  matInput
                  formControlName="name"
                  placeholder="Kategorinavn"
                >
              </mat-form-field>
              <mat-form-field color="accent" fxFlex="20">
                <mat-label>Initialer</mat-label>
                <input
                  matInput
                  formControlName="initials"
                  placeholder="Initialer"
                >
              </mat-form-field>
              <div fxFlex="10">
                <button
                  *ngIf="categories.length > 1"
                  mat-icon-button
                  matTooltip="Slet denne kategori"
                  (click)="doRemoveCategory(i)"
                ><mat-icon>delete</mat-icon></button>
              </div>
            </div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="accent"
          matTooltip="Tilføj ny kategori"
          (click)="doAppendCategory()"
        ><mat-icon>add</mat-icon> Tilføj kategori</button>
      </mat-card-actions>
    </div>
    <div fxFlex="40">
      <mat-card-title>Board brugere</mat-card-title>
      <mat-card-subtitle>Tilføj board brugere ud fra eksisterende admin brugere</mat-card-subtitle>
      <mat-card-content>
        <div fxLayout="column" fxLayoutGap="10px">
          <ng-container *ngFor="let admin of allAdminUsers; let i = index">
            <mat-checkbox [checked]="isAdminUserSelected(admin)"
                          (change)="doToggleAdminUser(admin, $event.checked)"
                          color="accent" class="mr-2">
              {{admin.name}}
            </mat-checkbox>
          </ng-container>
        </div>
      </mat-card-content>
    </div>
  </mat-card>
<!--  Todo: implement in seperate component -->
  <mat-card class="mb-3 modules">
    <mat-card-title>
      Moduler
    </mat-card-title>
    <mat-card-content fxLayout="row wrap">
      <div fxFlex="100">
        <mat-form-field class="mr-3">
          <mat-label>Søg efter modul</mat-label>
          <input type="text" name="search"
                 [(ngModel)]="searchString"
                 matInput
                 (keyup)="search()">
        </mat-form-field>
        <button (click)="toggleAll()"
                class="mb-3"
                mat-flat-button
                [color]="showChooseAll() ? 'accent' : 'primary'">
          <span *ngIf="showChooseAll()">Vælg alle</span>
          <span *ngIf="!showChooseAll()">Fravælg alle</span>
        </button>
      </div>
      <div fxFlex="55">
        <mat-grid-list cols="3" rowHeight="3em"
                       fxLayoutGap="1em"
                       [@listAnimation]="filteredComponents.length">
          <mat-grid-tile fxFlex="30" *ngFor="let component of filteredComponents" colspan="1" rowspan="1">
            <mat-card fxLayoutAlign="space-between center" fxFlex="100">
              <span>{{component.name}}</span>
              <mat-slide-toggle [checked]="selectedComponents.includes(component.id)"
                                (change)="selectComponent(component.id, $event.checked)"
                                color="accent" class="mr-2"></mat-slide-toggle>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
        <div>Det kan tage op til et minut at oprette en fond.</div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="form.disabled "
          (click)="doCreate()"
        ><mat-icon>verified</mat-icon> Opret fond</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
