import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotificationsSettings, Settings} from "../../../../../core/models";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-portal-settings-dialog',
  templateUrl: './portal-settings-dialog.component.html',
  styleUrls: ['./portal-settings-dialog.component.scss']
})
export class PortalSettingsDialogComponent implements OnInit {
  settings: NotificationsSettings;

  form: FormGroup = new FormGroup({
    cleanup: new FormGroup ({
      noticed: new FormControl<number>(1, Validators.required),
      unnoticed: new FormControl<number>(30, Validators.required)
    })
  });
  constructor(public dialogRef: MatDialogRef<PortalSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data.notifications || { cleanup: {
        noticed: 1,
        unnoticed: 30
      }
    };
    this.form.patchValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  get cleanup(): FormGroup {
    return this.form.get('cleanup') as FormGroup;
  }
}
