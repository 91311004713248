<h2 mat-dialog-title>
  Medarbejdernummer indstillinger
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="column" fxFlex="50">
      <p>Angiv et regulært udtryk for medarbejdernumrene for kunden</p>
      <mat-form-field>
        <mat-label>Regulært udtryk</mat-label>
        <input
          type="text"
          matInput
          formControlName="employeeNoRegex"
        >
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()">Gem</button>
</mat-dialog-actions>
