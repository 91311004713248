// Data for Onboarding - which is really a combination of
// customer, wealth manager, categories and users
import {Settings} from "./settings";

export interface OnboardingUser {
  name: string;
  email: string;
  initials: string;
  cpr: string;
  provider: string;
}

export interface OnboardingCategory {
  name: string;
  initials: string;
}

export interface OnboardingCustomer {
  name: string;
  subdomain: string;
  reg_no: string;
  contact: string;
  email: string;
  email_confirmation: string;
  phone: string;
  address_attributes: {
    care_of: string;
    street: string;
    zip: string;
    city: string;
    region: string;
    country: string;
  };
  wealth_manager_attributes: {
    bank: string;
    custody_no: string;
    nem_konto: boolean;
    accounting: boolean;
    capital: number;
  };
  settings: Settings
}

export interface Onboarding {
  customer: OnboardingCustomer;
  users: OnboardingUser[];
  categories: OnboardingCategory[];
}
