import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DisplayField, ProjectDisplay} from "../../../../core/models/display";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-edit-display-dialog',
  templateUrl: './edit-display-dialog.component.html',
  styleUrls: ['./edit-display-dialog.component.scss']
})
export class EditDisplayDialogComponent implements OnInit {

  createNew: boolean = false;
  display: ProjectDisplay;
  fields: DisplayField[] = [];

  chosenFields: DisplayField[] = [];
  availableFields: DisplayField[] = [];
  availableSortingFields: DisplayField[] = [];

  form: FormGroup = new FormGroup({
    fields: new FormArray([]),
    fields_sorting: new FormArray([]),
    name: new FormControl('', Validators.required),
    position: new FormControl(null),
    shared: new FormControl(false),
    user_id: new FormControl(null),
    user_initials: new FormControl(null)
  });

  constructor(public dialogRef: MatDialogRef<EditDisplayDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { display: ProjectDisplay, fields: DisplayField[], createNew: boolean }) { }

  ngOnInit(): void {
    this.display = this.data.display;
    this.fields = this.data.fields;
    this.createNew = this.data.createNew;
    this.initFields();

    if (!this.createNew) {
      this.initForm();
    }
  }

  initFields(): void {
    this.availableFields = this.fields;
    if (this.display) {
      this.chosenFields = this.fields.filter(field => this.display.fields.includes(field.key));
      this.availableFields = this.fields.filter(field => !this.display.fields.includes(field.key));
      console.log(this.display.fields_sorting)
      if (this.display.fields_sorting) {
        this.display.fields_sorting.forEach(sortingField => this.fieldsSortingArray.push(new FormControl(sortingField)));
      }
    }
    this.availableFields = this.availableFields.sort((a, b) => a.key.localeCompare(b.key));
    this.setPossibleSortingFields();
  }

  initForm(): void {
    this.form.get('name')?.setValue(this.display.name);
    this.form.get('position')?.setValue(this.display.position);
    this.form.get('shared')?.setValue(this.display.shared);
  }

  addField(field: DisplayField, index: number) {
    this.chosenFields.push(field);
    this.availableFields.splice(index, 1);
    this.setPossibleSortingFields();
  }

  removeField(field: DisplayField, index: number) {
    this.chosenFields.splice(index, 1)
    this.availableFields.push(field);
    this.availableFields.sort((a, b) => a.key.localeCompare(b.key));
    this.setPossibleSortingFields();
  }

  addSorting(event: {sortingField: string, sortingNumber: number}): void {
    const sortingNumber = event.sortingNumber;
    const sortingField = event.sortingField;
    if (this.fieldsSortingArray.at(sortingNumber)) {
      this.fieldsSortingArray.at(sortingNumber).setValue(sortingField);
    } else {
      this.fieldsSortingArray.push(new FormControl(sortingField));
    }
  }
  removeSorting(event: {sortingField: string, index: number}): void {
    const index = this.fieldsSortingArray.value.indexOf(event.sortingField);
    this.fieldsSortingArray.removeAt(index);
  }

  doSave(): void {
    this.fieldsArray.reset();
    this.chosenFields.forEach(field => {
      this.fieldsArray.push(new FormControl(field.key));
    })
    this.dialogRef.close(this.form.value);
  }

  drop(event: CdkDragDrop<ProjectDisplay[]>) {
    moveItemInArray(this.chosenFields, event.previousIndex, event.currentIndex);
  }

  get fieldsArray(): FormArray {
    return this.form.get('fields') as FormArray;
  }

  get fieldsSortingArray(): FormArray {
    return this.form.get('fields_sorting') as FormArray;
  }

  setPossibleSortingFields(): void {
    this.availableSortingFields = [];
    if (!this.chosenFields || this.chosenFields.length === 0) {
      return
    }
    this.chosenFields.forEach(field => {
      if (field.sortable) {
        this.availableSortingFields.push(field);
      }
    })
    console.log(this.availableSortingFields)
  }
}
