import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {defaultVotesSettings, defaultWarningsSettings, WarningsSettings} from '../../../../core/models';
import {FormControl, FormGroup} from '@angular/forms';
import {FormGroupRecord} from '../../../../core/types/form.type';
import {dup} from '../../../../core/helpers/dup';

@Component({
  selector: 'app-warnings-settings',
  templateUrl: './warnings-settings.component.html',
  styleUrls: ['./warnings-settings.component.scss']
})
export class WarningsSettingsComponent implements OnChanges {

  @Input() settings: WarningsSettings;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter();

  form: FormGroupRecord<WarningsSettings> = new FormGroup({
    exceeding_grant_warning: new FormControl(false),
  });

  constructor() {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['settings']) {
      this.settings = changes['settings'].currentValue;
      this.form.patchValue(this.formattedSettings());
    }
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.form.reset(this.formattedSettings());
    this.form.disable();
  }

  private formattedSettings(): WarningsSettings {
    return Object.assign(dup(defaultWarningsSettings), this.settings || {});
  }
}
