<mat-drawer-container class="customers-container">
  <mat-drawer mode="side" opened class="customers-sidebar">
    <div class="px-3 pt-3">
      <div style="display: flex; justify-content: space-between">
        <div>
          <h3>Fonde</h3>
          <p>Søg i listen med fonde eller opret en ny.</p>
        </div>
        <button mat-mini-fab color="primary" aria-label="Opret fond" routerLink="/customers/new">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    <app-customers-list></app-customers-list>
  </mat-drawer>
  <mat-drawer-content class="customers-main-view">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
