import {animate, style, transition, trigger} from "@angular/animations";

export const fadeIn = trigger('fadeIn', [
  transition(":enter", [
    style({ opacity: 0 }),
    animate(500, style({opacity: 1}))
  ]),
])

export const fadeInSlow = trigger('fadeInSlow', [
  transition(":enter", [
    style({ opacity: 0 }),
    animate(1000, style({opacity: 1}))
  ]),
])
