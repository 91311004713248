import {RecordService} from '../helpers/records/record-service';
import {ForecastGroup} from '../models/forecast-group';
import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root'})
export class ForecastGroupsService extends RecordService<ForecastGroup> {

  constructor() {
    super('forecast_groups');
  }

}
