import { Component, OnInit } from '@angular/core';
import {Customer} from "../../../core/models";
import { ActivatedRoute } from "@angular/router";
import {FormControl} from "@angular/forms";
import {emailValidator} from "../../../core/validators/email.validator";
import {COMMA, ENTER, SPACE} from "@angular/cdk/keycodes";
import {CustomersService} from "../../../core/services";
import {dup} from '../../../core/helpers/dup';

@Component({
  selector: 'app-wealth-manager',
  templateUrl: './wealth-manager.component.html',
  styleUrls: ['./wealth-manager.component.scss']
})
export class WealthManagerComponent implements OnInit {

  customer!: Customer;
  payment_exported_notify: FormControl = new FormControl('', [emailValidator()])
  separatorKeyCodes: number[] = [ENTER, COMMA, SPACE];
  currentEmailReceivers: string[] = [];

  constructor(private route: ActivatedRoute, private customerService: CustomersService) {
    this.route.data.subscribe((data) => {
      this.customer = data['customer'];
      console.log('CUSTOMER - ', this.customer)
    });
  }

  ngOnInit(): void {
    this.currentEmailReceivers = dup(this.customer.wealth_manager.settings.payment_exported_notify) || [];
    this.payment_exported_notify.disable();
  }

  addEmail(): void {
    if (this.payment_exported_notify.value.length === 0) {
      return
    }
    if (this.payment_exported_notify.hasError('pattern')) {
      // this.showEmailErrors = true;
      return;
    }
    this.currentEmailReceivers.push(this.payment_exported_notify.value)
    this.payment_exported_notify.setValue('')
  }

  removeMail(mail: string): void {
    this.currentEmailReceivers.splice(this.currentEmailReceivers.indexOf(mail), 1);
  }

  doEdit(): void {
    this.payment_exported_notify.enable();
  }

  doCancel(): void {
    this.payment_exported_notify.disable();
  }

  doSave(): void {
    this.customer.wealth_manager_attributes = {
      bank: this.customer.wealth_manager.bank,
      custody_no: this.customer.wealth_manager.custody_no,
      nem_konto: this.customer.wealth_manager.nem_konto,
      accounting: this.customer.wealth_manager.accounting,
      capital: Number(this.customer.wealth_manager.capital),
      settings: Object.assign(
        dup(this.customer.wealth_manager.settings),
        { payment_exported_notify: this.currentEmailReceivers }
      )
    };
    this.customerService.update(this.customer).subscribe(result => {
      this.payment_exported_notify.disable();
    })
  }
}
