export type GrantGuide = {
  title: string;
  description: string;
  language: string;
  guide_tag_ids: number[];
  steps: number;
  url: string;
  id: number;
}

export type GrantGuideTag = {
  created_at: Date;
  id: number;
  language: 'da' | 'en';
  name: string;
  updated_at: Date;
}
