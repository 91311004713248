import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Color, SubPalette} from "../../../../core/models/theme";
import {TinyColor} from "@ctrl/tinycolor";
import {FormControl, FormGroup} from "@angular/forms";
import {delay} from "rxjs";

interface ColorObject {
  name: string,
  hex: string,
  darkContrast: boolean
}

@Component({
  selector: 'app-color-selection',
  templateUrl: './color-selection.component.html',
  styleUrls: ['./color-selection.component.scss']
})
export class ColorSelectionComponent implements AfterViewInit {
  @Input() primary: SubPalette;
  @Input() accent: SubPalette;
  @Input() warn: SubPalette;

  @Input() colorSelection: FormGroup;

  @Output() onColorChange: EventEmitter<{variation: 'primary' | 'accent' | 'warn', palette: SubPalette}> = new EventEmitter();

  constructor() { }

  ngAfterViewInit() {
    this.setPrimary(this.primaryColor.value);
    this.setAccent(this.accentColor.value);
    this.setWarn(this.warnColor.value);
  }

  setPrimary(color: string): void {
    this.primaryColor.setValue(color);
    setTimeout(() => {
      this.onColorChange.emit({variation: 'primary', palette: this.primary})
    }, 100) // Workaround.. Wait for palette to value from generatePalette();
  }
  setAccent(color: string): void {
    this.accentColor.setValue(color);
    this.onColorChange.emit({variation: 'accent', palette: this.accent})
  }
  setWarn(color: string): void {
    this.warnColor.setValue(color);
    this.onColorChange.emit({variation: 'warn', palette: this.warn})
  }

  setMatColor(color: string): void {
    this.matCardColor.setValue(color);
  }
  setBackgroundColor(color: string): void {
    this.backgroundColor.setValue(color);
  }
  setTextColor(color: string): void {
    this.textColor.setValue(color);
  }
  setHeaderTextColor(color: string): void {
    this.headerTextColor.setValue(color);
  }

  getTextColor(col: string): '#000' | '#fff' {
    return new TinyColor(col).isLight() ? '#000' : '#fff';
  }

  getColorObject(value: any, name: string): ColorObject {
    const c = new TinyColor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }

  multiply(rgb1: any, rgb2: any): TinyColor{
    rgb1.b = Math.floor(rgb1.b * rgb2.b / 255);
    rgb1.g = Math.floor(rgb1.g * rgb2.g / 255);
    rgb1.r = Math.floor(rgb1.r * rgb2.r / 255);
    return new TinyColor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
  };

  generatePalette(hex: string, type: 'primary' | 'accent'| 'warn'): Color[] {
    let baseLight = new TinyColor('#ffffff');
    let baseDark = this.multiply(new TinyColor(hex).toRgb(), new TinyColor(hex).toRgb());
    let baseTriad = new TinyColor(hex).tetrad();
    const colors = [
      this.getColorObject(new TinyColor(baseLight).mix(hex, 12), '50'),
      this.getColorObject(new TinyColor(baseLight).mix(hex, 30), '100'),
      this.getColorObject(new TinyColor(baseLight).mix(hex, 50), '200'),
      this.getColorObject(new TinyColor(baseLight).mix(hex, 70), '300'),
      this.getColorObject(new TinyColor(baseLight).mix(hex, 85), '400'),
      this.getColorObject(new TinyColor(baseLight).mix(hex, 100), '500'),
      this.getColorObject(new TinyColor(baseDark).mix(hex, 87), '600'),
      this.getColorObject(new TinyColor(baseDark).mix(hex, 70), '700'),
      this.getColorObject(new TinyColor(baseDark).mix(hex, 54), '800'),
      this.getColorObject(new TinyColor(baseDark).mix(hex, 25), '900'),
      this.getColorObject(new TinyColor(baseDark).mix(baseTriad[4], 15).saturate(80).lighten(65), 'A100'),
      this.getColorObject(new TinyColor(baseDark).mix(baseTriad[4], 15).saturate(80).lighten(55), 'A200'),
      this.getColorObject(new TinyColor(baseDark).mix(baseTriad[4], 15).saturate(100).lighten(45), 'A400'),
      this.getColorObject(new TinyColor(baseDark).mix(baseTriad[4], 15).saturate(100).lighten(40), 'A700')
    ];
    const subPalette: SubPalette = {
      '50': colors[0]?.hex,
      '100': colors[1]?.hex,
      '200': colors[2]?.hex,
      '300': colors[3]?.hex,
      '400': colors[4]?.hex,
      '500': colors[5]?.hex,
      '600': colors[6]?.hex,
      '700': colors[7]?.hex,
      '800': colors[8]?.hex,
      '900': colors[9]?.hex,
      'A100': colors[10]?.hex,
      'A200': colors[11]?.hex,
      'A400': colors[12]?.hex,
      'A700': colors[13]?.hex,
      'contrast': {
        '50': this.getTextColor(colors[0]?.hex),
        '100': this.getTextColor(colors[1]?.hex),
        '200': this.getTextColor(colors[2]?.hex),
        '300': this.getTextColor(colors[3]?.hex),
        '400': this.getTextColor(colors[4]?.hex),
        '500': this.getTextColor(colors[5]?.hex),
        '600': this.getTextColor(colors[6]?.hex),
        '700': this.getTextColor(colors[7]?.hex),
        '800': this.getTextColor(colors[8]?.hex),
        '900': this.getTextColor(colors[9]?.hex),
        'A100': this.getTextColor(colors[10]?.hex),
        'A200': this.getTextColor(colors[11]?.hex),
        'A400': this.getTextColor(colors[12]?.hex),
        'A700': this.getTextColor(colors[13]?.hex),
      }
    }

    if (type === 'primary') {
      this.primary = subPalette;
    } else if (type === 'accent') {
      this.accent = subPalette;
    } else {
      this.warn = subPalette;
    }

    return colors;
  }

  get primaryColor(): FormControl {
    return this.colorSelection.get('primaryColor') as FormControl;
  }
  get accentColor(): FormControl {
    return this.colorSelection.get('accentColor') as FormControl;
  }
  get warnColor(): FormControl {
    return this.colorSelection.get('warnColor') as FormControl;
  }
  get matCardColor(): FormControl {
    return this.colorSelection.get('matCardColor') as FormControl;
  }
  get textColor(): FormControl {
    return this.colorSelection.get('textColor') as FormControl;
  }
  get headerTextColor(): FormControl {
    return this.colorSelection.get('headerTextColor') as FormControl;
  }
  get backgroundColor(): FormControl {
    return this.colorSelection.get('backgroundColor') as FormControl;
  }
}
