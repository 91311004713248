import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'fill'
})
export class FillPipe implements PipeTransform {

  transform(value: number): any[] {
    return Array(value);
  }

}