import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import { ApiConfig } from "./api-config";
import {Usage, UsageStatistics} from "../models";
import {StatisticDimension} from "../models/statistic-dimension";

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient) { }

  /**
   * Fetch a specific usage statistics for a customer for the last 5 years.
   * Supported stats are 'projects' (number of submitted project),
   * 'profiles' (number of profiles), 'grants' (sum of grants), and
   * 'messages' (number of messages).
   * @param subdomain The subdomain of the customer in question.
   * @param type The type of statistics to fetch.
   * @return [Observable<number[]>] The stats for the last 5 years.
   */
  getUsage(subdomain: string, type: UsageStatistics): Observable<Usage> {
    const url = ApiConfig.getApiUrl(`/usage/${type}.json`, subdomain);
    return this.http.get<Usage>(url);
  }


  getAllStatisticDimensions(subdomain: string): Observable<StatisticDimension[]> {
    const url = ApiConfig.getApiUrl('/statistic/dimensions.json?shallow=true', subdomain);
    return this.http.get<StatisticDimension[]>(url);
  }
}
