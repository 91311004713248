<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Søjler</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <ng-container *ngFor="let field of value.fields; let i = index">
      <dl fxFlex="25" class="settings-list">
        <dt>Titel:</dt>
        <dd>{{getTranslation(i).value}}</dd>
      </dl>
      <dl fxFlex="25" class="settings-list" *ngIf="getKey(i).value.includes('statistic')">
        <dt>Data:</dt>
        <dd>{{getStatistic(i)}}</dd>
      </dl>
      <dl fxFlex="25" class="settings-list">
        <dt>Søjle størrelse:</dt>
        <dd>{{getSize(i).value}}</dd>
      </dl>
      <mat-divider [vertical]="true"></mat-divider>
    </ng-container>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxLayout="column" formArrayName="fields" style="width: 100%">
        <p>Her kan du tilføje ekstra søjler til kunden. Den kan herfter tilføjes til en søjlevisning.</p>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2 mt-2" fxFlex="45"
             *ngFor="let _ of fields.controls; let i = index" [formGroupName]="i">
          <mat-form-field fxFlex="25">
            <mat-label>Titel på søjle</mat-label>
            <input
              type="text"
              matInput
              formControlName="translation"
            />
            <mat-hint>Husk at angive titlen ud fra sproget angivet i board app'en. I.E. <b>{{language}}</b></mat-hint>
            <mat-error *ngIf="getTranslation(i).hasError('required')">Skal udfyldes</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="25">
            <mat-label>Data for søjlen</mat-label>
            <mat-select formControlName="key">
              <mat-optgroup label="Indstilling">
                <mat-option value="extra_columns.recommendation.text">Tekst</mat-option>
              </mat-optgroup>
              <mat-optgroup label="Statistik">
                <mat-option *ngFor="let dimension of statisticDimensions"
                            [value]="'extra_columns.statistic.' + dimension.uuid">
                  {{dimension.title}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error *ngIf="getKey(i).hasError('required')">Skal udfyldes</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="25">
            <mat-label>Størrelsen på søjlen</mat-label>
            <mat-select formControlName="size">
              <mat-option value="xxs">XXS</mat-option>
              <mat-option value="xs">XS</mat-option>
              <mat-option value="s">S</mat-option>
              <mat-option value="m">M</mat-option>
              <mat-option value="l">L</mat-option>
              <mat-option value="xl">XL</mat-option>
              <mat-option value="xxl">XXL</mat-option>
            </mat-select>
            <mat-error *ngIf="getSize(i).hasError('required')">Skal udfyldes</mat-error>
          </mat-form-field>
          <button
            mat-icon-button
            matTooltip="Slet e-mail"
            (click)="doRemoveField(i)"
          ><mat-icon>delete</mat-icon></button>
        </div>
        <div class="mt-3">
          <button
            mat-raised-button
            color="primary"
            matTooltip="Tilføj e-mail"
            (click)="doAddField()"
          >Tilføj søjle</button>
        </div>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="accent" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="primary" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
