export enum UsageStatistics {
  Projects = 'Project',
  Applied = 'ProjectApplied',
  Granted = 'PaymentGranted',
  Paid = 'PaymentOutgoing',
  Profiles = 'Profile::Profile',
  Messages = 'Message',
  Assets = 'Asset',
  Attachments = 'Attachment',
  AssetsSize = 'AssetSize',
  AttachmentsSize = 'AttachmentSize'
}

export interface Usage {
  total: number;
  data: number[];
}
