export class Category {
  name: string;
  color?: string;
  initials: string;
  invitation_only: boolean;
  tax_code_id: number;
  grant_duration: number;
  applicant_types: any;
  category_reportings_attributes: object[];
  questionnaire_id: number;
  active: boolean;
  id: number;
  journal_no_pattern?: string;
  approved_template_id: number;
  approved_w_link_template_id: number;
  receipt_template_id: number;
  refused_template_id: number;
  applicant_template_id: number;
  expert_template_id: number;
  case_document_template_id?: number;
  recommendation_template_id?: number
  payment_type: string;
  position?: number;
  subcategories?: Subcategory[];
  subcategories_attributes?: Subcategory[];
  payment_documentation_setting_attributes: PaymentDocumentation;
  payment_plan_locked: boolean;
  payment_plan_read: boolean;
  payment_plan_write: boolean;
  program = false;
  criterion_group_id?: number;
  separate_history: boolean;
  manual_history: boolean;
  payment_plan_enabled: boolean;
  mandatory_subcategory?: boolean;
  start_on?: Date;
  end_on?: Date;
}

export class Subcategory {
  approved_template_id?: number;
  id: number;
  identifier: string;
  name: string;
  position?: number;
}

export interface PaymentDocumentation {
  notify_before: number;
  questionnaire_id: number;

  report: boolean;
  report_mandatory: boolean;
  invoice: boolean;
  invoice_mandatory: boolean;
  uploads: boolean;
  uploads_mandatory: boolean;
}
