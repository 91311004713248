export interface Profile {
  id: number;
  name: string;
  uid: string;
  locked_at?: Date,
  confirmed_at?: Date;
  provider: string;
  last_sign_in_at?: Date,
  current_sign_in_at?: Date,
  reset_password_expires_at?: Date,
  language: string;
  reset_password_token?: string;
  unlock_token?: string;
}
