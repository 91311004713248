import {Component, OnInit} from '@angular/core';
import {IntegrationUsersService} from '../../../core/services/integration-users.service';
import {IntegrationUser} from '../../../core/models/integration-user';
import {Customer} from '../../../core/models';
import {ActivatedRoute} from '@angular/router';
import {first, forkJoin, map, Observable, of, startWith, Subject, switchMap, tap} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {
  EditIntegrationUserDialogComponent, EditIntegrationUserDialogData, EditIntegrationUserDialogOutput
} from './edit-integration-user-dialog/edit-integration-user-dialog.component';

@Component({
  selector: 'app-customer-integration-users',
  templateUrl: './customer-integration-users.component.html',
  styleUrls: ['./customer-integration-users.component.scss']
})
export class CustomerIntegrationUsersComponent implements OnInit {

  users$: Observable<IntegrationUser[]>;
  private customer$: Observable<Customer>;
  private refreshUsers: Subject<void> = new Subject<void>();
  subdomain: string;

  constructor(
    private route: ActivatedRoute,
    private usersService: IntegrationUsersService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initCustomer();
    this.initUsers();
  }

  newUser(): void {
    const data: EditIntegrationUserDialogData = {
      id: 0, ip_addresses: [], permissions: {}, username: ''
    }
    this.openEditIntegrationUserDialog(data).subscribe(result => {
      if (!result) return;

      this.usersService.create(this.subdomain, { user: result})
        .subscribe(() => this.refreshUsers.next());
    });
  }

  editUser(user: IntegrationUser): void {
    this.openEditIntegrationUserDialog(user).subscribe(result => {
      if (!result) return;

      this.usersService.update(this.subdomain, { user: result}, user.id)
        .subscribe(() => this.refreshUsers.next());
    });
  }

  private initCustomer(): void {
    this.customer$ = this.route.data.pipe(
      map(data => data['customer'])
    );
  }

  private initUsers(): void {
    this.users$ = this.refreshUsers.pipe(
      startWith(null),
      switchMap(() => this.customer$.pipe(
        map(customer => customer.subdomain),
        tap(subdomain => this.subdomain = subdomain),
        switchMap(subdomain =>
          this.usersService.getAll(subdomain)
        )
      ))
    );
  }

  private openEditIntegrationUserDialog(data: EditIntegrationUserDialogData): Observable<IntegrationUser | undefined> {
    return this.dialog.open<EditIntegrationUserDialogComponent, EditIntegrationUserDialogData, EditIntegrationUserDialogOutput>(
      EditIntegrationUserDialogComponent,
      {
        data: data,
        width: '500px',
        disableClose: true
      }).afterClosed();
  }
}
