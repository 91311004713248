import {HttpClient} from "@angular/common/http";
import {first, map, Observable, shareReplay, switchMap, tap} from "rxjs";
import {RecordCache} from "./record-cache";
import {urlBuilder} from "../url-builder";
import {InjectorModule} from '../../../injector.module';

export class RecordService<Model extends { id: number }, IdArgs extends (number|string)[] = []> {

  protected record: RecordCache<Model, [...IdArgs, number]>;
  protected records: RecordCache<Model[], []>;
  private cache: boolean;
  private filterNull: boolean;

  constructor(
    private readonly route: string,
    private readonly httpClient: HttpClient = InjectorModule.getInjectable(HttpClient),
    options: { cache?: boolean, filterNull?: boolean } = {}
  ) {
    this.route = `/${this.route}`;
    this.cache = !!options?.cache;
    this.filterNull = !!options?.filterNull;
    this.record = new RecordCache({ filterNull: this.filterNull });
    this.records = new RecordCache({ filterNull: this.filterNull });
  }

  public get(subdomain: string, ...id: [...IdArgs, number]): Observable<Model> {
    if (!this.cache) return this.getRecord(subdomain, ...id);

    return this.record.exist(...id) ?
      this.record.get(...id) :
      this.getLatestCached(subdomain, ...id);
  }

  public getAll(subdomain: string, ...id: IdArgs): Observable<Model[]> {
    if (!this.cache) return this.getRecords(subdomain, ...id);

    return this.records.exist() ?
      this.records.get() :
      this.getAllLatestCached(subdomain, ...id);
  }

  public create(subdomain: string, payload: any, ...id: IdArgs): Observable<Model> {
    const obs = this.httpClient.post<Model>(this.buildRecordUrl(subdomain, '.json', ...id), payload);
    if (!this.cache) return obs;

    return obs.pipe(
      tap(record => this.record.valueSet(record, ...[...id, record.id])),
      tap(record => this.records.exist() ?
        this.records.valueSet([...this.records.value(), record]) : null
      )
    );
  }

  public update(subdomain: string, payload: any, ...id: [...IdArgs, number]): Observable<Model> {
    const obs = this.httpClient.patch<Model>(this.buildRecordUrl(subdomain, '/:?.json', ...id), payload);
    if (!this.cache) return obs;

    return obs.pipe(
      tap(record => this.record.valueSet(record, ...id)),
      tap(record => this.updateRecords(record))
    );
  }

  public destroy(subdomain: string, ...id: [...IdArgs, number]): Observable<void> {
    const obs = this.httpClient.delete<void>(this.buildRecordUrl(subdomain, '/:?.json', ...id));
    if (!this.cache) return obs;

    return obs.pipe(
      map(() => this.record.value(...id)),
      tap(() => this.record.delete(...id)),
      tap(record => this.records.exist() ?
        this.records.valueSet(this.records.value().filter(r => r.id !== record.id)) : null
      ),
      map(() => undefined)
    );
  }

  public getLatest(subdomain: string, ...id: [...IdArgs, number]): Observable<Model> {
    return this.getLatestCached(subdomain, ...id);
  }

  public getAllLatest(subdomain: string, ...id: IdArgs): Observable<Model[]> {
    return this.getAllLatestCached(subdomain, ...id);
  }

  protected recordUrl(subdomain: string, path: string, ...id: [...IdArgs, number] | IdArgs): string {
    return urlBuilder(`${this.route}${path}`, subdomain, id);
  }

  private buildRecordUrl(subdomain: string, path: string, ...id: [...IdArgs, number] | IdArgs): string {
    return urlBuilder(`${this.route}${path}`, subdomain, id);
  }

  private getLatestCached(subdomain: string, ...id: [...IdArgs, number]): Observable<Model> {
    const obs = this.getRecord(subdomain, ...id);
    return this.record.set(obs, ...id).pipe(
      first(),
      tap(record => this.updateRecords(record)),
      shareReplay(1),
      switchMap(() => this.record.get(...id))
    );
  }

  private getAllLatestCached(subdomain: string, ...id: IdArgs): Observable<Model[]> {
    const obs = this.getRecords(subdomain, ...id);
    return this.records.set(obs).pipe(
      first(),
      tap(records => records.forEach(record =>
        this.record.valueSet(record, ...[...id, record.id]))
      ),
      shareReplay(1),
      switchMap(() => this.records.get())
    );
  }

  private getRecord(subdomain: string, ...id: [...IdArgs, number]): Observable<Model> {
    return this.httpClient.get<Model>(this.buildRecordUrl(subdomain, '/:?.json',...id));
  }

  private getRecords(subdomain: string, ...id: IdArgs): Observable<Model[]> {
    return this.httpClient.get<Model[]>(this.buildRecordUrl(subdomain, '.json',...id));
  }

  private updateRecords(record: Model): void {
    if (!this.records.exist() || !record || !this.records.value()) return;

    this.records.valueSet(
      this.records.value().map(r => r.id === record.id ? record : r)
    );
  }
}
