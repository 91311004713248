import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiConfig} from './api-config';
import {Administrator} from "../models";

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {

  constructor(private http: HttpClient) {
  }

  userToken(subdomain: string): Observable<string> {
    return this.http.post<{token: string}>(ApiConfig.getApiUrl('/administration/support.json', subdomain), {})
      .pipe(map(data => data.token));
  }

  getAll(): Observable<Administrator[]> {
    return this.http.get<Administrator[]>(ApiConfig.getApiUrl('/administrators.json'));
  }

}
