import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import {Customer, defaultDialogSettings, DialogSettings, dialogThreadTypes} from '../../../../core/models';
import { CustomersService } from '../../../../core/services';
import {FormGroupRecord} from '../../../../core/types/form.type';

@Component({
  selector: 'app-dialog-settings',
  templateUrl: './dialog-settings.component.html',
  styleUrls: ['./dialog-settings.component.scss']
})
export class DialogSettingsComponent  {

  customer: Customer;
  loading: boolean = false;
  value: DialogSettings = {
    ...defaultDialogSettings
  };
  form: FormGroupRecord<DialogSettings> = new FormGroup({
    shared_reader: new FormControl(this.value.shared_reader),
    internal_senders: new FormArray<FormControl<string | null>>([]),
    thread_type: new FormControl(this.value.thread_type)
  });
  dialogThreadTypes = dialogThreadTypes;

  constructor(private route: ActivatedRoute,
              private customersService: CustomersService) {
    this.loading = true;
    this.route.data.subscribe(data => {
      this.loading = false;
      this.customer = data['customer'];
      this.value = {
        ...defaultDialogSettings,
        ...this.customer.settings.dialog
      };
      this.value.internal_senders.forEach(_ => this.doAddInternalSender());
      this.form.patchValue(this.value);
      this.form.disable();
    });
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.form.disable();
  }

  doSave(): void {
    this.loading = true;
    this.customer.settings.dialog = this.formValue();
    this.customersService.update(this.customer)
      .subscribe(customer => {
        this.loading = false;
        this.customer = customer;
        this.value = this.formValue();
        this.form.disable();
      });
  }

  doAddInternalSender(): void {
    this.internalSenders.push(new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]));
  }

  doRemoveInternalSender(index: number): void {
    this.internalSenders.removeAt(index);
  }

  get internalSenders(): FormArray {
    return this.form.get('internal_senders') as FormArray;
  }

  internalSenderAt(index: number): FormControl {
    return this.internalSenders.at(index) as FormControl;
  }

  private formValue(): DialogSettings {
    return this.form.value as DialogSettings;
  }

}
