<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Primærværdi</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Antal decimaler</dt>
      <dd>{{value.decimals}}</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45" fxLayout="column">
        <label>Antal decimaler ({{decimals.value}})</label>
        <mat-slider min="0" max="3" step="1"  formControlName="decimals">
          <input matSliderThumb>
        </mat-slider>
        <mat-hint>Antal decimaler i primærværdi-søjlen..</mat-hint>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="accent" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="primary" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
