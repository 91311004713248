import {RecordService} from './record-service';
import {Observable} from 'rxjs';

export class AdminRecordService<Model extends { id: number }, IdArgs extends (number|string)[] = []> extends RecordService<Model, IdArgs>{

  private readonly subdomain: string = 'admin';

  // @ts-ignore
  override get(...id: [...IdArgs, number]): Observable<Model> {
    return super.get(this.subdomain, ...id);
  }

  // @ts-ignore
  override getAll(...id: IdArgs): Observable<Model[]> {
    return super.getAll(this.subdomain, ...id)
  }

  // @ts-ignore
  override create(payload: any, ...id: IdArgs): Observable<Model> {
    return super.create(this.subdomain, payload, ...id);
  }

  // @ts-ignore
  override update(payload: any, ...id: [...IdArgs, number]): Observable<Model> {
    return super.update(this.subdomain, payload, ...id);
  }

  // @ts-ignore
  override destroy(...id: [...IdArgs, number]): Observable<void> {
    return super.destroy(this.subdomain, ...id);
  }

  // @ts-ignore
  override getLatest(...id: [...IdArgs, number]): Observable<Model> {
    return super.getLatest(this.subdomain, ...id);
  }

  // @ts-ignore
  override getAllLatest(...id: IdArgs): Observable<Model[]> {
    return super.getAllLatest(this.subdomain, ...id);
  }

  // @ts-ignore
  override recordUrl(path: string, ...id: [...IdArgs, number] | IdArgs): string {
    return super.recordUrl(this.subdomain, path, ...id);
  }

}
