import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {ProfileSettings} from "../../../../../core/models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-profile-settings-dialog',
  templateUrl: './profile-settings-dialog.component.html',
  styleUrls: ['./profile-settings-dialog.component.scss']
})
export class ProfileSettingsDialogComponent implements OnInit {
  profileSettings: ProfileSettings;

  profile: FormGroup;

  constructor(public dialogRef: MatDialogRef<ProfileSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.profileSettings = this.data.profile;
    this.loadForm();
  }

  loadForm(): void {
    this.profile = new FormGroup({
      logins: new FormArray(this.profileSettings.logins ? this.profileSettings.logins.map(l => new FormControl(l)) : []),
      signups: new FormArray(this.profileSettings.signups ? this.profileSettings.signups.map(s => new FormControl(s)) : []),
      force: new FormControl<boolean>(this.profileSettings.force),
      confirmation: new FormControl<boolean>(this.profileSettings.confirmation),
      allow_copy_of_rejection: new FormControl<boolean>(this.profileSettings.allow_copy_of_rejection),
      account_alert_on_payment_request: new FormControl<boolean>(this.profileSettings.account_alert_on_payment_request),
    })
  }

  hasLoginType(value: string): boolean {
    return this.logins.value.includes(value);
  }

  hasSignupType(value: string): boolean {
    return this.signups.value.includes(value);
  }


  onLoginTypeToggle(type: string, checked: boolean) {
    if (checked) {
      this.logins.push(new FormControl(type));
    } else {
      const index = this.logins.controls.findIndex((control) => control.value === type);
      this.logins.removeAt(index)
    }
  }

  onSignupTypeToggle(type: string, checked: boolean) {
    if (checked) {
      this.signups.push(new FormControl(type));
    } else {
      const index = this.signups.controls.findIndex((control) => control.value === type);
      this.signups.removeAt(index)
    }
  }

  isLoginSameAsSignUp(): boolean {
    return this.signups.value.sort().toString() === this.logins.value.sort().toString();
  }

  saveDialog(): void {
    this.dialogRef.close(this.profile);
  }

  get signups(): FormArray {
    return this.profile.get('signups') as FormArray;
  }
  get logins(): FormArray {
    return this.profile.get('logins') as FormArray;
  }
}
