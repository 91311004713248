import {Component, OnInit} from '@angular/core';
import {
  Customer,
  defaultExtraColumnsSettings,
  ExtraColumnsSettings
} from "../../../../core/models";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {StatisticDimension} from "../../../../core/models/statistic-dimension";
import {Translation} from "../../../../core/models/translation";
import {ActivatedRoute} from "@angular/router";
import {CustomersService, StatisticsService} from "../../../../core/services";
import {TranslationService} from "../../../../core/services/translation.service";

@Component({
  selector: 'app-extra-columns-settings',
  templateUrl: './extra-columns-settings.component.html',
  styleUrls: ['./extra-columns-settings.component.scss']
})
export class ExtraColumnsSettingsComponent implements OnInit {
  language: string;
  settings: ExtraColumnsSettings | undefined;
  statisticDimensions: StatisticDimension[];

  customer: Customer;

  value: ExtraColumnsSettings = {
    ...defaultExtraColumnsSettings
  };
  form: FormGroup = new FormGroup({
    fields: new FormArray([])
  });

  loading: boolean = false;

  constructor(private route: ActivatedRoute,
              private customersService: CustomersService,
              private statisticService: StatisticsService,
              private translationService: TranslationService) {
    this.loading = true;
    this.route.data.subscribe(data => {
      this.loading = false;
      this.customer = data['customer'];
      this.value = {
        ...defaultExtraColumnsSettings,
        ...this.customer.settings.extra_columns
      };
      this.value.fields.forEach(_ => this.doAddField());
      this.form.patchValue(this.value);
      this.form.disable();
      this.language = this.customer.settings.board?.language || 'da';
    });
  }

  ngOnInit(): void {
    this.form.disable();
    this.loadStatisticDimensions();
  }

  loadStatisticDimensions(): void {
    this.statisticService.getAllStatisticDimensions(this.customer.subdomain)
      .subscribe(dimensions => {
        this.statisticDimensions = dimensions
      });
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.form.disable();
  }

  doSave(): void {
    this.loading = true;
    this.saveTranslations();
    const fields: {key: string, size: string}[] = [];
    this.fields.controls.forEach((control, i) => {
      fields.push({
        key: this.getKey(i).value,
        size: this.getSize(i).value
      })
    })
    console.log(fields);
    this.customer.settings.extra_columns = { ...this.customer.settings.extra_columns, ...this.form.value };
    this.customersService.update(this.customer)
      .subscribe(customer => {
        this.loading = false;
        this.customer = customer;
        this.value = this.form.value;
        this.form.disable();
      })
  }

  saveTranslations(): void {
    const translations: Translation[] = [];
    this.fields.controls.forEach((control, i) => {
      translations.push({
        key: 'grant-board.edit-display-dialog.content.field.' + this.getKey(i).value,
        value: this.getTranslation(i).value
      });
      translations.push({
        key: 'grant-board.applications-list.field.' + this.getKey(i).value,
        value: this.getTranslation(i).value
      });
    })
    this.translationService.addNewTranslations(this.customer.subdomain, this.customer.settings.board?.language || 'da', translations)
      .subscribe((value: any) => {})
  }


  doAddField(): void {
    this.fields.push(new FormGroup({
      key: new FormControl('', Validators.required),
      size: new FormControl('', Validators.required),
      translation: new FormControl('', Validators.required)
    }));
  }

  doRemoveField(index: number): void {
    this.fields.removeAt(index);
  }

  get fields(): FormArray {
    return this.form.get('fields') as FormArray;
  }

  fieldAt(index: number): FormControl {
    return this.fields.at(index) as FormControl;
  }

  getKey(index: number): FormControl {
    return this.fieldAt(index)?.get('key') as FormControl;
  }
  getSize(index: number): FormControl {
    return this.fieldAt(index)?.get('size') as FormControl;
  }
  getTranslation(index: number): FormControl {
    return this.fieldAt(index).get('translation') as FormControl;
  }

  getStatistic(index: number): string | undefined {
    if (!this.statisticDimensions || this.statisticDimensions.length === 0) {
      return '--'
    }
    const uuid = this.getKey(index).value.split('extra_columns.statistic.')[1];

    const dimension = this.statisticDimensions.find(dimension => dimension.uuid === uuid);
    return dimension?.title;
  }
}
