import {Component, Inject, OnInit} from '@angular/core';
import {AISettings, defaultAISettings, PromptType, Settings} from '../../../../../core/models';
import {FormGroupRecord} from '../../../../../core/types/form.type';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {dup} from '../../../../../core/helpers/dup';
import {YesNoDialogService} from '../../../../shared-components/yes-no-dialog/yes-no-dialog.service';

@Component({
  selector: 'app-ai-settings-dialog',
  templateUrl: './ai-settings-dialog.component.html',
  styleUrls: ['./ai-settings-dialog.component.scss']
})
export class AISettingsDialogComponent implements OnInit {

  form: FormGroupRecord<AISettings, { prompt_types: FormControl<PromptType[]> }>;

  private settings: AISettings

  constructor(
    public dialogRef: MatDialogRef<AISettingsDialogComponent, FormGroup>,
    @Inject(MAT_DIALOG_DATA) private data: Settings,
    private yesNoDialog: YesNoDialogService
  ) {}

  ngOnInit(): void {
    this.settings = Object.assign(dup(defaultAISettings), this.data.ai || {});
    this.form = new FormGroup({
      allow_gdpr_unsafe_files: new FormControl(this.settings.allow_gdpr_unsafe_files),
      prompt_types: new FormControl(this.settings.prompt_types)
    });
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  confirmUploadUnsafeFilesDialog(currentValue: boolean | null): void {
    if(currentValue) return;

    this.yesNoDialog.open({
      title: 'Bekræft beslutning',
      content: 'Er du sikker på du følgende handling?<br>Alle sagens dokumenter, inklusiv dokumenter med personhenførbare data, uploades til AI',
      yes: 'Bekræft',
      no: 'Fortryd'
    }, true).afterClosed().subscribe(bool => {
      if(bool) return;

      this.form.controls.allow_gdpr_unsafe_files.setValue(false);
    });
  }
}
