import {Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {DisplayField} from "../../../../../core/models/display";

@Component({
  selector: 'app-fields-sorting',
  templateUrl: './fields-sorting.component.html',
  styleUrls: ['./fields-sorting.component.scss']
})
export class FieldsSortingComponent implements OnInit{
  @Input() chosenSortingFields: FormArray;
  @Input() availableSortingFields: DisplayField[];
  @Input() sortingNumber: number;
  @Output() addSorting: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeSorting: EventEmitter<any> = new EventEmitter<any>();

  sortingKey: string = '';
  sortingMethod: string = '';

  constructor() {
  }

  ngOnInit() {
    this.resetSorting();

    const currentSortingField = this.chosenSortingFields.at(this.sortingNumber)?.value as string;
    if (currentSortingField) {
      this.sortingMethod = currentSortingField.charAt(0);
      this.sortingKey = currentSortingField.substring(1);
    }
  }

  private resetSorting(): void {
    // Todo: Update available list
    this.sortingKey = '';
    this.sortingMethod = '';
  }

  isAvailable(field: DisplayField): boolean {
    return !!this.availableSortingFields.find(af => af.key === field.key);
  }

  chooseSortingField(sortKey: string) {
    if (sortKey === 'None'){
      this.removeSortingField();
    } else {
      this.addSortingField();
    }
  }

  addSortingField(): void {
    this.sortingMethod = '-';
    this.sort();
  }

  sort(): void {
    // Todo: Update available list
    this.addSorting.emit({
      sortingField: this.sortingMethod + this.sortingKey,
      sortingNumber: this.sortingNumber
    });
  }

  removeSortingField(): void {
    this.removeSorting.emit({sortingField: this.sortingMethod + this.sortingKey, index: this.sortingNumber});
    this.resetSorting();
  }
}
