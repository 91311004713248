import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {filter, map, Observable, Subject} from 'rxjs';

import { ApiConfig } from './api-config';
import {GrantGuide, GrantGuideTag, Profile} from '../models';

@Injectable({
  providedIn: 'root'
})
export class GrantGuidesService {
  guideChange: Subject<void> = new Subject<void>();
  constructor(private http: HttpClient) {}

  /**
   * Subscribe to this Observable to be notified of any changes to the
   * current view settings (filters and sorting).
   */
  onGuideChange(): Observable<void> {
    return this.guideChange;
  }
  getGuides(): Observable<GrantGuide[]> {
    return this.http.get<GrantGuide[]>(`${ApiConfig.getApiUrl('/guides.json')}`)
  }

  getGuidesWithTag(tagId: number): Observable<GrantGuide[]> {
    return this.getGuides().pipe(
      map(guides => guides.filter(guide => guide.guide_tag_ids.includes(tagId)))
    );
  }

  searchGuides(searchString: string): Observable<GrantGuide[]> {
    return this.http.get<GrantGuide[]>(`${ApiConfig.getApiUrl('/guides')}.json?search=${searchString}`);
  }

  getGuide(id: number): Observable<GrantGuide> {
    return this.http.get<GrantGuide>(`${ApiConfig.getApiUrl('/guides')}/${id}.json`);
  }
  getGuideInfo(guideUrl: string): Observable<GrantGuide> {
    return this.http.get<GrantGuide>(`${ApiConfig.getApiUrl('/guides')}/new?url=${guideUrl}`);
  }

  createGuide(guide: GrantGuide): Observable<GrantGuide> {
    return this.http.post<GrantGuide>(`${ApiConfig.getApiUrl('/guides')}.json`, { "guide": guide });
  }

  updateGuide(guide: GrantGuide): Observable<GrantGuide> {
    return this.http.patch<GrantGuide>(`${ApiConfig.getApiUrl('/guides')}/${guide.id}.json`, { "guide": guide });
  }
  refreshGuide(guide: GrantGuide): Observable<GrantGuide> {
    return this.http.patch<GrantGuide>(`${ApiConfig.getApiUrl('/guides')}/${guide.id}/refresh.json`, {});
  }

  deleteGuide(guideId: number): Observable<GrantGuide> {
    return this.http.delete<GrantGuide>(`${ApiConfig.getApiUrl('/guides')}/${guideId}.json`);
  }

  createGuideTag(tag: string): Observable<GrantGuideTag> {
    const payload = {
      name: tag
    }
    return this.http.post<GrantGuideTag>(`${ApiConfig.getApiUrl('/guide_tags')}.json`, payload);
  }
  deleteGuideTag(tagId: number): Observable<GrantGuideTag> {
    return this.http.delete<GrantGuideTag>(`${ApiConfig.getApiUrl('/guide_tags')}/${tagId}.json`);
  }
  getGuideTags(): Observable<GrantGuideTag[]> {
    return this.http.get<GrantGuideTag[]>(`${ApiConfig.getApiUrl('/guide_tags')}.json`);
  }
}
