<h2 mat-dialog-title>
  Massehandling
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <p>Massehandling gør det muligt for kunden at lave én handlinger for mange sager, fx at tilknytte eller fjerne et tag.</p>
  <form [formGroup]="form">
    <div fxLayout="column" fxFlex="500px">
      <mat-form-field>
        <mat-label>Handlinger</mat-label>
        <mat-select formControlName="actions" multiple placeholder="Vælg handlinger">
          <mat-option *ngFor="let action of actions" [value]="action">{{actionT[action]}}</mat-option>
        </mat-select>
        <mat-error>Vælg <strong>mindst</strong> 1 handling.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Handlingers indstillinger</mat-label>
        <mat-select [formControl]="selectedAction">
          <mat-option *ngFor="let action of form.controls.actions.value" [value]="action">{{actionT[action]}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngFor="let action of actions" [hidden]="selectedAction.value !== action" formGroupName="action_options">
        <div fxLayout="column" [formGroupName]="action">
          <mat-form-field>
            <mat-label>Antal handlinger per vindue</mat-label>
            <input
              matInput
              type="number"
              formControlName="buffer"
            >
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
</mat-dialog-actions>
