<mat-card [ngClass]="cssClass">
  <mat-card-title>{{title}}</mat-card-title>
  <mat-card-subtitle *ngIf="usage">
    <span [matTooltip]="'År til dato - ' + (yearToDate | number:'1.0-0')">{{toHuman(yearToDate)}}</span> //
    <span [matTooltip]="'I alt - ' + (total | number:'1.0-0')">{{toHuman(total)}}</span>
  </mat-card-subtitle>
  <mat-card-content *ngIf="usage">
    <ngx-trend
      [data]="usage.data"
      [autoDraw]="true"
      [autoDrawDuration]="1500"
      autoDrawEasing="ease-out"
      [gradient]="['#ffffffb3']"
    ></ngx-trend>
  </mat-card-content>
</mat-card>
