import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { defaultPaymentsSettings, defaultSmsSettings, PaymentsSettings } from '../../../../core/models';

// TODO: Support "frequency" either 'apmf' or week days
@Component({
  selector: 'app-payments-settings',
  templateUrl: './payments-settings.component.html',
  styleUrls: ['./payments-settings.component.scss']
})
export class PaymentsSettingsComponent implements OnInit, OnChanges {

  @Input() settings: PaymentsSettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  value: PaymentsSettings = {...defaultPaymentsSettings };
  form: FormGroup = new FormGroup( {
    notify_accounting: new FormControl<string>('', [Validators.maxLength(100), Validators.email]),
    notify_applicant: new FormControl<boolean>(false),
    'file-format': new FormControl<string>('PaymentsXlsx', [Validators.required]),
    use_p_no: new FormControl<boolean>(false),
    frequency: new FormControl<number[]>([]),
  });

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...defaultPaymentsSettings, ...this.settings };
      }
      this.form.patchValue(this.value);
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultPaymentsSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

  get fileFormat(): FormControl {
    return this.form.get('file-format') as FormControl;
  }

  showUsePNo(): boolean {
    return ['NordeaCsv', 'NykreditCsv'].includes(this.fileFormat.value);
  }

}
