import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {defaultSettings, MaterializedViewSettings} from "../../../../core/models";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormGroupRecord} from '../../../../core/types/form.type';

@Component({
  selector: 'app-materialized-view-settings',
  templateUrl: './materialized-view-settings.component.html',
  styleUrls: ['./materialized-view-settings.component.scss']
})
export class MaterializedViewSettingsComponent implements OnInit, OnChanges {

  protected form: FormGroupRecord<MaterializedViewSettings> = new FormGroup({
    schedule: new FormControl<number>(defaultSettings.materialized_view.schedule, [Validators.required])
  });

  @Input() settings: MaterializedViewSettings;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      this.setSettings();
    }
  }

  setSettings(): void {
    const disabled = this.form.disabled;
    this.form.enable();
    if (this.settings?.schedule) {
      this.form.controls.schedule.setValue(this.settings.schedule)
    }
    if (disabled) {
      this.form.disable();
    }
  }

  edit(): void {
    this.form.enable();
  }

  cancel(): void {
    this.setSettings();
    this.form.disable();
  }

  save(): void {
    this.form.disable();
    this.saveSettings.next(this.form);
  }
}
