export type BankType = 'generic' | 'danske_forvaltning' | 'danske_bank' | 'jyske_bank' | 'bank_connect' | 'bank_integration';
export interface WealthManager {
  id: number;
  bank: BankType;
  custody_no: string;
  nem_konto: boolean;
  accounting: boolean;
  capital: 'more_than_one_million' | 'less_than_one_million';
  exports_enabled: boolean;
  bank_connector_id?: number;
  settings: {
    payment_exported_notify: string[]; // list of emails to notify for payments exported
  }
}
