<h2 mat-dialog-title>
  Rammebeløb / Bevillingsrammer
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content *ngIf="groups$ | async as groups">
  <p>Opsætning af bevillingsrammer for fonden. Bemærk, at bevillingsrammer <em>ikke</em> understøtter tillægsbevillinger.</p>
  <form [formGroup]="form">
    <div class="mb-2">
      <mat-checkbox formControlName="no_exceeding_available">Overtræk på rammer IKKE tilladt.</mat-checkbox>
    </div>
    <div class="mb-2">
      <mat-checkbox formControlName="least_one_source">Mindst en ramme pr sag</mat-checkbox>
    </div>
    <div class="mb-3">
      <mat-checkbox formControlName="multiple_sources">Tillad flere ramme pr sag</mat-checkbox>
    </div>
    <div class="mb-3">
      <mat-checkbox formControlName="allow_change_after_approval">Tillad ændring efter bevilling</mat-checkbox>
    </div>
    <ng-container *ngIf="groups.length > 0">
      <mat-label>Dimensioner</mat-label>
      <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="30px">
        <mat-form-field floatLabel="never">
          <mat-select [formControl]="groupSelector" placeholder="Vælg Ramme" style="min-width: 167px;">
            <mat-option
              *ngFor="let group of groups"
              [value]="group.id"
            >{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button (click)="addDimension()" [disabled]="!groupSelector.dirty">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="dimensionsChange$ | async">
        <mat-card *ngFor="let dimension of currentDimensions?.controls || []; let i = index"
                  fxLayout="row" fxLayoutAlign=" end" fxLayoutGap="50px" class="forecast-dimension mb-3">
          <div>
            <mat-form-field fxLayout="row">
              <mat-label>Navn</mat-label>
              <input matInput [formControl]="dimension.controls.name">
            </mat-form-field>
            <mat-form-field fxLayout="row">
              <mat-label>Kode</mat-label>
              <input matInput [formControl]="dimension.controls.code">
            </mat-form-field>
          </div>
          <button mat-icon-button (click)="removeDimension(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-card>
      </ng-container>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()">Gem</button>
</mat-dialog-actions>
