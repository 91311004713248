import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";

import {catchError, Observable, of, Subject, tap} from "rxjs";

import {Category, Company, Customer} from "../models";
import { ApiConfig } from "./api-config";
import { StatusResponse } from "../models/status-response";
import { Onboarding } from "../models/onboarding";

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  customerChange: Subject<void> = new Subject<void>();

  /**
   * Subscribe to this Observable to be notified of any changes to the
   * current view settings (filters and sorting).
   */
  onCustomerChange(): Observable<void> {
    return this.customerChange;
  }

  constructor(private http: HttpClient) { }

  getCustomers(): Observable<Customer[]> {
    const url = ApiConfig.getApiUrl('/customers.json');
    return this.http.get<Customer[]>(url)
      .pipe(
        tap(customers => console.log('CustomersService.getCustomers() =>', customers)),
        catchError(this.handleError<any>('getCustomers', []))
      );
  }

  search(query: string): Observable<Customer[]> {
    const url = ApiConfig.getApiUrl('/customers.json');
    const params = new HttpParams().set('query', query);
    return this.http.get<Customer[]>(url, { params: params })
      .pipe(
        tap(customers => console.log(`CustomersService.searchCustomers(${query}) =>`, customers)),
        catchError(this.handleError<any>('searchCustomers', []))
      );
  }

  getCustomer(id: number): Observable<Customer> {
    const url = ApiConfig.getApiUrl(`/customers/${id}.json`);
    return this.http.get<Customer>(url)
      .pipe(
        tap(customer => console.log(`CustomersService.getCustomer(${id}) =>`, customer)),
        catchError(this.handleError<any>('getCustomer', null))
      );
  }

  create(onboarding: Onboarding): Observable<Customer> {
    const url = ApiConfig.getApiUrl('/customer.json');
    return this.http.post<Customer>(url, onboarding)
      .pipe(
        tap(data => console.log(`CustomersService.create() =>`, data))
      );
  }

  update(customer: Customer): Observable<Customer> {
    const url = ApiConfig.getApiUrl(`/customers/${customer.id}.json`);
    delete customer.logo;
    delete customer.data_policy;
    return this.http.patch<Customer>(url, { customer: customer })
      .pipe(
        tap(data => console.log(`CustomersService.update(${customer.id}) =>`, data))
      );
  }

  activate(customer: Customer): Observable<any> {
    const url = ApiConfig.getApiUrl(`/customers/${customer.id}/activate.json`, customer.subdomain);
    return this.http.patch(url, {})
      .pipe(
        tap(data => console.log(`CustomersService.activate(${customer.id}) =>`, data))
      );
  }

  lookupCompany(cvr: string): Observable<Company> {
    const url = ApiConfig.getApiUrl(`/virks/${cvr}/lookup_company.json`);
    return this.http.get<Company>(url)
      .pipe(
        tap(data => console.log(`CustomersService.lookupCompany(${cvr}) =>`, data)),
        catchError(this.handleError<any>('lookupCompany', null))
      );
  }

  checkSubdomain(subdomain: string): Observable<StatusResponse> {
    const url = ApiConfig.getApiUrl(`/check_domain.json?domain=${subdomain}`);
    return this.http.get<StatusResponse>(url)
      .pipe(
        tap(data => console.log(`CustomersService.checkSubdomain(${subdomain}) =>`, data)),
        catchError(this.handleError<any>('checkSubdomain', null))
      );
  }

  getCategories(subdomain: string): Observable<Category[]> {
    const url = ApiConfig.getApiUrl('/categories.json', subdomain);
    return this.http.get<Category[]>(url);
  }

  updateCategory(category: Category, subdomain: string): Observable<Category> {
    const url = ApiConfig.getApiUrl('/categories/' + category.id + '.json', subdomain);
    return this.http.patch<Category>(url, { category: category });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
