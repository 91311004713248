import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Observable, switchMap} from "rxjs";
import {FontSelection} from "../../../../core/models/theme";
import {FontService} from "../../../../core/services/customer-theme-services/font.service";

@Component({
  selector: 'app-font-selection',
  templateUrl: './font-selection.component.html',
  styleUrls: ['./font-selection.component.scss']
})
export class FontSelectionComponent implements OnInit {
  @Input() fontSelection: FormGroup;
  @Output() onPickFont: EventEmitter<{ type: 'header-font' | 'body-font', f: FontSelection }> = new EventEmitter<{ type: 'header-font' | 'body-font', f: FontSelection }>;

  @Input() currentHeaderFont: FontSelection;
  @Input() currentBodyFont: FontSelection;
  fontsHeader$: Observable<FontSelection[]>;
  fontsBody$: Observable<FontSelection[]>;
  weightValues: Array<number> = [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800
  ]
  constructor(private fontService: FontService,
  ) { }

  ngOnInit(): void {
    this.initFonts();
  }

  private initFonts() {
    this.fontsHeader$ = this.searchHeader.valueChanges.pipe(
      switchMap(search => this.fontService.searchAllFonts(search))
    );
    this.fontsBody$ = this.searchBody.valueChanges.pipe(
      switchMap(search => this.fontService.searchAllFonts(search))
    );
  }

  pickFont(type: 'header-font' | 'body-font', f: FontSelection): void {
    this.onPickFont.emit({type: type, f: f})
  }

  private get searchHeader(): FormControl {
    return this.fontSelection.get('searchHeader') as FormControl;
  }
  private get searchBody(): FormControl {
    return this.fontSelection.get('searchBody') as FormControl;
  }
}
