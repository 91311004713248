import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeepAliveService {

  private pingInterval: number = 10 * 60;
  private pingHandle: any;
  private pingCallback: any;

  /*
   * Initializes a new instance of Keepalive
   * @param zone - The zone
   */
  constructor(private zone: NgZone) {}

  setCallback(callback: () => void): void {
    this.pingCallback = callback;
  }

  /*
   * Sets the interval (in seconds) at which the ping operation will occur when start() is called.
   * @param seconds - The ping interval in seconds.
   * @return The current interval value.
   */
  interval(seconds: number): number {
    if (!isNaN(seconds) && seconds > 0) {
      this.pingInterval = seconds;
    } else if (!isNaN(seconds) && seconds <= 0) {
      throw new Error('Interval value must be greater than zero.');
    }

    return this.pingInterval;
  }

  /*
   * Immediately performs the ping operation. If a request has been set, an HTTP
   * request will be made and the response will be emitted via the
   * onPingResponse event.
   */
  ping(): void {
    if (this.pingCallback) {
      this.pingCallback();
    }
  }

  /*
   * Starts pinging on an interval.
   */
  start(): void {
    this.stop();

    this.zone.runOutsideAngular(() => {
      this.pingHandle = setInterval(() => {
        this.zone.run(() => {
          this.ping();
        });
      }, this.pingInterval * 1000);
    });
  }

  /*
   * Stops pinging on an interval.
   */
  stop(): void {
    if (this.hasPingHandle()) {
      clearInterval(this.pingHandle);
      this.pingHandle = null;
    }
  }

  /*
   * Performs any cleanup tasks when Angular destroys the instance.
   */
  ngOnDestroy(): void {
    this.stop();
  }

  /*
   * Returns whether or not the service will ping automatically at the specified interval.
   * @return True if the service will ping at the specified interval; otherwise, false.
   */
  isRunning(): boolean {
    return this.hasPingHandle();
  }

  private hasPingHandle(): boolean {
    return this.pingHandle !== null && typeof this.pingHandle !== 'undefined';
  }

}
