<mat-card>
  <mat-card-title-group>
    <mat-card-title>Indstillinger</mat-card-title>
  </mat-card-title-group>
  <mat-card-content>
    Opsætninger, som ikke er knyttet til et specifikt modul.
  </mat-card-content>
</mat-card>

<mat-accordion>
  <app-payments-settings
    [settings]="customer.settings.payments"
    (saveSettings)="save('payments', $event)"
  ></app-payments-settings>

  <app-dialog-settings
  ></app-dialog-settings>

  <app-authentication-settings
    [settings]="customer.settings.authentication"
    (saveSettings)="save('authentication', $event)"
  ></app-authentication-settings>

  <app-accounting-settings
    [settings]="customer.settings.accounting"
    (saveSettings)="save('accounting', $event)"
  ></app-accounting-settings>

  <app-overview-xlsx-settings
    [settings]="customer.settings.overview_xlsx"
    (saveSettings)="save('overview_xlsx', $event)"
  ></app-overview-xlsx-settings>

  <app-gdpr-settings
    [settings]="customer.settings.gdpr"
    (saveSettings)="save('gdpr', $event)"
  ></app-gdpr-settings>

  <app-history-settings
    [settings]="customer.settings.applicant"
    (saveSettings)="save('applicant', $event)"
  ></app-history-settings>

  <app-import-settings
    [settings]="customer.settings.import"
    (saveSettings)="save('import', $event)"
  ></app-import-settings>

  <app-security-settings
    [settings]="customer.settings.security"
    (saveSettings)="save('security', $event)"
  ></app-security-settings>

  <app-custom-value-settings
    [settings]="customer.settings['custom-value']"
    (saveSettings)="save('custom-value', $event)"
  ></app-custom-value-settings>

  <app-case-worker-settings
    [settings]="customer.settings['case-workers']"
    (saveSettings)="save('case-workers', $event)"
  ></app-case-worker-settings>

  <app-sms-settings
    [settings]="customer.settings.sms"
    (saveSettings)="save('sms', $event)"
  ></app-sms-settings>

  <app-support-settings
    [settings]="customer.settings.support"
    (saveSettings)="save('support', $event)"
  ></app-support-settings>

  <app-extra-columns-settings
  ></app-extra-columns-settings>

  <app-materialized-view-settings
    [settings]="customer.settings.materialized_view"
    (saveSettings)="save('materialized_view', $event)"
  ></app-materialized-view-settings>

  <app-siblings-settings></app-siblings-settings>

  <app-votes-settings [settings]="customer.settings.votes"
                      (saveSettings)="save('votes', $event)"></app-votes-settings>

<app-warnings-settings
  [settings]="customer.settings.warnings"
  (saveSettings)="save('warnings', $event)"
></app-warnings-settings>

</mat-accordion>
