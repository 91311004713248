import {Component, Inject, OnInit} from '@angular/core';
import {BulkActionOption, BulkActionOptions, BulkActionSettings, Settings} from "../../../../../core/models";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {actionTranslations, BulkAction, bulkActions} from '../../../../../core/models/bulk-action';
import {NestedRequired} from '../../../../../core/types';
import {dig} from '../../../../../core/helpers/dig';
import {FormGroupRecord} from '../../../../../core/types/form.type';

type ActionOptionsForm = FormGroupRecord<NestedRequired<BulkActionOptions>>;

@Component({
  selector: 'app-bulk-action-settings-dialog',
  templateUrl: './bulk-action-settings-dialog.component.html',
  styleUrls: ['./bulk-action-settings-dialog.component.scss']
})
export class BulkActionSettingsDialogComponent implements OnInit {

  settings: BulkActionSettings;
  actionT = actionTranslations;
  actions = bulkActions;
  selectedAction: FormControl<BulkAction | null> = new FormControl(null);

  form: FormGroup<{
    actions: FormControl<BulkAction[] | null>,
    action_options: ActionOptionsForm
  }>;

  constructor(public dialogRef: MatDialogRef<BulkActionSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.settings = Object.assign({actions: []}, this.data.bulk_actions);
    this.form = this.fb.group({
      actions: [this.settings.actions || [], [Validators.required]],
      action_options: this.initActionOptionsForm()
    });
  }

  doSave(): void {
    this.disableUnusedBulkActionOptions();
    this.dialogRef.close(this.form);
  }

  private initActionOptionsForm(): ActionOptionsForm {
    const settings: BulkActionOptions = this.settings.action_options || {};
    const form: Partial<Record<BulkAction, FormGroupRecord<BulkActionOption>>> = {};
    this.actions.forEach(key => {
      form[key] = this.fb.group({
        buffer: [dig<number>(settings, key, 'buffer') || null]
      });
    });
    return this.fb.group(form) as ActionOptionsForm;
  }

  private disableUnusedBulkActionOptions(): void {
    const selectedActions = this.form.controls.actions.value as BulkAction[];
    const actionOptions = this.form.controls.action_options.controls;
    this.actions.forEach(action => {
      const options = actionOptions[action];
      if (!selectedActions.includes(action)) {
        options.disable();
        return;
      }

      Object.keys(options.controls).forEach(key => {
        const controller = options.controls[key as keyof BulkActionOption];
        if (controller.value === null) {
          controller.disable()
        }
      });
    });
  }
}
