import {Component, Inject, OnInit} from '@angular/core';
import {Category} from "../../../../core/models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {ENTER} from "@angular/cdk/keycodes";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

export interface EditCategoryData {
  category: Category
}
export type FormatType = {
  value: string,
  name: string
}

@Component({
  selector: 'app-edit-category-dialog',
  templateUrl: './edit-category-dialog.component.html',
  styleUrls: ['./edit-category-dialog.component.scss']
})
export class EditCategoryDialogComponent implements OnInit {

  userValue: FormControl = new FormControl<string>('');
  separatorKeyCodes: number[] = [ENTER];
  currentFormat: (FormatType | string)[] = [];
  showLeadingZeroes: boolean = false;
  constructor(public dialogRef: MatDialogRef<EditCategoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EditCategoryData) { }

  ngOnInit(): void {
    const journalPatternRegex = /%<category>s|%<year>[ds]{1}|%<no>\d+[ds]{1}|-+|\s*([0-9a-zA-Z]+)\s*/g;
    const formatTypeMatch = this.data.category.journal_no_pattern?.match(journalPatternRegex) || []
    formatTypeMatch.forEach(m => {
      this.showLeadingZeroes = false;
      if (m.includes('no')) {
        if (m.charAt(m.length - 3) === '0') {
          this.showLeadingZeroes = true;
        }
        const num = Number(m.slice(-2, -1)) // get num
        this.addNumberFormatType(num)
      } else if (m.includes('category')) {
        this.addFormatType('category')
      } else if (m.includes('year')){
        this.addFormatType('year')
      } else {
        this.addUserValue(m)
      }
    })

  }

  saveJournalNoPattern(): void {
    let returnValue = ''
    this.currentFormat.forEach(f => {
      if (typeof f === 'string') {
        returnValue += f;
      } else {
        returnValue += f.value
      }
    });
    this.dialogRef.close(returnValue);
  }

  drop(event: CdkDragDrop<(FormatType | string)[]>) {
    moveItemInArray(this.currentFormat, event.previousIndex, event.currentIndex);
  }

  removeSubFormatType(subFormat: FormatType | string): void {
    this.currentFormat.splice(this.currentFormat.indexOf(subFormat), 1)
  }
  addUserValue(value: string) {
    if (value) {
      this.addFormatType(value)
      this.userValue.setValue(null)
    }
  }

  addFormatType(type: string): void {
    this.currentFormat.push(this.getFormatType(type))
  }

  addNumberFormatType(no: number): void {
    const value = this.showLeadingZeroes ? `%<no>0${no}d` : `%<no>${no}d`
    let name = '';
    if (this.showLeadingZeroes) {
      for (let i = 0; i < no; i++) {
        i === no - 1 ? name += '1' : name += '0'
      }
    } else {
      name = '1'
    }
    const type = {
      value: value,
      name: name
    }
    this.currentFormat.push(type)
  }

  toggleShowLeadingZeroes(): void {
    this.showLeadingZeroes = !this.showLeadingZeroes;
  }

  getFormatType(type: string): FormatType | string {
    if (type === 'category'){
      return  {
        value: '%<category>s',
        name: this.data.category.initials
      }
    } else if (type === 'year') {
      return {
        value: '%<year>s',
        name: new Date().getFullYear().toString()
      }
    } else {
      return type
    }
  }

  getDisplayValue(value: FormatType | string): string {
    return typeof value === 'string' ? value : value?.name
  }

  getExampleJournalNo(): string {
    let exampleValue = '';
    this.currentFormat.forEach(f => {
      if (typeof f === 'string') {
        exampleValue += f;
      } else {
        if (f.value.includes('year')) {
          exampleValue += new Date().getFullYear().toString()
        } else if (f.value.includes('category')) {
          exampleValue += this.data.category.initials
        } else {
          exampleValue += f.name;
        }
      }
    });
    return exampleValue
  }

}
