import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomValueSettings, defaultCustomValueSettings } from '../../../../core/models';


@Component({
  selector: 'app-custom-value-settings',
  templateUrl: './custom-value-settings.component.html',
  styleUrls: ['./custom-value-settings.component.scss']
})
export class CustomValueSettingsComponent implements OnInit, OnChanges {

  @Input() settings: CustomValueSettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  value: CustomValueSettings = {
    ...defaultCustomValueSettings
  };
  form: FormGroup = new FormGroup({
    decimals: new FormControl<number>(0, [Validators.required, Validators.min(0), Validators.max(3)])
  });

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      this.value = {
        ...defaultCustomValueSettings,
        ...this.settings
      };
      this.form.patchValue(this.value);
      this.form.disable();
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultCustomValueSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

  get decimals(): FormControl {
    return this.form.get('decimals') as FormControl;
  }
}
