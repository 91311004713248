<mat-card>
  <mat-card-title-group>
    <mat-card-title>Bankoplysninger</mat-card-title>
    <mat-card-subtitle>{{customer.name}}</mat-card-subtitle>
  </mat-card-title-group>
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between top">
      <dl fxFlex="25">
        <dt>Bank</dt>
        <dd [ngSwitch]="customer.wealth_manager.bank">
          <span *ngSwitchCase="'generic'">Ingen/generisk</span>
          <span *ngSwitchCase="'danske_bank'">Danske Bank District</span>
          <span *ngSwitchCase="'danske_forvaltning'">Danske Bank Forvaltning</span>
          <span *ngSwitchCase="'jyske_bank'">Jyske Bank</span>
          <span *ngSwitchCase="'bank_connect'">BankConnect</span>
          <span *ngSwitchCase="'bank_integration'">BankIntegration</span>
          <span *ngSwitchDefault>Ukendt</span>
        </dd>

        <dt>Depot-/kontonummer</dt>
        <dd>{{customer.wealth_manager.custody_no | accountNumber }}</dd>

        <dt>Valuta</dt>
        <dd>{{customer.currency}}</dd>
      </dl>
      <mat-divider [vertical]="true"></mat-divider>
      <dl fxFlex="25">
        <dt>NemKonto</dt>
        <dd>{{customer.wealth_manager.nem_konto ? 'Ja' : 'Nej'}}</dd>

        <dt>Regnskabskunde</dt>
        <dd>{{customer.wealth_manager.accounting ? 'Ja' : 'Nej'}}</dd>

      </dl>
      <mat-divider [vertical]="true"></mat-divider>
      <dl fxFlex="25">
        <dt>Overføres betalinger direkte til banken</dt>
        <dd>{{customer.wealth_manager.exports_enabled ? 'Ja' : 'Nej'}}</dd>
      </dl>
    </div>
    <mat-divider [vertical]="false"></mat-divider>
    <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start top">
      <dl fxFlex="50">
        <div *ngIf="payment_exported_notify.disabled">
          <dt>Email liste for mail med betalingsliste:</dt>
          <dd *ngFor="let mail of currentEmailReceivers">{{mail}}</dd>
          <button mat-raised-button color="accent" (click)="doEdit()">Rediger</button>
        </div>
        <div fxLayout="column" *ngIf="!payment_exported_notify.disabled">
          <div fxLayout="row">
            <mat-form-field fxFlex="100" class="mb-3">
              <mat-label>Skal modtage notifikation om dagens eksporterede betalinger</mat-label>
              <input type="text" matInput
                     class="form-control"
                     [formControl]="payment_exported_notify"
                     id="email"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                     [matChipInputAddOnBlur]="true"
                     (matChipInputTokenEnd)="addEmail()">
              <mat-hint>Klik Enter, Komma, eller Spacebar for tilføje mail </mat-hint>
            </mat-form-field>
          </div>
          <mat-chip-list #chipList class="mb-3">
            <ng-container *ngFor="let mail of currentEmailReceivers">
              <mat-chip selected
                        color="accent"
                        [removable]="true"
                        (removed)="removeMail(mail)">
                <span>{{mail}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </ng-container>
          </mat-chip-list>
          <div>
            <button mat-raised-button color="primary" class="mr-3" (click)="doCancel()">Fortryd</button>
            <button mat-raised-button color="accent" (click)="doSave()" [disabled]="payment_exported_notify.invalid">Gem</button>
          </div>
        </div>
      </dl>
    </div>
  </mat-card-content>
</mat-card>
