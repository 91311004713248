export const overviewXlsxColumns = [
  'project.journal_no',
  'project.title',
  'project.approved_on',
  'project.applied_amount',
  'project.total_amount',
  'project.own_funding',
  'project.note',
  'project.needed_amount',
  'project.submitted_on',
  'project.start_on',
  'project.end_on',
  'project.expires_on',
  'project.custom_value',
  'project.currency',
  'project.exchange_rate',
  'project.external_reference',
  'project.avg_score',
  'project.weighted_score',
  'project.category',
  'project.subcategory',
  'project.tax_code',
  'project.finished',
  'project.archived_on',
  'project.case_workers',
  'project.primary_assignees',
  'project.restrictive_tags',
  'applicant.company_or_name',
  'applicant.name',
  'applicant.company',
  'applicant.social_security_no',
  'applicant.company_registration_no',
  'applicant.production_unit_no',
  'applicant.production_unit_name',
  'applicant.department',
  'applicant.industry',
  'applicant.street',
  'applicant.phone',
  'applicant.email',
  'applicant.birthday',
  'applicant.taxpayer_identification_no',
  'applicant.employee_no',
  'applicant.zip',
  'applicant.city',
  'applicant.country',
  'applicant.social_security_no_or_company_registration_no',
  'meeting.title',
  'meeting.date',
  'meeting.notes',
  'meeting.last_application_on',
  'meeting.meeting_group_title',
  'meeting.held',
  'overview.granted_amount',
  'overview.granted_during',
  'overview.paid_during',
  'overview.dropped_during',
  'overview.available_start',
  'overview.available_stop',
  'overview.hidden_stop'
] as const;
export type OverviewXlsxColumn = typeof overviewXlsxColumns[number];
