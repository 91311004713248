import { Component, Inject, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CriteriaSettings, Settings } from '../../../../../core/models';

@Component({
  selector: 'app-criteria-settings-dialog',
  templateUrl: './criteria-settings-dialog.component.html',
  styleUrls: ['./criteria-settings-dialog.component.scss']
})
export class CriteriaSettingsDialogComponent implements OnInit {

  settings: CriteriaSettings;

  form: FormGroup = new FormGroup({
    individual: new FormControl<boolean>(false),
    pct_precision: new FormControl<number>(0, [Validators.min(0), Validators.max(2),
      Validators.pattern("^[0-9]+$")])
  });

  constructor(public dialogRef: MatDialogRef<CriteriaSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data.criteria || { individual: false, pct_precision: 9 };
    this.form.patchValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

}
