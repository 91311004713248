import { Injectable } from '@angular/core';
import {ApiConfig} from "./api-config";
import {HttpClient} from "@angular/common/http";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {Translation} from "../models/translation";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor( private translate: TranslateService,
               private http: HttpClient) { }

  loadTranslations(subdomain: string): void{
    this.translate.use('da');
    this.http.get<object>(ApiConfig.getApiUrl("/translations/da/all.json", subdomain))
      .subscribe(translations => this.translate.setTranslation(this.translate.currentLang, translations, true))
  }

  getTranslations(subdomain: string, language: string): Observable<Map<string, string>> {
    return this.http.get<Map<string,string>>(ApiConfig.getApiUrl(`/translations/${language}/all.json`, subdomain));
  }

  setTranslation(subdomain: string, language: string, key: string, value: string): Observable<string> {
    const url = ApiConfig.getApiUrl(`/translations/${language}.json`, subdomain);
    return this.http.patch<string>(url, { translation: { key: key, value: value }});
  }

  addNewTranslation(subdomain: string, language: string, translation: Translation): Observable<any> {
    const url = ApiConfig.getApiUrl(`/translations/${language}.json`, subdomain);
    return this.http.patch<any>(url, {translation})
  }

  addNewTranslations(subdomain: string, language: string, translations: Translation[]): Observable<Translation[]> {
    const url = ApiConfig.getApiUrl(`/translations/${language}/multiple.json`, subdomain);
    return this.http.patch<Translation[]>(url, {translations})
  }
}
