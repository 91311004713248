import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RecordService} from '../helpers/records/record-service';
import {Questionnaire} from '../models/Questionnaire';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionnairesService extends RecordService<Questionnaire> {

  constructor(private http: HttpClient) {
    super('/questionnaire/questionnaires', http);
  }

  tenantClone(subdomain: string, target: string, id: number): Observable<void> {
    return this.http.post<void>(this.recordUrl(subdomain, '/:?/tenant_clone.json', id), { target: target });
  }

}
