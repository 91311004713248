<h2 mat-dialog-title>
  Automatisk bevillingskontrol
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <p>Automatisk bevillingskontrol godkender automatisk sager, som har ligget i bevillingskontrol i en given periode uden at blive agvist.</p>
  <form [formGroup]="form">
    <div>
      <mat-checkbox
        formControlName="list_projects"
        matTooltip="Listen indeholder titel, journalnr., ansøgt og bevilget beløb."
      >Inkluder liste med sager i mail.</mat-checkbox>
    </div>
    <div formGroupName="automatic">
      <div class="mb-2">
        <mat-checkbox formControlName="enabled">Aktiver automatisk godkendelse</mat-checkbox>
      </div>
      <mat-form-field *ngIf="enabled.value === true">
        <mat-label>Karensperiode</mat-label>
        <input
          type="number"
          matInput
          formControlName="grace"
        >
        <mat-hint>Antal dage i bevillingskontrol før automatisk godkendelse.</mat-hint>
        <mat-error *ngIf="grace.hasError('required')"><strong>Skal</strong> udfyldes.</mat-error>
        <mat-error *ngIf="grace.hasError('min')"><strong>Mindst</strong> 1 dag.</mat-error>
        <mat-error *ngIf="grace.hasError('max')"><strong>Højst</strong> 100 dage.</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
</mat-dialog-actions>
