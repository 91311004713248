import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {
  RecordsPermissionFormGroup,
  recordsPermissionMap,
  RecordsPermissionMap
} from '../../../../core/models/records-permission';
import {IntegrationUser, IntegrationUserFormGroup} from '../../../../core/models/integration-user';
import {toFormControlArray} from '../../../../core/helpers/form.helpers';

export type EditIntegrationUserDialogData = IntegrationUser;
export type EditIntegrationUserDialogOutput = IntegrationUser;

@Component({
  selector: 'app-edit-integration-user-dialog',
  templateUrl: './edit-integration-user-dialog.component.html'
})
export class EditIntegrationUserDialogComponent implements OnInit {

  form: IntegrationUserFormGroup;
  recordsPermission: RecordsPermissionMap = recordsPermissionMap();
  edit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditIntegrationUserDialogComponent, EditIntegrationUserDialogOutput>,
    @Inject(MAT_DIALOG_DATA) public data: EditIntegrationUserDialogData,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.edit = this.data.id > 0;
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      id: [this.data.id],
      username: [this.data.username, [Validators.required]],
      password: ['', this.passwordValidators()],
      ip_addresses: this.fb.array(
        toFormControlArray(this.data.ip_addresses, { validators: [Validators.required] })
      ),
      permissions: this.fb.group({
        records: this.recordsPermissionFormGroup(),
        posting: this.fb.group({
          manage: [!!this.data.permissions.posting?.manage]
        })
      })
    });
  }

  confirm(): void {
    const data = this.form.value as IntegrationUser;
    if (!data.password) {
      delete data.password;
    } else {
      data.password_confirmation = data.password;
    }
    this.dialogRef.close(data);
  }

  addIpAddress(): void {
    this.form.controls.ip_addresses.push(new FormControl('', [Validators.required]));
  }

  removeIpAddress(i: number): void {
    this.form.controls.ip_addresses.removeAt(i);
  }

  private passwordValidators(): ValidatorFn[] {
    return this.edit ?
      [Validators.pattern(/^$|^.{8,}$/)] :
      [Validators.required, Validators.minLength(8)]
  }

  private recordsPermissionFormGroup(): RecordsPermissionFormGroup {
    const records: Record<string, unknown> = this.data.permissions.records || {};
    const formGroupData: Record<string, [unknown]> = {};
    Object.keys(this.recordsPermission).forEach(key => {
      formGroupData[key] = [records[key] || []]
    });
    return this.fb.group(formGroupData) as any;
  }
}
