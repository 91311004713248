import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Permissions, User} from "../../../../core/models/user";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-user-info-dialog',
  templateUrl: './user-info-dialog.component.html',
  styleUrls: ['./user-info-dialog.component.scss']
})
export class UserInfoDialogComponent {

  user!: User;
  permissions: Permissions;

  constructor(public dialogRef: MatDialogRef<UserInfoDialogComponent>,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.user = this.data.user
    this.permissions = this.user.role.permissions;
  }

  getPermission(key: any): { key: string, value: boolean } {
    return this.permissions[key];
  }
}
