import {Injectable} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {YesNoDialogComponent, YesNoDialogData} from "./yes-no-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class YesNoDialogService {

  constructor(private dialog: MatDialog) {}

  open(data: YesNoDialogData, disableClose: boolean = false): MatDialogRef<YesNoDialogComponent, boolean> {
    return this.dialog.open<YesNoDialogComponent, YesNoDialogData, boolean>(
      YesNoDialogComponent,
      {
        data,
        panelClass: 'dialog',
        width: '400px',
        disableClose: disableClose
      }
    );
  }

}
