<h2 mat-dialog-title>
  Betalingsbilag
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <div class="mb-2">
      <mat-checkbox formControlName="receipts_amount_check">Summen af bilagsbeløb skal mindst svare til det anmodede beløb.</mat-checkbox>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()">Gem</button>
</mat-dialog-actions>
