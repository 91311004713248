import {Component, OnInit} from '@angular/core';
import { AuthenticationService } from "../../core/services";
import { Router } from "@angular/router";
import {TranslationService} from "../../core/services/translation.service";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              private translationService: TranslationService,
              private router: Router) {}

  ngOnInit() {
    this.translationService.loadTranslations('admin');
  }

  doSignOut() {
    this.authenticationService.signOut();
    location.reload();
  }

  goToSidekiq(): void {
    this.goTo(environment.sidekiqUrl);
  }

  goToSupport(): void {
    this.goTo('https://support.grant.nu/');
  }

  private goTo(url: string): void {
    window.open(url, "_blank");
  }
}
