<div fxLayout="row wrap" fxLayoutGap="2rem grid">
  <div fxFlex="30" fxLayout="column">
    <mat-label fxFlex>Primær farve</mat-label>
    <div fxFlex="100" [style.background-color]="primaryColor.value" fxLayoutAlign="space-between center" class="mb-2">
      <input [colorPicker]="primaryColor.value" class="color-picker" [cpPosition]="'bottom'"
             [cpOutputFormat]="'hex'" (colorPickerChange)="setPrimary($event)" [value]="primaryColor.value"
             [style.background]="primaryColor.value" [style.color]="getTextColor(primaryColor.value)"/>
    </div>
    <ng-container *ngFor="let color of generatePalette(primaryColor.value, 'primary')">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" [style.background-color]="color.hex" class="p-2">
        <span [style.color]="getTextColor(color.hex)">{{color.name}}</span>
        <span [style.color]="getTextColor(color.hex)" class="color-indicator">{{color.hex}}</span>
      </div>
    </ng-container>

  </div>
  <div fxFlex="30" fxLayout="column">
    <mat-label fxFlex>Sekundær farve</mat-label>
    <div fxFlex="100" [style.background-color]="accentColor.value" fxLayoutAlign="space-between center" class="mb-2">
      <input [colorPicker]="accentColor.value" class="color-picker" [cpPosition]="'bottom'"
             [cpOutputFormat]="'hex'" (colorPickerChange)="setAccent($event)" [value]="accentColor.value"
             [style.background]="accentColor.value" [style.color]="getTextColor(accentColor.value)"/>
    </div>
    <ng-container *ngFor="let color of generatePalette(accentColor.value, 'accent')">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" [style.background-color]="color.hex" class="p-2">
        <span [style.color]="getTextColor(color.hex)">{{color.name}}</span>
        <span [style.color]="getTextColor(color.hex)" class="color-indicator">{{color.hex}}</span>
      </div>
    </ng-container>
  </div>

  <div fxFlex="30" fxLayout="column">
    <mat-label fxFlex>Advarsel farve</mat-label>
    <div fxFlex="100" [style.background-color]="warnColor.value" fxLayoutAlign="space-between center" class="mb-2">
      <input [colorPicker]="warnColor.value" class="color-picker" [cpPosition]="'bottom'"
             [cpOutputFormat]="'hex'" (colorPickerChange)="setWarn($event)" [value]="warnColor.value"
             [style.background]="warnColor.value" [style.color]="getTextColor(warnColor.value)"/>
    </div>
    <ng-container *ngFor="let color of generatePalette(warnColor.value, 'warn')">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" [style.background-color]="color.hex" class="p-2">
        <span [style.color]="getTextColor(color.hex)">{{color.name}}</span>
        <span [style.color]="getTextColor(color.hex)" class="color-indicator">{{color.hex}}</span>
      </div>
    </ng-container>
  </div>

  <div fxFlex="30" fxLayout="column">
    <mat-label fxFlex>Mat Card farve</mat-label>
    <div fxFlex="100" [style.background-color]="matCardColor.value" fxLayoutAlign="space-between center" class="mb-2">
      <input [colorPicker]="matCardColor.value" class="color-picker" [cpPosition]="'bottom'"
             [cpOutputFormat]="'hex'" (colorPickerChange)="setMatColor($event)" [value]="matCardColor.value"
             [style.background]="matCardColor.value" [style.color]="getTextColor(matCardColor.value)"/>
    </div>
  </div>

  <div fxFlex="30" fxLayout="column">
    <mat-label fxFlex>Baggrundsfarve</mat-label>
    <div fxFlex="100" [style.background-color]="backgroundColor.value" fxLayoutAlign="space-between center" class="mb-2">
      <input [colorPicker]="backgroundColor.value" class="color-picker" [cpPosition]="'bottom'"
             [cpOutputFormat]="'hex'" (colorPickerChange)="setBackgroundColor($event)" [value]="backgroundColor.value"
             [style.background]="backgroundColor.value" [style.color]="getTextColor(backgroundColor.value)"/>
    </div>
  </div>

  <div fxFlex="30" fxLayout="column">
    <mat-label fxFlex>Body (tekst) farve</mat-label>
    <div fxFlex="100" [style.background-color]="textColor.value" fxLayoutAlign="space-between center" class="mb-2">
      <input [colorPicker]="textColor.value" class="color-picker" [cpPosition]="'bottom'"
             [cpOutputFormat]="'hex'" (colorPickerChange)="setTextColor($event)" [value]="textColor.value"
             [style.background]="textColor.value" [style.color]="getTextColor(textColor.value)"/>
    </div>
  </div>
  <div fxFlex="30" fxLayout="column">
    <mat-label fxFlex>Header tekst farve</mat-label>
    <div fxFlex="100" [style.background-color]="headerTextColor.value" fxLayoutAlign="space-between center" class="mb-2">
      <input [colorPicker]="headerTextColor.value" class="color-picker" [cpPosition]="'bottom'"
             [cpOutputFormat]="'hex'" (colorPickerChange)="setHeaderTextColor($event)" [value]="headerTextColor.value"
             [style.background]="headerTextColor.value" [style.color]="getTextColor(headerTextColor.value)"/>
    </div>
  </div>
</div>
