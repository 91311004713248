export interface Company {
  company_registration_no: string;
  city: string;
  company: string;
  company_type: string;
  country: string;
  email: string;
  phone: string;
  street: string;
  zip: number;
}
