// Helper to build GrantOne API urls
export class ApiConfig {

  public static getBaseUrl(subdomain:string = ''): string {
    let url = location.origin;
    if (!!subdomain) {
      url = url.replace(/\/\/[\w-]+(?=\.)/, '//' + subdomain)
    }
    return url;
  }

  public static getUrl(path: string, subdomain:string = ''): string {
    return ApiConfig.getBaseUrl(subdomain) + path;
  }

  public static getBaseApiUrl(subdomain:string = ''): string {
    return `${this.getBaseUrl(subdomain)}/api`;
  }

  public static getApiUrl(path: string, subdomain:string = ''): string {
    return ApiConfig.getBaseApiUrl(subdomain) + path;
  }

}
