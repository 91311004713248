<mat-card class="mb-3">
  <mat-card-title>Skema kopiering</mat-card-title>
  <mat-card-content>
    <div fxFlex="30" fxLayout="column">
      <div fxFlex fxLayout="column">
        <mat-form-field floatLabel="never">
          <mat-select
            [formControl]="form.controls.id"
            placeholder="Vælg skema"
          >
            <mat-option
              *ngFor="let questionnaire of questionnaires$ | async"
              [value]="questionnaire.id"
            >{{questionnaire.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex fxLayout="column">
        <mat-form-field floatLabel="never">
          <mat-select
            [formControl]="form.controls.target"
            placeholder="Vælg fond"
          >
            <mat-option
              *ngFor="let customer of customers$ | async"
              [value]="customer.subdomain"
            >{{customer.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex fxLayout="column"></div>
  </mat-card-content>
  <mat-card-actions class="questionnaire-clone-actions">
    <button
      *ngIf="form.enabled"
      mat-stroked-button
      color="accent"
      [disabled]="form.invalid"
      (click)="clone()"
    >Kopier</button>
    <mat-progress-spinner
      *ngIf="form.disabled"
      color="accent"
      [diameter]="24"
      mode="indeterminate"
    ></mat-progress-spinner>
  </mat-card-actions>
</mat-card>
