import { Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { catchError, Observable, of, tap } from "rxjs";

import { Customer, GrantComponent } from "../models";
import { ApiConfig } from "./api-config";
import { CustomersService } from "./customers.service";

@Injectable({
  providedIn: 'root'
})
export class GrantComponentsService {

  constructor(private http: HttpClient,
              private customerService: CustomersService) { }

  /**
   * Gets all Grant Components available.
   */
  getComponents(): Observable<GrantComponent[]> {
    const url = ApiConfig.getApiUrl('/components.json');
    return this.http.get<GrantComponent[]>(url)
      .pipe(
        tap(components => console.log('GrantComponentsService.getComponents() =>', components)),
        catchError(this.handleError<any>('getComponents', []))
      );
  }

  saveComponents(componentIds: number[], customerId: number): Observable<Customer> {
    const url = ApiConfig.getApiUrl(`/customers/${customerId}.json`);
    return this.http.patch<Customer>(url, {customer: { component_ids: componentIds }})
      .pipe(
        tap(data => {
          this.customerService.customerChange.next();
          console.log(`ComponentService.update(${customerId}) =>`, data)
        })
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
