import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { overviewXlsxColumns, OverviewXlsxColumn, OverviewXlsxSettings, defaultOverviewXlsxSettings } from '../../../../core/models';

@Component({
  selector: 'app-overview-xlsx-settings',
  templateUrl: './overview-xlsx-settings.component.html',
  styleUrls: ['./overview-xlsx-settings.component.scss']
})
export class OverviewXlsxSettingsComponent implements OnInit, OnChanges {

  @Input() settings: OverviewXlsxSettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  availableColumns: OverviewXlsxColumn[] = [...overviewXlsxColumns];

  form: FormGroup = new FormGroup({
    columns: new FormArray([])
  });
  value: OverviewXlsxSettings = {
    ...defaultOverviewXlsxSettings
  };

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...this.settings };
      }
    }
  }

  doEdit(): void {
    this.columns.clear();
    this.value.columns.forEach(c => this.doAddColumn(c));
    this.form.patchValue(this.value);
    this.refreshAvailable();
    this.form.enable();
  }

  doRemoveColumn(index: number): void {
    this.columns.removeAt(index);
    this.refreshAvailable();
  }

  doAddColumn(column: OverviewXlsxColumn): void {
    this.columns.push(new FormControl(column, [Validators.required]));
    this.refreshAvailable();
  }

  refreshAvailable(): void {
    const selected = this.columns.value;
    this.availableColumns = overviewXlsxColumns.filter(c => !selected.includes(c));
  }    

  onDrop(event: CdkDragDrop<OverviewXlsxColumn[]>) {
    const dir = event.currentIndex > event.previousIndex ? 1 : -1;
    const item = this.columns.at(event.previousIndex);
    for (let i = event.previousIndex; i * dir < event.currentIndex * dir; i = i + dir) {
      const current = this.columns.at(i + dir);
      this.columns.setControl(i, current);
    }
    this.columns.setControl(event.currentIndex, item);
  }

  doCancel(): void {
    this.value = { ...defaultOverviewXlsxSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

  get columns(): FormArray {
    return this.form.get('columns') as FormArray;
  }

}
