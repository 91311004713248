<h2 mat-dialog-title>
  Opdelt bevilling
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <p>Hvis fonden benytter opdelte bevillinger, dvs. mulighed for at flytte dele af bevilling til fondens indkøb,
      reserve og skjult reserve, så kan det være vigtigt at foretage flyttet inden bevillingsbrevet sendes.</p>
  <form [formGroup]="form" fxLayout="column">
    <div class="mb-2">
      <mat-checkbox formControlName="lock_before_notify">Fordelingen skal bekræftes <em>inden</em> bevillingsbrevet kan sendes.</mat-checkbox>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()">Gem</button>
</mat-dialog-actions>
