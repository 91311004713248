<div fxFlex fxLayout="column" class="mt-3">
  <span>{{sortingNumber + 1}}. prio</span>

  <div fxFlex fxLayout="row" fxLayoutGap="3rem">
    <mat-form-field fxFlex="25">
      <mat-label>Sorteringsnøgle</mat-label>
      <mat-select
        [(value)]="sortingKey">
        <div *ngFor="let field of availableSortingFields">
          <mat-option
            [value]="field.key"
            (click)="chooseSortingField(field.key)"
            [ngStyle]="{display: isAvailable(field) ? 'flex' : 'none' }"
          >{{'fields.' + field.key | translate}}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="25">
      <mat-label>Sorteringsvej</mat-label>
      <mat-select [(value)]="sortingMethod" [disabled]="sortingKey === ''">
        <mat-option value="+" (click)="sort()">
          +
        </mat-option>
        <mat-option value="-" (click)="sort()">
          -
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button mat-raised-button color="warn" *ngIf="this.sortingKey" (click)="removeSortingField()">Fjern</button>
    </div>
  </div>
</div>
