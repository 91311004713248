import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[libRandomBackground]'
})
export class RandomBackgroundDirective {

  images = [
    'Skov.jpg',
    'baad_drone.jpg',
    'badebro.jpg',
    'sejl.jpg',
    'skumring.jpg',
    'solnedgang.jpg',
    'spejling.jpg'
  ];

  constructor(element: ElementRef) {
    const idx = Math.floor(Math.random() * this.images.length);
    const url = 'url(assets/images/backgrounds/' + this.images[idx] + ')';
    element.nativeElement.style.backgroundImage = url;
    element.nativeElement.style.backgroundsize = 'cover';
  }
}
