<div fxLayout="row wrap" [formGroup]="fontSelection">
  <mat-form-field fxFlex="45" class="customer-list--search-field mr-3" color="accent" appearance="outline">
    <mat-label>Header Font</mat-label>
    <input matInput type="text" formControlName="searchHeader" [matAutocomplete]="autoHeader" [style.font-family]="currentHeaderFont?.family">
    <button matSuffix mat-button><mat-icon>search</mat-icon></button>
  </mat-form-field>
  <mat-autocomplete #autoHeader="matAutocomplete">
    <mat-option (onSelectionChange)="pickFont('header-font', option)" *ngFor="let option of (fontsHeader$ | async)"
                [value]="option.family">{{option.family}}</mat-option>
  </mat-autocomplete>

  <mat-form-field fxFlex="45" class="customer-list--search-field mr-3" color="accent" appearance="outline">
    <mat-label>Body Font</mat-label>
    <input matInput type="text" formControlName="searchBody" [matAutocomplete]="autoBody" [style.font-family]="currentBodyFont?.family">
    <button matSuffix mat-button><mat-icon>search</mat-icon></button>
  </mat-form-field>
  <mat-autocomplete #autoBody="matAutocomplete">
    <mat-option (onSelectionChange)="pickFont('body-font', option)" *ngFor="let option of (fontsBody$ | async)"
                [value]="option.family">{{option.family}}</mat-option>
  </mat-autocomplete>

  <mat-form-field fxFlex="45" class="customer-list--select-field mr-3" color="accent" appearance="outline">
    <mat-label>Header weight</mat-label>
    <mat-select formControlName="currentHeaderWeight">
      <mat-option
        *ngFor="let weight of weightValues"
        [value]="weight"
      >{{weight}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFlex="45" class="customer-list--select-field mr-3" color="accent" appearance="outline">
    <mat-label>Body weight</mat-label>
    <mat-select formControlName="currentBodyWeight">
      <mat-option
        *ngFor="let weight of weightValues"
        [value]="weight"
      >{{weight}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
