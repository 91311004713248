import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {DisplayField, ProjectDisplay} from "../models/display";
import {HttpClient} from "@angular/common/http";
import {ApiConfig} from "./api-config";

@Injectable({
  providedIn: 'root'
})
export class DisplaysService {

  constructor(private http: HttpClient) { }

  getProjectsDisplays(subdomain: string): Observable<ProjectDisplay[]> {
    const url = ApiConfig.getApiUrl( '/project_displays.json', subdomain);
    return this.http.get<ProjectDisplay[]>(url);
  }

  getFields(subdomain: string): Observable<DisplayField[]> {
    const url = ApiConfig.getApiUrl( 'customers/display_fields.json', subdomain);
    return this.http.get<DisplayField[]>(url);
  }

  createProjectDisplay(display: ProjectDisplay, subdomain: string): Observable<ProjectDisplay> {
    const url = ApiConfig.getApiUrl('/project_displays.json', subdomain);
    const data = { project_display: display };
    return this.http.post<ProjectDisplay>(url, data);
  }
  updateProjectDisplay(display: ProjectDisplay, subdomain: string): Observable<ProjectDisplay> {
    const url = ApiConfig.getApiUrl(`/project_displays/${display.id}.json`, subdomain);
    return this.http.patch<ProjectDisplay>(url, display);
  }
  deleteProjectDisplay(displayId: number, subdomain: string) {
    const url = ApiConfig.getApiUrl(`/project_displays/${displayId}.json`, subdomain);
    return this.http.delete(url);
  }
}
