import { Settings } from "./settings";
import { GrantComponent } from "./grant-component";
import { WealthManager } from "./wealth-manager";

export type CustomerStatus = 'enabled' | 'trial' | 'disabled';

export type CustomerShallow = {
  id: number,
  name: string,
  status: CustomerStatus,
  subdomain: string
}

export interface Customer {
  id: number;
  name: string;
  subdomain: string;
  status: CustomerStatus;
  reg_no: string;
  care_of: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  contact: string;
  phone: string;
  email: string;
  settings: Settings;
  logo?: string;
  data_policy?: string;
  currency: string;
  created_at: string;
  components: GrantComponent[];
  wealth_manager: WealthManager;
  wealth_manager_attributes: { // Used for updating customer
    bank: string;
    custody_no: string;
    nem_konto: boolean;
    accounting: boolean;
    capital: number;
    settings: {
      payment_exported_notify: string[];
    }
  };
  stylesheet: any;
  tag: string; // used in search
}
// {"settings":{"siblings":["grant-a"],"letter_types":[{"key":"ak_af","title":"AF Almene formål"},{"key":"fb_af","title":"AF Formansbevilling"},{"key":"fs_af","title":"AF Folkeskolen"},{"key":"ak_sf","title":"SF Almene formål"},{"key":"fb_sf","title":"SF Formansbevilling"}],"languages":["da"],"profile":{"enabled":false,"force":false,"types":["company","personal"],"logins":["mitid","dbu","email","nemid","code_file"],"confirmation":true,"signups":["nemid","mitid"]},"accounting":{"account_no":"1234567890","provision_account_no":"1234567890","payment_credit_account":"1234567890","payment_debit_account":"0987654321"},"imap":{},"smtp":{},"foreign-currency":{},"recommendations":{"show_private_name":true,"show_private_address":false},"module.notifications.receipt.enabled":true,"split-grants":{}},"has_sso":false,"sso_uuid":null,"currency":"DKK","components":[{"id":16,"name":"Udenlandsk valuta","slug":"foreign-currency"},{"id":33,"name":"Profiler","slug":"profile"},{"id":42,"name":"Opdelt bevilling","slug":"split-grants"}],"reg_no":"12345678","contact":"Morten Bak","phone":"70345038","email":"thorunn@grantone.dk","subdomain":"grant-a","tag":"SET TAG","color":"#969691","language":"da","wealth_manager":{"id":1,"bank":"generic","custody_no":"938512345","nem_konto":true,"accounting":true,"capital":"more_than_one_million","exports_enabled":false},"created_at":"2022-06-24T09:23:49+02:00","siblings":[{"name":"Købmand Herman Sallings Fond","subdomain":"grant-a"}]}

