import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { defaultSupportSettings, SupportSettings } from '../../../../core/models';

@Component({
  selector: 'app-support-settings',
  templateUrl: './support-settings.component.html',
  styleUrls: ['./support-settings.component.scss']
})
export class SupportSettingsComponent implements OnInit, OnChanges {

  @Input() settings: SupportSettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  value: SupportSettings = {
    ...defaultSupportSettings
  };
  form: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email, Validators.maxLength(100)])
  });
  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...defaultSupportSettings, ...this.settings };
      }
      this.form.patchValue(this.value);
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultSupportSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

}
