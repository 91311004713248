import { NgModule } from '@angular/core';

import { AccountNumberPipe } from './pipes';
import {FillPipe} from "./pipes/fill.pipe";
import {InjectorModule} from '../injector.module';

@NgModule({
  declarations: [
    AccountNumberPipe,
    FillPipe
  ],
  imports: [
    InjectorModule
  ],
  exports: [
    AccountNumberPipe,
    FillPipe
  ],
})
export class CoreModule { }
