import {Injectable} from '@angular/core';
import {Portal, PortalUser} from '../models/portal';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {AdminRecordService} from '../helpers/records/admin-record-service';

@Injectable({
  providedIn: 'root'
})
export class PortalsService extends AdminRecordService<Portal> {

  private portalsChange: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {
    super('portals', http);
  }

  portalsChanged(): void {
    this.portalsChange.next();
  }

  onPortalsChange(): Observable<void> {
    return this.portalsChange.asObservable();
  }

  linkUser(user: PortalUser, id: Portal['id']): Observable<Portal> {
    const url = this.recordUrl('/:?/link_user.json', id);
    return this.http.patch<Portal>(url, { user: user });
  }

  unlinkUser(user: PortalUser, id: Portal['id']): Observable<Portal> {
    const url = this.recordUrl('/:?/unlink_user.json', id);
    return this.http.patch<Portal>(url, { user: user });
  }

}
