import {MonoTypeOperatorFunction, tap} from 'rxjs';

export function tapLog<T>(logType: 'log' | 'warn' | 'error' = 'log'): MonoTypeOperatorFunction<T> {
  return tap<T>(value => {
    switch (logType) {
      case 'log': console.log(value); break;
      case 'warn': console.warn(value); break;
      case 'error': console.error(value); break;
    }
  })
}
