import { Component, OnDestroy } from '@angular/core';

import { AuthenticationService, KeepAliveService } from './core/services';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnDestroy {

  constructor(
    private keepAlive: KeepAliveService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService) {
    this.translate.setDefaultLang('da');
    this.translate.use('da');
    // configure keep alive - refresh every 5 minutes
    this.keepAlive.interval(30);
    this.keepAlive.setCallback(() => {
      this.authenticationService.refreshToken();
    });
    this.keepAlive.start();
  }

  ngOnDestroy() {
    this.keepAlive.stop();
  }
}
