import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {catchError, Observable, of, tap} from "rxjs";
import {User} from "../models/user";
import {ApiConfig} from "./api-config";
import {IpData} from "../models/ip-data";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) { }

  getUsers(subdomain: string): Observable<User[]> {
    const url = ApiConfig.getApiUrl( '/users.json', subdomain);
    console.log('Go get users!');
    return this.http.get<User[]>(url)
      .pipe(
        tap(users => console.log('UserService.getUsers() =>', users)),
        catchError(this.handleError<any>('getUsers', []))
      );
  }

  getUser(subdomain: string, id: string): Observable<User> {
    const url = ApiConfig.getApiUrl( `/users/${id}.json`, subdomain);
    return this.http.get<User>(url)
      .pipe(
        tap(user => console.log(`UserService.getUser(${id}) =>`, user)),
        catchError(this.handleError<any>('getUser', null))
      );
  }

  printUsers(subdomain: string) {
    const url = ApiConfig.getApiUrl(subdomain, '/users.pdf') +
      '?token=' + this.authenticationService.getToken();

    window.open(url, '_blank');
  }

  getIpData(ip: string): Observable<IpData> {
    return this.http.get<IpData>(`https://ipinfo.io/${ip}`)
      .pipe(
        tap(data => data),
        catchError(this.handleError<IpData>('getIpData'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
