import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { defaultSecuritySettings, SecuritySettings } from '../../../../core/models';

@Component({
  selector: 'app-security-settings',
  templateUrl: './security-settings.component.html',
  styleUrls: ['./security-settings.component.scss']
})
export class SecuritySettingsComponent implements OnInit, OnChanges {

  @Input() settings: SecuritySettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  value: SecuritySettings = {
    mail: [],
    ...defaultSecuritySettings
  };
  form: FormGroup = new FormGroup({
    mail: new FormArray([]),
    skip_ip_check: new FormControl<boolean>(this.value.skip_ip_check)
  });

  ngOnInit(): void {
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      let disabled = this.form.disabled;
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...defaultSecuritySettings, ...this.settings };
      }
      this.mails.clear();
      this.value.mail?.forEach(m => this.doAddMail());
      this.form.patchValue(this.value);
      if (disabled) {
        this.form.disable();
      }
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultSecuritySettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }

  doAddMail(): void {
    this.mails.push(new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]));
  }

  doRemoveMail(index: number): void {
    this.mails.removeAt(index);
  }

  get mails(): FormArray {
    return this.form.get('mail') as FormArray;
  }

  mailAt(index: number): FormControl {
    return this.mails.at(index) as FormControl;
  }


}
