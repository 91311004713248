import {BehaviorSubject, filter, map, Observable, shareReplay, Subject, switchMap, take, tap} from "rxjs";
import {RecordStorage} from "./record-storage";

export class RecordCache<Model, Id extends (number | string)[] = [number]> {

  private record: { [K: string]: BehaviorSubject<string> } = {};
  private change: Subject<void> = new Subject<void>();
  private storage: RecordStorage<Model, Id> | null = null;
  private filterNull: boolean;

  constructor(options: { storage?: RecordStorage<Model, Id> | null, filterNull?: boolean } = {}){
    this.filterNull = !!options?.filterNull;
    this.storage = !!options?.storage && options.storage || null;
  }

  public get<T extends Model = Model>(...id: Id): Observable<T> {
    return this.observable<T>(...id);
  }

  public set<T extends Model = Model>(obs: Observable<T>, ...id: Id): Observable<T> {
    this.subject(...id); // inits subject if missing
    return obs.pipe(
      take(1),
      tap(record => this.valueSet(record, ...id)),
      shareReplay(1),
      switchMap(() => this.observable<T>(...id))
    )
  }

  public changes(): Observable<void> {
    return this.change.asObservable();
  }

  public value<T extends Model = Model>(...id: Id): T {
    return JSON.parse(this.subject(...id).value);
  }

  public valueSet(record: Model, ...id: Id): void {
    this.storage?.set(record, ...id);
    this.change.next();
    this.subject(...id).next(JSON.stringify(record));
  }

  public delete(...id: Id): void {
    this.storage?.delete(...id);
    this.change.next()
    this.subject(...id).complete();
    delete this.record[this.key(...id)];
  }

  public exist(...id: Id): boolean {
    return this.key(...id) in this.record;
  }

  private key(...id: Id): string {
    return ['#', ...id].join();
  }

  private subject(...id: Id): BehaviorSubject<string> {
    if(!this.exist(...id)) {
      const value = JSON.stringify(this.storage?.get(...id) || null);
      this.record[this.key(...id)] = new BehaviorSubject<string>(value);
    }
    return this.record[this.key(...id)];
  }

  private observable<T extends Model = Model>(...id: Id): Observable<T> {
    let obs = this.subject(...id).asObservable().pipe(
      map(record => JSON.parse(record))
    );
    if (this.filterNull) {
      obs = obs.pipe(filter(record => record !== null));
    }
    return obs;
  }
}
