import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from "../services/authentication.service";

// Injects the administrator identification token into the Authorization
// header
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const target = new URL(request.url);
    if (this.getDomainName(target.hostname) === this.getDomainName(location.hostname)) {
      let token = this.authenticationService.getToken();
      if (request.url.includes('project_displays')) { // FIXME: Bad work around
        token = this.authenticationService.getUserToken();
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }

  private getDomainName(hostname: string): string {
    return hostname.substring(hostname.indexOf('.') + 1);
  }
}
