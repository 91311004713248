import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { Customer } from '../models';
import { CustomersService } from "../services";

@Injectable({
  providedIn: 'root'
})
export class CustomerResolver implements Resolve<Customer> {

  constructor(private customersService: CustomersService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Customer> {
    const id = route.params['id'];
    return this.customersService.getCustomer(id);
  }
}
