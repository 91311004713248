import {animate, query, stagger, style, transition, trigger} from "@angular/animations";

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('20ms', animate('100ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('100ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
])

export const listAnimationParams = (duration: string) => trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('50ms', animate(duration + ' ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
  ])
])
