import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface YesNoDialogData {
  title?: string;
  content?: string
  yes?: string;
  no?: string;
}

@Component({
  selector: 'yes-no-dialog.component',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: YesNoDialogData,
    public dialogRef: MatDialogRef<YesNoDialogComponent, boolean>
  ) {
  }
}
