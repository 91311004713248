import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Customer} from "../../../core/models";
import {DisplaysService} from "../../../core/services/displays.service";
import {DisplayField, ProjectDisplay} from "../../../core/models/display";
import {listAnimation} from "../../../core/animations/list-animation";
import {MatDialog} from "@angular/material/dialog";
import {EditDisplayDialogComponent} from "./edit-display-dialog/edit-display-dialog.component";
import {YesNoDialogService} from "../../shared-components/yes-no-dialog/yes-no-dialog.service";

@Component({
  selector: 'app-customer-displays',
  templateUrl: './customer-displays.component.html',
  styleUrls: ['./customer-displays.component.scss'],
  animations: [listAnimation]
})
export class CustomerDisplaysComponent implements OnInit {

  customer: Customer;
  displays: ProjectDisplay[];
  fields: DisplayField[] = [];

  constructor(private route: ActivatedRoute,
              private dialog: MatDialog,
              private yesNoDialog: YesNoDialogService,
              private displaysService: DisplaysService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.customer = data['customer'];
    })
    this.getDisplays();
    this.getFields();
  }

  getDisplays(): void {
    this.displaysService.getProjectsDisplays(this.customer.subdomain)
      .subscribe(value => {
        this.displays = value.sort((a,b) => b.name.localeCompare(a.name));
      })
  }

  getFields(): void {
    this.displaysService.getFields(this.customer.subdomain)
      .subscribe(fields => {
        this.fields = fields;
      });
  }

  openCreateDisplayDialog(): void {
    // open dialog
    this.dialog.open(EditDisplayDialogComponent, {
      data: {
        display: null,
        fields: [...this.fields],
        createNew: true
      },
      panelClass: 'dialog',
      minWidth: '1200px'
    }).afterClosed()
      .subscribe((result) => {
        if (result) {
          console.log('Save -> ', result);
          this.displaysService.createProjectDisplay(result, this.customer.subdomain)
            .subscribe(value => {
              console.log('Created new -> ', value);
              this.displays.push(value);
            })
        }
      });
  }

  openEditDisplayDialog(display: ProjectDisplay): void {
    // open dialog
    this.dialog.open(EditDisplayDialogComponent, {
      data: {
        display: display,
        fields: [...this.fields],
        createNew: false
      },
      panelClass: 'dialog',
      minWidth: '1200px'
    }).afterClosed()
      .subscribe((result) => {
        if (result) {
          console.log('Save -> ', result);
          this.displaysService.updateProjectDisplay(result, this.customer.subdomain)
            .subscribe(value => {
              console.log('update -> ', value);
              display = value;
            })

        }
      });
  }

  deleteDisplay(display: ProjectDisplay): void {
    this.yesNoDialog.open({
      title: 'Slet søjlevisning',
      content: `Skal søjlevisning ${display.name} slettes?`,
      yes: 'Ja',
      no: 'Annuler'
    }).afterClosed().subscribe(result => {
      if (result) {
        this.displaysService.deleteProjectDisplay(display.id, this.customer.subdomain).subscribe(result => {
          //delete local
          this.displays.forEach((element, index)=>{
            if(element.id === display.id) this.displays.splice(index,1);
          });
        });
      }
    });
  }

}
