import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../core/services/authentication.service';
import { ApiConfig } from '../../core/services/api-config';
import {JwtHelperService} from "@auth0/angular-jwt";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  private jwtHelper: JwtHelperService;

  hasError: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.jwtHelper =  new JwtHelperService();
  }

  ngOnInit() {
    this.tokenCallback();
  }

  goToMitId() {
    window.location.href = ApiConfig.getApiUrl(`/authenticate/administrator/new?provider=mitid&return_to=admin`);
  }

  tokenCallback() {
    this.hasError = false;
    this.route.queryParams
      .subscribe(params => {
        if (params['token']) {
          const tokenContent: any = this.jwtHelper.decodeToken(params['token']);
          if (!tokenContent.status) {
            this.authenticationService.setToken(params['token']);
            this.router.navigate(['/']).then();
          } else {
            this.hasError = true;
          }
        }
      });
  }

}
