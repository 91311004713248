<h2 mat-dialog-title>
  Portal indstillinger
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="column" formGroupName="cleanup">
      <p>Angiv værdier for hvornår læste/ulæste notifikationer slettes i portalen</p>
      <div fxLayout="row" fxLayoutGap="1em">
        <mat-form-field>
          <mat-label>Læste notifikationer</mat-label>
          <input
            type="number"
            matInput
            formControlName="noticed"
          >
          <mat-hint>Angiv værdi i dage.</mat-hint>
          <mat-error *ngIf="cleanup.get('notified')?.hasError('required')"><strong>Skal</strong> udfyldes.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ulæste notifikationer</mat-label>
          <input
            type="number"
            matInput
            formControlName="unnoticed"
          >
          <mat-hint>Angiv værdi i dage.</mat-hint>
          <mat-error *ngIf="cleanup.get('unnoticed')?.hasError('required')"><strong>Skal</strong> udfyldes.</mat-error>
        </mat-form-field>
      </div>

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
</mat-dialog-actions>
