<mat-card class="mb-3">
  <mat-card-title>Styling</mat-card-title>
  <mat-card-content fxLayout="column">
    <br>
    <div fxFlex fxLayout="row wrap" fxLayoutGap="2rem grid">
      <app-color-selection fxFlex="100"
                           [colorSelection]="colorSelection"
                           [primary]="primary"
                           [accent]="accent"
                           [warn]="warn"
                           (onColorChange)="setPalette($event)"
      ></app-color-selection>

      <app-font-selection [fontSelection]="fontSelection"
                          [currentBodyFont]="currentBodyFont"
                          [currentHeaderFont]="currentHeaderFont"
                          fxFlex="100"
                          (onPickFont)="pickFont($event.type, $event.f)"></app-font-selection>
    </div>

  </mat-card-content>
  <button *ngIf="!(loading$ | async)" mat-stroked-button color="warn" (click)="setDefault()"
          matTooltip="Sætter stylingen til Grant default styling" matTooltipPosition="above"
          class="mr-3">Reset styling</button>
  <button *ngIf="!(loading$ | async)" mat-stroked-button color="accent" (click)="updateTheme()">Tilføj styling</button>
  <mat-progress-spinner
    *ngIf="loading$ | async"
    color="accent"
    [diameter]="24"
    mode="indeterminate"
  ></mat-progress-spinner>

</mat-card>
