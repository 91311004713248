import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
  Customer,
  defaultVotesSettings,
  VotesSettings
} from "../../../../core/models";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-votes-settings',
  templateUrl: './votes-settings.component.html',
  styleUrls: ['./votes-settings.component.scss']
})
export class VotesSettingsComponent implements OnChanges {
  @Input() settings: VotesSettings | undefined;
  @Output() saveSettings: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  customer: Customer;
  loading: boolean = false;
  value: VotesSettings = {
    ...defaultVotesSettings
  };
  form: FormGroup = new FormGroup({
    show_details: new FormControl<boolean>(false),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.settings = changes['settings'].currentValue;
      if (this.settings) {
        this.value = { ...defaultVotesSettings, ...this.settings };
      }
      this.form.patchValue(this.value);
    }
  }

  constructor() {
    this.form.disable();
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.value = { ...defaultVotesSettings, ...this.settings };
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.saveSettings.emit(this.form);
    this.form.disable();
  }
}
