import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ApiConfig } from './api-config';

export const TOKEN_KEY = 'grant.admin.token';
export const USER_TOKEN_KEY = 'grant.auth.token';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly helper: JwtHelperService;

  constructor(private cookie: CookieService,
              private http: HttpClient) {
    this.helper =  new JwtHelperService();
  }

  public refreshToken(): void {
    // no point in trying, unless the user is signed in
    if(!this.isSignedIn()) {
      return;
    }
    // so we give it a go '/authenticate/refresh.json'
    const url = ApiConfig.getApiUrl('/authenticate/refresh.json');
    this.http.patch<any>(url, {})
      .subscribe((response) => {
        if(response.token) {
          this.setToken(response.token);
        }
      });
  }


  public getToken(): string {
    return this.cookie.get(TOKEN_KEY);
  }
  public getUserToken(): string {
    return this.cookie.get(USER_TOKEN_KEY);
  }

  public setToken(token: string): void {
    this.cookie.set(TOKEN_KEY, token, undefined, '/', this.getSharedDomain()
    );
  }

  public isSignedIn(): boolean {
    const token = this.getToken();
    return !this.helper.isTokenExpired(token);
  }

  public signOut(): void {
    this.cookie.delete(TOKEN_KEY, '/', this.getSharedDomain());
  }

  getSharedDomain(): string {
    const host = location.hostname;
    return host.substr(host.indexOf('.') + 1);
  }

}
