import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { catchError, Observable, of, tap } from "rxjs";

import { Country } from "../models";
import { ApiConfig } from "./api-config";
import {Currency} from "../models/currency";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  //Fixme
  getCountries(): Observable<Country[]> {
    const url = ApiConfig.getApiUrl('/countries.json');
    return this.http.get<Country[]>(url)
      .pipe(
        tap(countries => console.log('DataService.getCountries() =>', countries)),
        catchError(this.handleError<any>('getCountries', []))
      );
  }

  //Fixme
  getCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>('/currencies.json');
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
