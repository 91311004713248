import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CustomersService } from '../../../../core/services';
import { Customer, defaultTransferSettings, SiblingsSettings } from '../../../../core/models';

@Component({
  selector: 'app-siblings-settings',
  templateUrl: './siblings-settings.component.html',
  styleUrls: ['./siblings-settings.component.scss']
})
export class SiblingsSettingsComponent {

  customer: Customer;
  loading: boolean = false;
  customers: Customer[] = [];
  available: string[] = [];
  value: SiblingsSettings[] = [];

  form: FormGroup = new FormGroup({
    siblings: new FormArray([])
  })
  transfer: FormGroup = new FormGroup({
    case_documents: new FormControl(false),
    messages: new FormControl(false),
    recommendation: new FormControl(false)
  })

  constructor(private route: ActivatedRoute,
              private customersService: CustomersService) {
    this.loading = true;
    this.route.data.subscribe(data => {
      this.loading = false;
      this.customer = data['customer'];
      this.value = this.customer.settings.siblings || [];
      this.checkCurrentSiblings();

      this.initFormGroup();

      this.customersService.getCustomers().subscribe(customers => {
        this.customers = customers;
        this.available = this.customers.map(c => c.subdomain)
          .filter(s => !this.hasSibling(s) && s !== this.customer.subdomain);
      });
    });
  }

  initFormGroup(): void {
    this.value?.forEach(_ => this.addSibling());
    this.siblings.setValue(this.value);
    this.form.disable();
    const transferValue = {
      ...defaultTransferSettings,
      ...this.customer.settings.transfer
    };
    this.transfer.patchValue(transferValue);
  }

  hasSibling(sibling: string): boolean {
    if (this.value?.length === 0) {
      return false
    }
    return this.value?.filter(s => s.subdomain === sibling)?.length > 0
  }

  checkCurrentSiblings(): void {
    const includesSelf: boolean = this.value.findIndex(v => v.subdomain === this.customer.subdomain) !== -1;
    if (!includesSelf) {
      this.value.push({
        subdomain: this.customer.subdomain,
        share_history: true,
        share_profiles: true
      })
    }
  }

  doEdit(): void {
    this.form.enable();
  }

  doCancel(): void {
    this.form.reset(this.value);
    this.form.disable();
  }

  doSave(): void {
    this.loading = true;
    this.customer.settings.siblings = this.siblings.value;
    this.customer.settings.transfer = this.transfer.value;
    this.customersService.update(this.customer)
      .subscribe(customer => {
        this.loading = false;
        this.customer = customer;
        this.value = this.siblings.value;
        this.form.disable();
      })
  }

  getCustomer(subdomain: string): string {
    const customer = this.customers.find(customer => customer.subdomain === subdomain);

    return customer ? customer.name : subdomain;
  }

  removeSibling(index: number): void {
    this.siblings.removeAt(index);
  }

  addSibling(): void {
    this.siblings.push(new FormGroup({
      subdomain: new FormControl('', [Validators.required]),
      share_history: new FormControl(false),
      share_profiles: new FormControl(false)
    }));
  }

  siblingAt(index: number): FormGroup {
    return this.siblings.at(index) as FormGroup;
  }

  get siblings(): FormArray {
    return this.form.get('siblings') as FormArray;
  }

}
