import {Injectable} from '@angular/core';
import {RecordService} from '../helpers/records/record-service';
import {IntegrationUser} from '../models/integration-user';

@Injectable({
  providedIn: 'root'
})
export class IntegrationUsersService extends RecordService<IntegrationUser> {

  constructor() {
    super('integration/users');
  }

}
