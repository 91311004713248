<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Import indstillinger</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Vis muligheden for at importere statusnoter?</dt>
      <dd>{{value.show_import_project_statuses ? 'Ja' : 'Nej'}}</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45" fxLayout="column">
        <mat-checkbox formControlName="show_import_project_statuses">Vis muligheden for at importere statusnoter?</mat-checkbox>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="accent" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="primary" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
