import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable } from 'rxjs';

import { ApiConfig } from './api-config';
import { Profile } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  constructor(private http: HttpClient) {}

  search(subdomain: string, query: string): Observable<Profile[]> {
    const params = new HttpParams()
      .append('query', query);
    const url = ApiConfig.getApiUrl('/profiles.json', subdomain) + `?${params.toString()}`;
    return this.http.get<Profile[]>(url);
  };

  unlock(subdomain: string, profile: Profile): Observable<Profile> {
    const url = ApiConfig.getApiUrl('/profile/unlock/', subdomain) + profile.unlock_token;
    return this.http.get<Profile>(url);
  }

  resetPassword(subdomain: string, profile: Profile, expireHours: number): Observable<Profile> {
    const url = ApiConfig.getApiUrl('/profile/forgot-password', subdomain);
    return this.http.post<Profile>(url, {email: profile.uid, reset_token_expire_hours: expireHours});
  }

  signIn(subdomain: string, profile: Profile): Observable<string> {
    const url = ApiConfig.getApiUrl(`/profiles/${profile.id}/token.json`, subdomain);
    return this.http.patch<{token: string}>(url, {})
      .pipe(map(data => data.token));
  }

}
