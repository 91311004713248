import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApprovalConfirmationSettings, Settings } from '../../../../../core/models';

@Component({
  selector: 'app-approval-confirmation-settings-dialog',
  templateUrl: './approval-confirmation-settings-dialog.component.html',
  styleUrls: ['./approval-confirmation-settings-dialog.component.scss']
})
export class ApprovalConfirmationSettingsDialogComponent implements OnInit {

  settings: ApprovalConfirmationSettings;

  form: FormGroup = new FormGroup({
    list_projects: new FormControl<boolean>(false),
    automatic: new FormGroup({
      enabled: new FormControl<boolean>(false),
      grace: new FormControl<number>(30, [Validators.required,
        Validators.min(1), Validators.max(100)])
    })
  });

  constructor(public dialogRef: MatDialogRef<ApprovalConfirmationSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data.approval_confirmation || {
      list_projects: false,
      automatic: { enabled: false, grace: 30 }
    };
    this.form.setValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  get automatic(): FormGroup {
    return this.form.get('automatic') as FormGroup;
  }

  get enabled(): FormControl {
    return this.automatic.get('enabled') as FormControl;
  }

  get grace(): FormControl {
    return this.automatic.get('grace') as FormControl;
  }

}
