<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Login</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Login-muligheder</dt>
      <dd *ngFor="let mode of value.modes">
        <ng-container [ngSwitch]="mode">
          <span *ngSwitchCase="'mitid'">MitID</span>
          <span *ngSwitchCase="'nemid'">NemID</span>
          <span *ngSwitchCase="'codefile'">NemID-nøglefil</span>
          <span *ngSwitchCase="'tfa'">Brugernavn/Adgangskode + to-faktor</span>
          <span *ngSwitchCase="'sso'">Single Sign On</span>
        </ng-container>
      </dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <label>Login-metoder</label>
    <mat-form-field class="mb-3" fxFill>
      <mat-select formControlName="modes" multiple>
        <mat-option value="mitid">MitID</mat-option>
        <mat-option value="nemid">NemID</mat-option>
        <mat-option value="codefile">NemID-nøglefil</mat-option>
        <mat-option value="tfa">Brugernavn/Adgangskode + to-faktor</mat-option>
        <mat-option value="sso">Single Sign In</mat-option>
      </mat-select>
      <mat-hint>Vælg de metoder, som svarer til fondens behov.</mat-hint>
      <mat-error *ngIf="form.controls['modes'].hasError('required')">Obligatorisk.</mat-error>
    </mat-form-field>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="accent" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="primary" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
