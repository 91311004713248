import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CommentsSettings, Settings } from '../../../../../core/models';

@Component({
  selector: 'app-comments-settings-dialog',
  templateUrl: './comments-settings-dialog.component.html',
  styleUrls: ['./comments-settings-dialog.component.scss']
})
export class CommentsSettingsDialogComponent implements OnInit {

  settings: CommentsSettings;

  form: FormGroup = new FormGroup({
    shared: new FormControl<boolean>(false)
  });

  constructor(public dialogRef: MatDialogRef<CommentsSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data.comments || { shared: false };
    this.form.setValue(this.settings);
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

}
