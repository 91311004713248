import { Component, Inject, OnInit } from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  defaultRecommendationsSettings, RecommendationCompactHistory,
  RecommendationsSettings,
  RecommendationStyle,
  Settings
} from '../../../../../core/models';
import {FormGroupRecord} from '../../../../../core/types/form.type';

@Component({
  selector: 'app-recommendations-settings-dialog',
  templateUrl: './recommendations-settings-dialog.component.html',
  styleUrls: ['./recommendations-settings-dialog.component.scss']
})
export class RecommendationsSettingsDialogComponent implements OnInit {

  settings: RecommendationsSettings;

  form: FormGroupRecord<RecommendationsSettings> = new FormGroup({
    warning_multiple_applications: new FormControl(false),
    hide_priority: new FormControl(true),
    show_private_name: new FormControl(false),
    show_private_address: new FormControl(false),
    style: new FormControl<RecommendationStyle|null>('ap'),
    compact_history: new FormControl<RecommendationCompactHistory|null>('digest'),
    attachment_scale: new FormControl(0.85,
      [Validators.required, Validators.min(0.1), Validators.max(1)]),
    priorities: new FormArray<FormControl>([], Validators.required),
    exceeding_grant_warning: new FormControl(false)
  });
  subPriorityAmount: number = 7;


  constructor(public dialogRef: MatDialogRef<RecommendationsSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Settings) { }

  ngOnInit(): void {
    this.settings = this.data['recommendations'] || {
      warning_multiple_applications: false,
      hide_priority: true,
      show_private_name: false,
      show_private_address: false,
      style: 'standard',
      compact_history: 'digest',
      attachment_scale: 0.85,
      exceeding_grant_warning: false
    };
    this.initPriorities();
    this.form.setValue(this.settings)
    this.form.updateValueAndValidity();
  }

  doSave(): void {
    this.dialogRef.close(this.form);
  }

  initPriorities(): void {
    if (!this.settings.priorities) {
      this.settings.priorities = defaultRecommendationsSettings.priorities;
    }
    this.settings?.priorities.forEach(value => this.doAppendPriority(value))
    this.subPriorityAmount = this.settings.priorities.length / 2;
  }

  doAppendPriority(value: string): void {
    this.priorities.push(new FormControl(value));
  }

  setPriorityValues(): void {
    this.priorities.clear();
    const list = Array.from(Array(this.subPriorityAmount).keys());
    [1, 2].forEach(p =>  {
      list.forEach(n => this.priorities.push(new FormControl(`${p}.${n}`)))
    });
  }

  get formatAttachmentScale(): string {
    const value = this.attachmentScale.value;
    return (value * 100).toFixed(0) + '%';
  }

  get attachmentScale(): FormControl {
    return this.form.get('attachment_scale') as FormControl;
  }

  get priorities(): FormArray {
    return this.form.get('priorities') as FormArray;
  }

  get hide_priority(): FormControl {
    return this.form.get('hide_priority') as FormControl;
  }

}
