import { Component, OnInit } from '@angular/core';

import {Customer, GrantComponent, Settings} from "../../../core/models";
import {ActivatedRoute} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {CustomersService} from "../../../core/services";

@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss']
})
export class CustomerSettingsComponent implements OnInit {

  customer: Customer;
  components: GrantComponent[];

  constructor(private route: ActivatedRoute,
              private customersService: CustomersService) { }

  ngOnInit(): void {
    this.loadCustomer();
  }

  loadCustomer(): void {
    this.route.data.subscribe(data => {
      this.customer = data['customer'];
    })
  }

  save(slug: string, settingsGroup: FormGroup): void {
    const settings: Settings = Object.assign({}, this.customer.settings, {[slug]: settingsGroup.value});
    const customer: Customer = Object.assign({}, this.customer, { settings });
    this.customersService.update(customer)
      .subscribe(value => {
        this.customer = value;
      });
  }
}
