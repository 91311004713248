import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Customer, IpData, User } from '../../../../core/models';
import { UsersService } from '../../../../core/services/users.service';
import { UserInfoDialogComponent } from '../user-info-dialog/user-info-dialog.component';
import { ApiConfig } from '../../../../core/services/api-config';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent  {

  @ViewChild('ipDialog') ipDialog = {} as TemplateRef<IpData>;

  @Input() user: User;
  @Input() customer: Customer;
  ipData: IpData;

  constructor(private usersService: UsersService,
              private dialog: MatDialog) {}

  showIpInfo(): void {
    if(!this.user.last_sign_in_ip) {
      return;
    }
    this.usersService.getIpData(this.user.last_sign_in_ip)
      .subscribe(data => {
        console.log('data => ', data);
        this.dialog.open(this.ipDialog, { data : data });
      });
  }

  showPermissions(): void {
    this.dialog.open(UserInfoDialogComponent, {
      data: {
        user: this.user
      },
      width: '800px',
    });
  }

  goToBoard(): void {
    const token = 'FIXME: get token for user';
    const url = ApiConfig.getUrl(`/board/#/sign-in?token=${token}`, this.customer.subdomain);
    window.open(url, '_board');
  }


}
