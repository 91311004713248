<div class="sign-in" libRandomBackground>
  <div class="logo">
    <img src="assets/images/GrantOne.svg" alt="GrantOne"/>
  </div>
  <div class="sign-in-box">
    <div class="sign-in-header">
      <h1>Log ind</h1>
      <h2>GrantOne Administration</h2>
    </div>
    <div class="sign-in-body">
      <button
        (click)="goToMitId()"
        class="float-left pill mitid-login-button"
        color="accent"
        mat-raised-button
      >Login med MitID</button>
      <div class="mt-3">
        <h4 *ngIf="hasError" style="color: red">Brugeren findes ikke admin systemet</h4>
      </div>
    </div>

  </div>
</div>
