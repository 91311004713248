import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AllFontSelection,
  Color,
  FontSelection,
  SubPalette,
  Theme
} from "../../../core/models/theme";
import {BehaviorSubject, combineLatest, map, Observable, Subject, switchMap, takeUntil} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {BuildThemeService} from "../../../core/services/customer-theme-services/build-theme.service";
import { TinyColor } from '@ctrl/tinycolor';
import {DEFAULT_FONTS} from "./default-styles";
import {applicantDigests, Customer} from "../../../core/models";
import {ActivatedRoute} from "@angular/router";
import {FontService} from "../../../core/services/customer-theme-services/font.service";

@Component({
  selector: 'app-customer-styling',
  templateUrl: './customer-styling.component.html',
  styleUrls: ['./customer-styling.component.scss']
})
export class CustomerStylingComponent implements OnInit, OnDestroy {
  customer: Customer;
  colorSelection: FormGroup = new FormGroup({
    primaryColor: new FormControl<string>('#50504b'), // default primary color from base
    accentColor: new FormControl<string>('#969691'), // default accent color from base
    warnColor: new FormControl<string>('#ff0000'), // default accent color from base
    matCardColor: new FormControl<string>('#ffffff'), // default accent color from base
    backgroundColor: new FormControl<string>('#f5f5f3'), // default accent color from base
    textColor: new FormControl<string>('#32322d'), // default accent color from base
    headerTextColor: new FormControl<string>('#32322d') // default accent color from base
  })
  primary: SubPalette;
  accent: SubPalette;
  warn: SubPalette;

  fonts: AllFontSelection = {};
  fontSelection: FormGroup = new FormGroup({
    searchHeader: new FormControl<string>(''),
    searchBody: new FormControl<string>(''),
    currentHeaderWeight: new FormControl<number>(600),
    currentBodyWeight: new FormControl<number>(400)
  })
  currentBodyFont: FontSelection;
  currentHeaderFont: FontSelection;

  theme: Theme;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  destroy$: Subject<boolean> = new Subject<boolean>();

  highLightUpdateThemeBtn: boolean = false;

  constructor(
    private buildThemeService: BuildThemeService,
    private fontService: FontService,
    private route: ActivatedRoute
  ) {
    for (const k of Object.keys(DEFAULT_FONTS)) {
      this.fonts[k] = { ...DEFAULT_FONTS[k] };
    }
    this.route.data.subscribe(data => this.customer = data['customer']);
  }

  ngOnInit(): void {
    this.initLoadingChange();
    this.initStylingFromCustomer();
    this.initCurrentThemeChange();
    this.initTheme();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initTheme(): void {
    if (!this.customer.stylesheet) {
      combineLatest([
        this.fontService.searchAllFonts('Open sans'),
        this.fontService.searchAllFonts('Barlow'),
      ]).pipe(takeUntil(this.destroy$))
        .subscribe(([headerFont, bodyFont]) => {
        this.pickFont('header-font', headerFont[0]);
        this.pickFont('body-font', bodyFont[0]);
      });
    }
  }

  pickFont(type: 'header-font' | 'body-font', f: FontSelection) {
    this.setFontType(type, f);
    let headerFontLinkElement = document.querySelector('link[id=' + type + ']') as HTMLLinkElement;
    const fontExists = headerFontLinkElement !== null;
    if (!fontExists) {
      headerFontLinkElement = document.createElement('link');
      headerFontLinkElement.id = type;
    }

    headerFontLinkElement.onerror = x => console.log('Unable to load font:', this.getFontType(type).family);
    headerFontLinkElement.href = `https://fonts.googleapis.com/css2?family=${this.getFontType(type).family.replace(/ /g, '+')}:wght@400`;
    headerFontLinkElement.rel = 'stylesheet';

    if (!fontExists) {
      document.head.appendChild(headerFontLinkElement);
    }
  }

  updateTheme(): void {
    this.highLightUpdateThemeBtn = false;
    this.theme = {
      palette: {
        primary: this.primary,
        accent: this.accent,
        warn: this.warn,
      },
      headerFont: this.currentHeaderFont,
      bodyFont: this.currentBodyFont,
      icons: 'Filled',
      lightness: false,
      version: 11,
      backgroundColor: this.backgroundColor.value,
      textColor: this.textColor.value,
      headerTextColor: this.headerTextColor.value,
      matCardColor: this.matCardColor.value,
      headerWeight: this.currentHeaderWeight.value,
      bodyWeight: this.currentBodyWeight.value
    }
    const styling = {
      primaryColor: this.primaryColor.value,
      accentColor: this.accentColor.value,
      warnColor: this.warnColor.value,
      matCardColor: this.matCardColor.value,
      headerFontFamily: this.currentHeaderFont.family,
      bodyFontFamily: this.currentBodyFont.family,
      backgroundColor: this.backgroundColor.value,
      textColor: this.textColor.value,
      headerTextColor: this.headerTextColor.value,
      headerWeight: this.currentHeaderWeight.value,
      bodyWeight: this.currentBodyWeight.value
    }
    this.loading$.next(true);
    this.buildThemeService.updateTheme(this.theme, this.customer, styling)
      .subscribe(() => this.loading$.next(false));
  }

  getFontType(type: 'header-font' | 'body-font'): FontSelection {
    return type === 'header-font' ? this.currentHeaderFont : this.currentBodyFont;
  }

  setFontType(type: 'header-font' | 'body-font', value: FontSelection): void {
    if (type === 'header-font') {
      this.currentHeaderFont = value
    } else {
      this.currentBodyFont = value;
    }
  };

  setPalette(event: {variation: 'primary' | 'accent' | 'warn', palette: SubPalette}): void {
    switch (event.variation) {
      case 'primary':
        this.primary = event.palette;
        break;
      case 'accent':
        this.accent = event.palette;
        break;
      case 'warn':
        this.warn = event.palette;
        break;
    }
  }

  private initStylingFromCustomer() {
    this.fontService.getAllFonts().subscribe(fonts => {
      if (!this.customer.settings.styling) return;

      const styling = this.customer.settings.styling;
      this.primaryColor.setValue(styling.primaryColor);
      this.accentColor.setValue(styling.accentColor);
      this.warnColor.setValue(styling.warnColor);
      const headerFont = fonts.find(f => f.family === styling.headerFontFamily);
      if (headerFont) {
        this.searchHeader.setValue(styling.headerFontFamily);
        this.currentHeaderFont = headerFont;
      }
      const bodyFont = fonts.find(f => f.family === styling.bodyFontFamily);
      if (bodyFont) {
        this.searchBody.setValue(styling.bodyFontFamily);
        this.currentBodyFont = bodyFont;
      }
      this.backgroundColor.setValue(styling.backgroundColor || '#f5f5f3');
      this.textColor.setValue(styling.textColor || '#32322d');
      this.headerTextColor.setValue(styling.headerTextColor || '#32322d');
      this.matCardColor.setValue(styling.matCardColor || '#ffffff');
      this.currentHeaderWeight.setValue(styling.headerWeight || 600);
      this.currentBodyWeight.setValue(styling.bodyWeight || 400);
    });
  }

  private initCurrentThemeChange(): void {
    this.buildThemeService.getCurrentTheme().pipe(
      takeUntil(this.destroy$),
      switchMap(css => this.setStylesheet(css))
    ).subscribe(() => this.loading$.next(false));
  }

  private setStylesheet(css: string): Observable<Customer> {
    const file = new File([css], "foo.css", {
      type: "text/css",
    });
    const styling = {
      primaryColor: this.primaryColor.value,
      accentColor: this.accentColor.value,
      warnColor: this.warnColor.value,
      matCardColor: this.matCardColor.value,
      headerFontFamily: this.currentHeaderFont.family,
      bodyFontFamily: this.currentBodyFont.family,
      backgroundColor: this.backgroundColor.value,
      textColor: this.textColor.value,
      headerTextColor: this.headerTextColor.value,
      headerWeight: this.currentHeaderWeight.value,
      bodyWeight: this.currentBodyWeight.value
    }
    return this.buildThemeService.setNewStylesheet(this.customer, file, styling);
  }

  setDefault(): void {
    this.searchHeader.setValue('Open Sans');
    this.searchBody.setValue('Barlow');
    this.currentBodyWeight.setValue(400);
    this.currentHeaderWeight.setValue(600);
    this.primaryColor.setValue('#50504b');
    this.accentColor.setValue('#969691');
    this.warnColor.setValue('#ff0000');
    this.matCardColor.setValue('#ffffff');
    this.textColor.setValue('#32322d');
    this.headerTextColor.setValue('#32322d');
    this.backgroundColor.setValue('#f5f5f3');
    this.highLightUpdateThemeBtn = true;
  }

  private initLoadingChange() {
    this.loading$.subscribe(() => {

    });
  }

  private get searchHeader(): FormControl {
    return this.fontSelection.get('searchHeader') as FormControl;
  }
  private get searchBody(): FormControl {
    return this.fontSelection.get('searchBody') as FormControl;
  }

  private get currentHeaderWeight(): FormControl {
    return this.fontSelection.get('currentHeaderWeight') as FormControl;
  }
  private get currentBodyWeight(): FormControl {
    return this.fontSelection.get('currentBodyWeight') as FormControl;
  }

  private get primaryColor(): FormControl {
    return this.colorSelection.get('primaryColor') as FormControl;
  }
  private get accentColor(): FormControl {
    return this.colorSelection.get('accentColor') as FormControl;
  }
  private get warnColor(): FormControl {
    return this.colorSelection.get('warnColor') as FormControl;
  }
  private get matCardColor(): FormControl {
    return this.colorSelection.get('matCardColor') as FormControl;
  }
  get textColor(): FormControl {
    return this.colorSelection.get('textColor') as FormControl;
  }
  get headerTextColor(): FormControl {
    return this.colorSelection.get('headerTextColor') as FormControl;
  }
  get backgroundColor(): FormControl {
    return this.colorSelection.get('backgroundColor') as FormControl;
  }
}
