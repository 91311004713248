/**
 * Finds value from nested Record.
 *
 * @param {Record<string, unknown>} obj
 * @param {string} path
 * @returns {T | undefined}
 */
export function dig<T>(obj: Record<string, unknown>, ...path: string[]): T | undefined {
  try {
    const key = path.shift();
    const value = obj[`${key}`];
    return path.length < 1 ?
      value as T :
      dig(value as any, ...path);
  } catch (_) {
    return undefined;
  }
}
