<div fxLayout="column">
  <mat-card class="mb-3" fxLayout="column">
    <mat-card-title>Søjlevisninger</mat-card-title>
    <mat-card-content class="mt-3">
      <button mat-raised-button color="accent" (click)="openCreateDisplayDialog()">
        Opret ny
      </button>
    </mat-card-content>

  </mat-card>

  <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" fxLayoutGap="2rem grid"
       *ngIf="displays"
       [@listAnimation]="displays.length"
  >
    <div *ngFor="let display of displays" fxFlex="33">
      <mat-card fxFlex>
        <mat-card-title-group>
          <mat-card-title>
            {{display.name}}
          </mat-card-title>
          <div fxLayoutAlign="end start">
            <button mat-icon-button class="mr-3" matTooltip="Rediger" color="accent" (click)="openEditDisplayDialog(display)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="accent" matTooltip="Slet" (click)="deleteDisplay(display)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-card-title-group>
        <mat-card-content>
          <p>
            Ejer af visning: {{display.user_initials}}
          </p>
          <p *ngIf="display.shared">
            Visningen er delt med alle
          </p>
          <p *ngIf="!display.shared">
            Visningen er ikke delt
          </p>
        </mat-card-content>
      </mat-card>

    </div>
  </div>

</div>
